import React, { useMemo, useState } from 'react';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '../../../components/ui/select';
import { AvatarStack } from 'src/components/ui/avatar-stack';
import { ProjectDetailsProps } from '../create-project.types';
import { useGetLicenseQuery, useGetUsersQuery } from 'src/redux/api-slice';
import { appTheme } from 'src/styles/theme';

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  projectFormData,
  handleProjectFormDataChange,
}) => {
  const [searchText, setSearchText] = useState('');

  const users = useGetUsersQuery();

  const {data} = useGetLicenseQuery();

  const filteredUsers = useMemo(() => {
    const memberUsers = users.data?.filter(user => user.role === 'MEMBER');
    if (!searchText) {
      return memberUsers || [];
    }
    return (
      memberUsers?.filter(user =>
        user.name.toLowerCase().includes(searchText.trim().toLowerCase()),
      ) || []
    );
  }, [searchText, users.data]);

  const options = ['SmartDoc', 'Transformation'];

  const handleProjectTypeChange = (value: string) => {
    handleProjectFormDataChange('projectType', value);
  };

  return (
    <div className="w-full">
      <div className="flex justify-between flex-row mb-4 gap-4 items-center">
        <div className="w-full flex flex-col gap-2">
          <label className="text-gray-600 text-sm">Project Name</label>
          <input
            type="text"
            className="py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            placeholder="Enter Project Name"
            value={projectFormData.projectName}
            onChange={e =>
              handleProjectFormDataChange('projectName', e.target.value)
            }
          />
        </div>

        <div className="w-full flex flex-col gap-2">
          <label className=" text-gray-600 text-sm">Project Type</label>
          <div className="relative w-full">
            <Select
              onValueChange={handleProjectTypeChange}
              value={projectFormData.projectType}
            >
              <SelectTrigger className="w-full px-3 py-2 border border-gray-300 rounded flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent className="w-full mt-2 bg-white border border-gray-300 rounded shadow-lg">
                {options.map(option => (
                  <SelectItem key={option} value={option}>
                    {option}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <div className="flex justify-between flex-row mb-4 gap-4 items-center">
        <div className="w-full flex flex-col gap-2">
          <label className="text-gray-600 text-sm">Organization Name</label>
          <input
            type="text"
            className="py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            placeholder="Enter Organization Name"
            value={projectFormData.organizationName}
            disabled
            onChange={e =>
              handleProjectFormDataChange('organizationName', e.target.value)
            }
          />
        </div>

        <div className="w-full flex flex-col gap-2">
          <label className="text-gray-600 text-sm">
            Servicedesk ID
          </label>
          <input
            type="text"
            className="py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            placeholder="Enter Service Desk Id"
            value={data?.ServicedeskID}
            disabled
            onChange={e =>
              handleProjectFormDataChange('servicedeskID', e.target.value)
            }
          />
        </div>
      </div>

      <div>
        <div className="mb-4 flex justify-between">
          <div className="w-1/2 pr-2 space-y-2">
            <label className="block text-gray-600 text-sm">Start Date</label>
            <input
              type="date"
              className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              value={projectFormData.startDate}
              onChange={e =>
                handleProjectFormDataChange('startDate', e.target.value)
              }
            />
          </div>
          <div className="space-y-2 w-1/2 pl-2">
            <label className="block text-gray-600 text-sm">End Date</label>
            <input
              type="date"
              value={projectFormData.endDate}
              onChange={e =>
                handleProjectFormDataChange('endDate', e.target.value)
              }
              className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="mb-6 space-y-2">
          <label className="block text-gray-600 text-sm">
            Add team members
          </label>
          <input
            type="text"
            className="mx-1 w-[calc(100%-.4rem)] py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            placeholder="Search Team Members"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div>

        {users.isLoading ? (
          'Loading'
        ) : users.isError ? (
          'Unable to fetch users!'
        ) : users.data && users.data.length > 0 ? (
          <div className="flex justify-start w-full overflow-auto gap-4 pb-6">
            {filteredUsers.map(user => {
              const isSelectedUser = projectFormData.teamMembers.some(
                u => u.id === user.id,
              );
              return (
                <div
                  key={user.name}
                  className="flex flex-col items-center w-40 cursor-pointer p-2 rounded-md"
                  style={{
                    borderWidth: 1,
                    borderColor: isSelectedUser
                      ? appTheme.colors.darkGrey
                      : appTheme.colors.greyCardOutline,
                    background: isSelectedUser
                      ? appTheme.colors.whiteSmoothLight
                      : appTheme.colors.whiteSmooth,
                  }}
                  onClick={() => {
                    if (isSelectedUser) {
                      handleProjectFormDataChange(
                        'teamMembers',
                        projectFormData.teamMembers.filter(
                          u => u.id !== user.id,
                        ),
                      );
                    } else {
                      handleProjectFormDataChange('teamMembers', [
                        ...projectFormData.teamMembers,
                        user,
                      ]);
                    }
                  }}
                >
                  <AvatarStack maxAvatarsAmount={1} avatars={[user.name]} />
                  <div
                    className="text-gray-700 mt-2 text-sm overflow-hidden whitespace-nowrap text-overflow-ellipsis"
                    title={user.name}
                  >
                    {user.name}
                  </div>
                  <div className="text-sm text-gray-500">{user.role}</div>
                </div>
              );
            })}
          </div>
        ) : (
          'No active users found'
        )}
      </div>
      <hr className="mb-4" />
      <div>
        <div className="mb-2 space-y-2">
          <label className="block text-gray-600 text-sm">
            Select project lead
          </label>
        </div>

        <div className="min-h-[140px]">
          {projectFormData.teamMembers.length > 0 ? (
            <div className="flex justify-start w-full overflow-auto gap-4 pb-6">
              {projectFormData.teamMembers?.map(user => {
                const isLeadUser = projectFormData.projectLead?.id === user.id;
                return (
                  <div
                    key={user.name}
                    className="flex flex-col items-center w-max cursor-pointer p-2 rounded-md"
                    onClick={() => {
                      if (projectFormData.projectLead?.id === user.id) {
                        handleProjectFormDataChange('projectLead', null);
                      } else {
                        handleProjectFormDataChange('projectLead', user);
                      }
                    }}
                    style={{
                      borderWidth: 1,
                      borderColor: isLeadUser
                        ? appTheme.colors.darkGrey
                        : appTheme.colors.greyCardOutline,
                      background: isLeadUser
                        ? appTheme.colors.whiteSmoothLight
                        : appTheme.colors.whiteSmooth,
                    }}
                  >
                    <AvatarStack maxAvatarsAmount={1} avatars={[user.name]} />
                    <div className="text-gray-700 mt-2 text-sm w-max">
                      {user.name}
                    </div>
                    <div className="text-sm text-gray-500">{user.role}</div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
