import { useState } from 'react';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import { useGetAppVersionQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';

const VersionPage = () => {
    const [alertsShown, setAlertsShown] = useState({ version: false });
    const { isLoading, error, data: version } = useGetAppVersionQuery();

    if (error && !alertsShown.version) {
        console.log(error);
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Unable to load app version. Please try again later.',
        });
        setAlertsShown({ ...alertsShown, version: true });
    }

    return (
        <div className="flex flex-row bg-[#F3F4F4] min-h-[87vh]">
            <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
                <AdminSideBar activeScreen={'Version'} />
            </div>
            <div className="h-[87vh] w-full flex items-center justify-center bg-gray-100">
        <div
          className="bg-white p-10 rounded-lg shadow-lg w-full"
          style={{ width: '700px', height: '500px' }}
        >
          <h3 className=" mb-4 text-2xl font-light text-gray-800 text-center">
            Release Versions
          </h3>
                    
                    <div className=" my-4 border border-gray-300 rounded-md p-4">
                        {isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="w-full">
                                {version?.filter(item => item.name && item.version).map((item, index) => (
                                    <div key={index} className="flex justify-between items-center mt-3">
                                        <span className="w-[50%] text-gray-600 font-medium">{item.name.replaceAll(/-/g, ' ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}</span>
                                        <span className="w-[50%] text-gray-600 text-left">{item.version}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
  );
};

export default VersionPage;
