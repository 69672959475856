import axios from 'axios';
import { IProjectFormData } from './create-project.types';
import { externalConfig } from 'src/utils/misc.utils';

export const createNewProject = async (project: IProjectFormData) => {
  const payload = {
    projectName: project.projectName,
    projectType:
      project.projectType === 'SmartDoc' ? 'SMART_DOC' : 'TRANSFORMATION',
    startDate: project.startDate,
    endDate: project.endDate,
    projectMembers:
      project.teamMembers.map(member => ({
        memberType: member.id === project.projectLead?.id ? 'LEAD' : 'MEMBER',
        userId: member.id,
      })) || [],
    dependentProjects: project.dependentProjects,
  };

  const response = await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project`,
    payload,
  );

  return response.data;
};
