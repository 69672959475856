import {
  useGetProjectDetailQuery,
  useGetTransformationStatusQuery,
} from 'src/redux/api-slice';
import { getSearchParameter } from 'src/utils/url.utils';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  EAppIntent,
  EArtifactStatus,
  ITransformationStatus,
} from 'src/redux/app-global/app-global.types';
import {
  clearArtifactDownloadAsServiceObject,
  clearArtifactTransformProfile,
  setArtifactStatusObject,
  setControlCardData,
  setCurrentIntent,
  setDynamicCallData,
  setIgnoredMissingArtifacts,
  setInvalidFileData,
  setIsCallChainLoaded,
  setJCLCallData,
  setMissingPrograms,
  setMissingUtilities,
  setProcData,
  setScanCompleted,
  setScanJclFailed,
  setScannerErrorMessage,
  setScanProgress,
  setSmartDocStatus,
  setTransformationProgress,
  setTransformationStatus,
} from 'src/redux/app-global';

export const useSetProjectContext = () => {
  const projectId = getSearchParameter('projectId');
  const { data } = useGetProjectDetailQuery(
    { projectId },
    { skip: !projectId },
  );
  const transformationStatusQuery = useGetTransformationStatusQuery(
    { projectId },
    { skip: !projectId },
  );

  const getCurrentStage = data => {
    if (data?.currentStage?.toLowerCase() === 'default') {
      return 'Extract';
    }

    return data?.currentStage || 'NA';
  };

  const currentStage = getCurrentStage(data);

  const dispatch = useDispatch();

  useEffect(() => {
    const setProjectStatus = () => {
      let projectIntent: EAppIntent = EAppIntent.EXTRACT_ARTIFACT;
      let isScanCompleted = false;
      if (currentStage.toLowerCase() === 'extract') {
        projectIntent = EAppIntent.EXTRACT_ARTIFACT;
      }
      if (currentStage.toLowerCase() === 'upload') {
        projectIntent = EAppIntent.ARTIFACT_UPLOAD;
      }
      if (currentStage.toLowerCase() === 'readiness') {
        projectIntent = EAppIntent.REPORT;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'smartdoc') {
        projectIntent = EAppIntent.SMART_DOC;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'transform') {
        projectIntent = EAppIntent.TRANSFORMATION_PAGE;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'test') {
        projectIntent = EAppIntent.TEST_CHEVRON;
        isScanCompleted = true;
      }
      dispatch(setScanCompleted(isScanCompleted));

      dispatch(setScanJclFailed(false));
      dispatch(setIsCallChainLoaded(false));
      dispatch(setScanProgress(100));
      dispatch(setMissingPrograms([]));
      dispatch(setMissingUtilities([]));
      dispatch(setSmartDocStatus({}));
      dispatch(setProcData([]));
      dispatch(setControlCardData([]));
      dispatch(setDynamicCallData([]));
      dispatch(setJCLCallData([]));
      dispatch(setScannerErrorMessage(''));
      dispatch(setCurrentIntent(projectIntent));
      dispatch(clearArtifactTransformProfile());
      dispatch(clearArtifactDownloadAsServiceObject());
      dispatch(setIgnoredMissingArtifacts(undefined));
      dispatch(setInvalidFileData({}));
    };

    if (currentStage) {
      setProjectStatus();
    }
  }, [dispatch, currentStage]);

  useEffect(() => {
    const newArtifactStatus: Record<string, EArtifactStatus> = {};
    const transformationProgress: Record<string, number> = {};
    const transformationStage: Record<string, ITransformationStatus> = {};

    if (transformationStatusQuery.data) {
      transformationStatusQuery.data.forEach((status: any) => {
        if (status.status === 'PASS') {
          newArtifactStatus[status.artifactName] = EArtifactStatus.SUCCESS;
          transformationProgress[status.artifactName] = 100;
          transformationStage[status.artifactName] = {
            PACKAGING: 'PASS',
            CODE_TRANSFORMATION: 'PASS',
            COMPILATION: 'PASS',
            CODE_GENERATION: 'PASS',
          };
        }
        if (status.status === 'FAILED') {
          newArtifactStatus[status.artifactName] = EArtifactStatus.ERROR;
          transformationProgress[status.artifactName] = 100;
        }
      });
    }
    console.log('New artifact status', newArtifactStatus);
    dispatch(setArtifactStatusObject(newArtifactStatus));
    dispatch(setTransformationProgress(transformationProgress));
    dispatch(setTransformationStatus(transformationStage));
  }, [transformationStatusQuery.data, dispatch]);

  return undefined;
};
