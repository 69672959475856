import React from 'react';

interface FileValidationProps {
    programDetailsMap: any
}

const FileValidationSection: React.FC<FileValidationProps> = ({ programDetailsMap }) => {
    const categorizeLanguagesWithFutureSupport = (detailsMap) => {
        const languageCounts = {};
        let numberOfLines = 0;
        
        Object.values(detailsMap).forEach((program) => {            
            if (Array.isArray((program as any).supportType) && (program as any).supportType.includes('futureSupport')) {
                numberOfLines += parseInt((program as any).noLines);
                (program as any).language.forEach((lang) => {
                    if (languageCounts[lang]) {
                        languageCounts[lang]++;
                    } else {
                        languageCounts[lang] = 1;
                    }
                });
            }
        });
        return {languageCounts, numberOfLines};
    };

    const {languageCounts,numberOfLines} = categorizeLanguagesWithFutureSupport(programDetailsMap);
    const hasFutureSupportLanguages = Object.keys(languageCounts).length > 0;

    return (
        <div>
            {hasFutureSupportLanguages && (
                <div className='border rounded-lg shadow-sm p-4' style={{borderColor:"#FFCC80"}}>
                    <div className="flex px-4 justify-center">
                        <div className="block mt-6">
                            <h2 className="text-center mr-4">
                                Additionally, We found programs which will be supported in the future
                            </h2>
                            <div className="flex ml-4 justify-center">
                                {Object.entries(languageCounts).map(([language, count]) => (
                                    <div
                                        key={language}
                                        className="mt-6 mr-8 rounded-full w-24 h-24 border-4 flex flex-col justify-center items-center"
                                        style={{borderColor: "orange"}}
                                    >
                                        <h2>{count as any}</h2>
                                        <p className="text-sm">{language}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <h1 className="font-bold text-center py-8 mr-4">Summary of Programs Supported in Future</h1>
                    <div className="flex justify-center">
                        <div className="block mt-4">
                            <div className="flex flex-col mr-4 justify-center items-center w-32 h-16">
                                <h2 className="text-4xl text-blue-500">
                                    {numberOfLines.toLocaleString()}
                                </h2>
                                <p className="text-base py-6 text-center">
                                    Number of Lines
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileValidationSection;
