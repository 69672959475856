import Chatbot from 'react-chatbot-kit';
import MessageParser from './message-parser';
import ActionProvider from './action-provider';
import { IChatResponse, getChatbotConfig } from './config';
import { appTheme } from '../../styles/theme';
import { useChatBotUtils } from '../../hooks/useChatBotUtils';
import { useEffect, useState } from 'react';
import { fetchBotResponse } from './chatbot.service';
import { SESSION_HEADER } from '../../constants/app.constant';
import setAuthHeaders from '../../interceptors/auth.interceptor';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import axios from 'axios';
import { externalConfig } from '../../utils/misc.utils';
import { SharedService } from '../../services/shared-service';
import './chatbot.css';
import { FaPaperclip } from 'react-icons/fa';
import Swal from 'sweetalert2';
import SmartDocSpinner from '../../assets/images/svg/smartDocLoader.icon';
import { getSearchParameter } from 'src/utils/url.utils';

const ChatFooter = () => {
  const [isUploading, setIsUploading] = useState(false);
  const scanCompleted = useSelector(
    (state: RootState) => state.appGlobal.scanCompleted,
  );
  const projectId = getSearchParameter('projectId');
  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(
        `${externalConfig.REACT_APP_API_URL}/file/uploadUserReport/${projectId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (response.data.status === 'ERROR') {
        throw new Error(response.data.data.aiRespBody);
      }
      const uploadedFileData = response.data.data[file.name];

      let errorsList = '';
      if (uploadedFileData) {
        Object.entries(uploadedFileData).forEach(([childFileName, details]) => {
          if (
            (details as any)?.status === 'ERROR' ||
            (details as any)?.aiStatus === 'NOT_ACCEPTABLE'
          ) {
            errorsList += `<div style="display: flex; align-items: center; margin-bottom: 8px;">
            <span style="color: #FFC107; margin-right: 10px;">⚠️</span>
            <span><strong>${childFileName}:</strong> ${(details as any).aiRespBody}</span>
          </div>`;
          }
        });
      }
      if (errorsList) {
        Swal.fire({
          icon: 'warning',
          title: 'File Partially Accepted',
          html: `<strong>Some extracted files were not accepted by the AI:</strong><br><br>${errorsList}`,
          confirmButtonColor: '#FFC107',
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'File Uploaded',
          text: `File "${file.name}" uploaded successfully to AI! You can now query from that report using our AI Plugin.`,
          confirmButtonColor: '#4CAF50',
        });
      }
    } catch (error: any) {
      console.error('File upload failed:', error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text:
          error ??
          `Failed to upload the file "${file.name}". Please check if you have provided necessary properties to connect with AI Plugin. `,
        confirmButtonColor: '#f27474',
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <span
      id="insent-message-input-buttons-wrapper"
      className="absolute bottom-[16px] rounded-md"
      style={{
        height: '4.5rem',
        color: '#0A61DC',
        minWidth: '3.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: 'white',
        opacity: isUploading || !scanCompleted ? '50%' : '100%',
      }}
    >
      <label htmlFor="ai-file-upload">
        {isUploading ? (
          <SmartDocSpinner height={40} width={40} cursor={'wait'} />
        ) : (
          <FaPaperclip size={20} cursor={'pointer'} />
        )}
      </label>
      <input
        type="file"
        id="ai-file-upload"
        accept=".txt,.pdf,.json,.html,.doc,.docx,.zip,.md,.pptx"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
        disabled={isUploading || !scanCompleted}
      />
    </span>
  );
};

const ChatBotOnPrem = () => {
  const [initialData, setInitialData] = useState<IChatResponse>({
    message: '',
    options: [],
  });
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const currentIntent = useSelector(
    (state: RootState) => state.appGlobal.currentIntent,
  );
  const scanCompleted = useSelector(
    (state: RootState) => state.appGlobal.scanCompleted,
  );
  const chatbotUtils = useChatBotUtils();
  const sessionId = sessionStorage.getItem(SESSION_HEADER);

  useEffect(() => {
    setTimeout(() => {
      const inputBtn = document.getElementsByClassName(
        'react-chatbot-kit-chat-btn-send',
      );
      if (inputBtn.length) {
        for (let i = 0; i < inputBtn.length; i++) {
          if (scanCompleted) {
            inputBtn[i].removeAttribute('disabled');
            inputBtn[i].removeAttribute('style');
          } else {
            inputBtn[i].setAttribute('disabled', 'true');
            inputBtn[i].setAttribute('style', 'opacity: .5;');
          }
        }
      }

      const inputField = document.getElementsByClassName(
        'react-chatbot-kit-chat-input',
      );
      if (inputField.length) {
        for (let i = 0; i < inputField.length; i++) {
          if (scanCompleted) {
            inputField[i].removeAttribute('disabled');
            inputField[i].removeAttribute('style');
            inputField[i].setAttribute('placeholder', 'Type a message...');
          } else {
            inputField[i].setAttribute('disabled', 'true');
            inputField[i].setAttribute('style', 'opacity: .5;');
            inputField[i].setAttribute(
              'placeholder',
              'Plugin is not activated yet!',
            );
          }
        }
      }
    }, 1000);
  }, [scanCompleted]);

  useEffect(() => {
    (async () => {
      if (!sessionId) return;
      setAuthHeaders();
      const firstPromptId = '000';
      // try {
      //   const options = await fetchBotResponse('');
      //   const promptResp = options.find(
      //     (option: any) => option?.type === 'prompt-response',
      //   );

      //   let restOption = options.filter(
      //     (option: any) => option?.type !== 'prompt-response',
      //   );

      //   if (license?.Environment === 'ONPREM') {
      //     restOption = restOption.filter(
      //       (option: any) => !option?.hideForOnPrem,
      //     );
      //   }

      //   setInitialData({ message: promptResp?.message, options: restOption });
      // } catch (err) {
      //   console.log(err);
      // }
    })();
  }, [sessionId, currentIntent]);

  function handleRestart() {
    chatbotUtils.restart();
    axios
      .post(
        `${externalConfig?.scannerUrl || process.env.REACT_APP_API_URL}/file/shutdown`,
        {},
      )
      .then(res => {
        console.log('Shutdown service initiated');
      })
      .catch(err => console.log(err));
  }
  return (
    <div className="h-full" id="chat-box-container">
      <Chatbot
        config={getChatbotConfig(handleRestart)}
        placeholderText={'Type a message...'}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        validator={msg => !!msg}
      />

      <div>
        <ChatFooter />
      </div>
    </div>
  );
};

export default ChatBotOnPrem;
