import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { MdCompareArrows } from 'react-icons/md';
import { JobData, Row } from './test-chevron.types';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { helpTexts } from 'src/helpTexts';

interface FileTypeTableProps {
    title: string;
    rows: Array<{
        datasetName: string;
        recordLength: number;
        recordFormat: string;
        fileType: string;
        source: string;
    }>;
    downloadStatus: boolean;
    tableClassName: string;
    headerClassName: string;
    rowClassName: string;
    openModal: (index: number, jobData: Row) => void;
    index: number;
}

const FileTypeTable: React.FC<FileTypeTableProps> = ({ title, rows, downloadStatus, tableClassName, headerClassName, rowClassName, openModal, index }) => {
    const pageHelpTexts = helpTexts[0].TEST_CHEVRON;
    return (
        <>
            {rows.length > 0 && (
                <>
                    <h2 className={`text-lg font-semibold mb-2 ${headerClassName}`}>{title}</h2>
                    <table className={`w-full shadow-md rounded-md ${tableClassName}`}>
                        <thead>
                            <tr className='border-b'>
                                <th className="p-4 text-left font-medium text-muted-foreground text-sm"><div className='flex items-center gap-2 justify-start text-sm '>Dataset Name<HelpTextCallout calloutText={pageHelpTexts[4].description}/></div></th>
                                <th className="p-4 text-center font-medium text-muted-foreground text-sm"><div className='flex items-center justify-start text-sm ml-4'>Record Length<HelpTextCallout calloutText={pageHelpTexts[5].description}/></div></th>
                                <th className="p-4 text-left font-medium text-muted-foreground text-sm"><div className='flex items-center justify-start gap-1 text-sm'>Record Format<HelpTextCallout calloutText={pageHelpTexts[6].description}/></div></th>
                                <th className="p-4 text-left font-medium text-muted-foreground text-sm"><div className='flex items-center justify-start text-sm'>File Source<HelpTextCallout calloutText={pageHelpTexts[7].description}/></div></th>
                                {title.includes("Output") && rows.length > 0 && <th className="p-4 text-center font-medium text-muted-foreground w-[200px] text-sm"><div className='flex items-center justify-center text-sm ml-2'>Upload and Compare<HelpTextCallout calloutText={pageHelpTexts[8].description}/></div></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, rowIndex) => (
                                <tr key={rowIndex} className={`border-b ${rowClassName}`}>
                                    <td className={`p-4 align-middle font-semibold text-sm`}>
                                        {row.datasetName}
                                    </td>
                                    <td className={`p-4 align-middle text-center font-semibold text-sm`}>
                                        {row.recordLength}
                                    </td>
                                    <td className={`p-4 align-middle font-semibold text-sm`}>
                                        {row.recordFormat}
                                    </td>
                                    <td className={`p-4 align-middle font-semibold text-sm`}>
                                        {row.source}
                                    </td>
                                    <td>
                                        {
                                            title.includes("Output") && rows.length > 0 &&
                                            (
                                                <div className="mx-auto my-2 flex items-center justify-center w-[7rem] bg-cta text-white rounded-md shadow-md p-3">
                                                <button onClick={() => openModal(index, row)} className="file-label flex items-center cursor-pointer">
                                                    <MdCompareArrows className="text-[18px] mr-2" />
                                                    <span className="text-[14px] font-semibold">Compare</span>
                                                </button>
                                                </div>
                                            ) 
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </>
    );
};

export default FileTypeTable;