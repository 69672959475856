import axios from 'axios';
import { setLicense } from '../redux/app-global';
import { apiConfig, externalConfig } from '../utils/misc.utils';
import Swal from 'sweetalert2';

export async function loadLicenseDetails(dispatch) {
  try {
    const basePath = externalConfig.REACT_APP_API_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    const response = await axios.get(`${basePath}/admin/api/config`, {
      headers: {
        'X-CF-CLIENT-ID': clientId,
        'X-CF-CLIENT-SECRET': clientSecret,
      },
    });
    Object.assign(apiConfig, response?.data?.data);
    const limit = parseInt(response?.data?.data?.maxFileSize) || 100;
    const licenseData = {
      Environment: response?.data?.data?.Environment || 'ONPREM',
      fileUploadLimit: limit || 0,
      licenseType: response?.data?.data?.LicenseType || '',
      enableCdn: response?.data?.data?.EnableCDN || '',
      licenseFor: response?.data?.data?.LicensedFor || '',
      category: response?.data?.data.Category || '',
      licensedProduct: response?.data?.data?.LicensedProduct || '',
      lineOfCode: response?.data?.data?.LineOfCode || '',
      maxFileSize: response?.data?.data?.maxFileSize || '',
      licenseId: response?.data?.data.LicenseId || '',
      licensedTill: response?.data?.data.PlatformExpiryDate || '',
      isLicenseActivate: response?.data?.data.isLicenseActivate || 'true',
      isLicenseValid: response?.data?.data.isLicenseValid || 'true',
    };
    dispatch(setLicense(licenseData as any));
    return licenseData;
  } catch (error) {
    dispatch(
      setLicense({
        Environment: 'ONPREM',
        fileUploadLimit: 1000,
        licenseType: 'ONPREM',
        enableCdn: '',
        licenseFor: '',
        category: '',
        licensedProduct: '',
        lineOfCode: '',
        maxFileSize: '',
        licenseId: '',
        licensedTill: '',
        isLicenseActivate: 'false',
        isLicenseValid: 'false',
      }),
    );
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching public email domains:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error fetching license details! Please check if you have a valid license.',
      });
    }
  }
}

export const fetchUiConfig = async (): Promise<any> => {
  try {
    if (process.env.REACT_APP_CF_ENV === 'onprem') {
      const response = await axios.get('ui/config');
      return response.data || {};
    }
  } catch (error) {
    console.log(error);
  }
  return '';
};
