import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export const getDocumentationPageProtectedUrl = async () => {
  const response = await axios.get(
    `${externalConfig.REACT_APP_REPORT_SERVICE}/kb/integration`,
  );

  return { url: response.data.data };
};
