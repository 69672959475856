import axios from 'axios';
import {
  EAppIntent,
  IIgnoredMissingArtifacts,
} from '../redux/app-global/app-global.types';
import { externalConfig } from '../utils/misc.utils';
import { StepProps } from 'antd';
import { setCurrentIntent } from '../redux/app-global';
import { getSearchParameter } from 'src/utils/url.utils';

const missingArtifactsService = () => {
  const getRouteFromAction = (action: string) => {
    switch (action) {
      case 'SYMBOL':
        return EAppIntent.MISSING_SYMBOLS;
      case 'PROC':
        return EAppIntent.MISSING_PROC;
      case 'CONTROL_CARD':
        return EAppIntent.MISSING_CONTROLCARD;
      case 'METADATA':
        return EAppIntent.MISSING_METADATA;
      case 'DYNAMIC_CALL':
        return EAppIntent.RESOLVE_DYNAMIC_CALLS;
      case 'PROGRAM':
        return EAppIntent.MISSING_PROGRAM;
      case 'VALIDATION':
        return EAppIntent.ARTIFACT_UPLOAD;
      case 'SCAN':
        return EAppIntent.UPLOADING_ARTIFACT;
      case 'REPORT':
        return EAppIntent.REPORT;
      default:
        throw new Error('Invalid action');
    }
  };

  const removeSuffix = (str) => {
    return str.split('__')[0];
  };

  const processDynamicCall = (dynamicCallArray) => {   
      return dynamicCallArray.map(item => ({
        ...item,
        programName: removeSuffix(item.programName),
      }));
  };
  
  const processMissingProgram = (missingProgramArray) => {
    return missingProgramArray.map(item => ({
      ...item,
      referredIn: {
        ...item.referredIn,
        callerName: item.referredIn.callerName.map(caller => removeSuffix(caller)),
      },
    }));
  };

  const processArtifactsData = (data) => {
    const { ignoredData, missingData } = data;
  
    const processData = (dataObj) => ({
      ...dataObj,
      dynamicCall: processDynamicCall(dataObj.dynamicCall || []),
      missingProgram: processMissingProgram(dataObj.missingProgram || []),
    });
    return {
      ignoredData: ignoredData ? processData(ignoredData) : {},
      missingData: missingData ? processData(missingData) : {},
    };
  };

  const getMissingArtifacts = async () => {
    const projectId = getSearchParameter('projectId');
    const response = await axios.get(
      `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}`,
    );

    if (response.data?.data) {
      const ignoredData = response.data.data?.ignoredDataArray;
      const missingData = response.data.data?.missingDataArray;

      return processArtifactsData({ ignoredData, missingData });
    }

    return { ignoredData: [], missingData: [] };
  };

  const getNextMissingPageIntentFromMissingData = (missingData: any) => {
    if (!missingData) {
      return '';
    }

    if (missingData.missingSymbol?.length) {
      return EAppIntent.MISSING_SYMBOLS;
    }

    if (missingData.missingProc?.length) {
      return EAppIntent.MISSING_PROC;
    }

    if (missingData.missingControlCard?.length) {
      return EAppIntent.MISSING_CONTROLCARD;
    }

    if (missingData.missingMetaData?.length) {
      return EAppIntent.MISSING_METADATA;
    }

    if (missingData.dynamicCall?.length) {
      return EAppIntent.RESOLVE_DYNAMIC_CALLS;
    }

    if (missingData.missingProgram?.length) {
      return EAppIntent.MISSING_PROGRAM;
    }

    return '';
  };

  const getNextIgnoredArtifactPageIntent = (ignoredData: any) => {
    if (!ignoredData) {
      return '';
    }

    if (ignoredData.missingSymbol?.length) {
      return EAppIntent.MISSING_SYMBOLS;
    }

    if (ignoredData.missingProc?.length) {
      return EAppIntent.MISSING_PROC;
    }

    if (ignoredData.missingControlCard?.length) {
      return EAppIntent.MISSING_CONTROLCARD;
    }

    if (ignoredData.missingMetaData?.length) {
      return EAppIntent.MISSING_METADATA;
    }

    if (ignoredData.dynamicCall?.length) {
      return EAppIntent.RESOLVE_DYNAMIC_CALLS;
    }

    if (ignoredData.missingProgram?.length) {
      return EAppIntent.MISSING_PROGRAM;
    }

    return '';
  };

  const getNextMissingPageIntent = (missingData: any, ignoredData: any) => {
    let missingIntent = getNextMissingPageIntentFromMissingData(missingData);

    if (!missingIntent) {
      missingIntent = getNextIgnoredArtifactPageIntent(ignoredData);
    }

    return missingIntent;
  };

  const getMissingDynamicCalls = async () => {
    const projectId = getSearchParameter('projectId');
    const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/DYNAMIC_CALL`;
    const response = await axios.get(apiUrl);

    if (!response.data?.data) {
      return { missingData: [], programList: [], ignoredData: [] };
    }

    const iniMissingData =
      response.data.data?.missingDataArray?.[0]?.missingData || [];
    const missingData = iniMissingData ? processDynamicCall(iniMissingData) : [];

    const programList =
      response.data.data?.missingDataArray?.[0]?.programList || [];
    const iniIgnoredData = response.data.data?.ignoredDataArray || [];
    const ignoredData = iniIgnoredData ? processDynamicCall(iniIgnoredData) : [];

    return { missingData, programList, ignoredData };
  };

  const getMissingSymbols = async () => {
    const projectId = getSearchParameter('projectId');
    const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/SYMBOL`;
    const response = await axios.get(apiUrl);

    if (!response.data?.data) {
      return { missingData: [], ignoredData: [] };
    }

    const missingData = response.data.data?.missingDataArray || [];
    const ignoredData = response.data.data?.ignoredDataArray || [];

    return { missingData, ignoredData };
  };

  const getMissingPrograms = async () => {
    const projectId = getSearchParameter('projectId');
    const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/PROGRAM`;
    const response = await axios.get(apiUrl);

    if (!response.data?.data) {
      return { missingData: [], ignoredData: [] };
    }

    const iniMissingData = response.data.data?.missingDataArray || [];
    const missingData = iniMissingData ? processMissingProgram(iniMissingData) : [];

    const iniIgnoredData = response.data.data?.ignoredDataArray || [];
    const ignoredData = iniIgnoredData ? processMissingProgram(iniIgnoredData) : [];

    return { missingData, ignoredData };
  };

  const getMissingProcs = async () => {
    const projectId = getSearchParameter('projectId');
    const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/PROC`;
    const response = await axios.get(apiUrl);

    if (!response.data?.data) {
      return { missingData: [], ignoredData: [] };
    }

    const missingData = response.data.data?.missingDataArray || [];
    const ignoredData = response.data.data?.ignoredDataArray || [];

    return { missingData, ignoredData };
  };

  const getMissingControlCards = async () => {
    const projectId = getSearchParameter('projectId');
    const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/CONTROL_CARD`;
    const response = await axios.get(apiUrl);

    if (!response.data?.data) {
      return { missingData: [], ignoredData: [] };
    }

    const missingData = response.data.data?.missingDataArray || [];
    const ignoredData = response.data.data?.ignoredDataArray || [];

    return { missingData, ignoredData };
  };

  const getMissingMetadata = async () => {
    const projectId = getSearchParameter('projectId');
    const apiUrl = `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/METADATA`;
    const response = await axios.get(apiUrl);

    if (!response.data?.data) {
      return { missingData: [], ignoredData: [] };
    }

    const missingData = response.data.data?.missingDataArray || [];
    const ignoredData = response.data.data?.ignoredDataArray || [];

    return { missingData, ignoredData };
  };

  const getProgramNamesWithIgnoredData = (ignoredData: any) => {
    const programNames = new Set<string>();

    ignoredData.dynamicCall?.forEach((program: any) => {
      programNames.add(program.programName);
    });

    ignoredData.missingSymbol?.forEach((symbol: any) => {
      symbol.referredIn?.callerName?.forEach((item: any) => {
        programNames.add(item);
      });
    });

    ignoredData.missingProc?.forEach((proc: any) => {
      proc.referredIn?.callerName?.forEach((item: any) => {
        programNames.add(item);
      });
    });

    ignoredData.missingControlCard?.forEach((cc: any) => {
      cc.referredIn?.callerName?.forEach((item: any) => {
        programNames.add(item);
      });
    });

    ignoredData.missingProgram?.forEach((program: any) => {
      program.referredIn?.callerName?.forEach((item: any) => {
        programNames.add(item);
      });
    });

    ignoredData.missingMetaData?.forEach((metadata: any) => {
      metadata.referredIn?.callerName?.forEach((item: any) => {
        programNames.add(item);
      });
    });

    return Array.from(programNames);
  };

  const stepperIntentToLabelMap: {
    [key in
      | EAppIntent.MISSING_SYMBOLS
      | EAppIntent.MISSING_PROC
      | EAppIntent.MISSING_CONTROLCARD
      | EAppIntent.MISSING_PROGRAM
      | EAppIntent.MISSING_METADATA
      | EAppIntent.RESOLVE_DYNAMIC_CALLS]: string;
  } = {
    [EAppIntent.MISSING_SYMBOLS]: 'Missing Symbols',
    [EAppIntent.MISSING_PROC]: 'Missing Procs',
    [EAppIntent.MISSING_CONTROLCARD]: 'Missing Control Cards',
    [EAppIntent.MISSING_PROGRAM]: 'Missing Programs',
    [EAppIntent.MISSING_METADATA]: 'Missing Metadata',
    [EAppIntent.RESOLVE_DYNAMIC_CALLS]: 'Resolve Dynamic Calls',
  };

  const getMissingPageStepperList = (
    missingData: any,
    ignoredData: any,
    currentPage: EAppIntent,
    dispatch: any,
  ) => {
    if (!missingData && !ignoredData) {
      return [];
    }

    const stepperList: StepProps[] = [];

    if (
      missingData.missingSymbol?.length ||
      ignoredData.missingSymbol?.length
    ) {
      stepperList.push({
        title: stepperIntentToLabelMap[EAppIntent.MISSING_SYMBOLS],
        disabled: currentPage !== EAppIntent.MISSING_SYMBOLS,
        onClick() {
          if (!this.disabled) {
            dispatch(setCurrentIntent(EAppIntent.MISSING_SYMBOLS));
          }
        },
      });
    }

    if (missingData.missingProc?.length || ignoredData.missingProc?.length) {
      stepperList.push({
        title: stepperIntentToLabelMap[EAppIntent.MISSING_PROC],
        disabled: currentPage !== EAppIntent.MISSING_PROC,
        onClick() {
          if (currentPage !== EAppIntent.MISSING_PROC) {
            dispatch(setCurrentIntent(EAppIntent.MISSING_PROC));
          }
        },
      });
    }

    if (
      missingData.missingControlCard?.length ||
      ignoredData.missingControlCard?.length
    ) {
      stepperList.push({
        title: stepperIntentToLabelMap[EAppIntent.MISSING_CONTROLCARD],
        disabled: currentPage !== EAppIntent.MISSING_CONTROLCARD,
        onClick() {
          if (currentPage !== EAppIntent.MISSING_CONTROLCARD) {
            dispatch(setCurrentIntent(EAppIntent.MISSING_CONTROLCARD));
          }
        },
      });
    }

    if (
      missingData.missingMetaData?.length ||
      ignoredData.missingMetaData?.length
    ) {
      stepperList.push({
        title: stepperIntentToLabelMap[EAppIntent.MISSING_METADATA],
        disabled: currentPage !== EAppIntent.MISSING_METADATA,
        onClick() {
          if (currentPage !== EAppIntent.MISSING_METADATA) {
            dispatch(setCurrentIntent(EAppIntent.MISSING_METADATA));
          }
        },
      });
    }

    if (missingData.dynamicCall?.length || ignoredData.dynamicCall?.length) {
      stepperList.push({
        title: stepperIntentToLabelMap[EAppIntent.RESOLVE_DYNAMIC_CALLS],
        disabled: currentPage !== EAppIntent.RESOLVE_DYNAMIC_CALLS,
        onClick() {
          if (currentPage !== EAppIntent.RESOLVE_DYNAMIC_CALLS) {
            dispatch(setCurrentIntent(EAppIntent.RESOLVE_DYNAMIC_CALLS));
          }
        },
      });
    }

    if (
      missingData.missingProgram?.length ||
      ignoredData.missingProgram?.length
    ) {
      stepperList.push({
        title: stepperIntentToLabelMap[EAppIntent.MISSING_PROGRAM],
        disabled: currentPage !== EAppIntent.MISSING_PROGRAM,
        onClick() {
          if (currentPage !== EAppIntent.MISSING_PROGRAM) {
            dispatch(setCurrentIntent(EAppIntent.MISSING_PROGRAM));
          }
        },
      });
    }

    return stepperList;
  };

  return {
    getRouteFromAction,
    getMissingArtifacts,
    getNextMissingPageIntent,
    getNextMissingPageIntentFromMissingData,
    getMissingDynamicCalls,
    getMissingSymbols,
    getMissingPrograms,
    getMissingProcs,
    getMissingControlCards,
    getMissingMetadata,
    getProgramNamesWithIgnoredData,
    getMissingPageStepperList,
    stepperIntentToLabelMap,
  };
};

export const MissingPageService = missingArtifactsService();
