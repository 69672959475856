import React, { Suspense, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { SharedService } from 'src/services/shared-service';
const DiffViewer = React.lazy(() => import('react-diff-viewer-continued'));

const CompareComponent = ({ oldString, newString }) => {
    return (
        <div>
            <Suspense fallback={
                <Spin
                    size="large"
                    tip="Loading the result.."
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                    }}
                />
            }>
                <DiffViewer
                    oldValue={oldString}
                    newValue={newString}
                    splitView={true}
                    leftTitle={
                        <div style={{ textAlign: "center" }}>
                            <strong style={{ fontSize: "18px" }}>{SharedService.mfFileName} (MainFrame Output)</strong>
                            <div style={{ fontSize: "14px", color: "#d15c5c" }}>Encoding: {SharedService.mfEncoding}</div>
                        </div>
                    }
                    rightTitle={
                        <div style={{ textAlign: "center" }}>
                            <strong style={{ fontSize: "18px" }}>{SharedService.javaFileName} (Java Output)</strong>
                            <div style={{ fontSize: "14px", color: "#d15c5c" }}>Encoding: {SharedService.javaEncoding}</div>
                        </div>
                    }
                    showDiffOnly={false}
                    styles={{
                        contentText: { fontSize: "12px", padding: "5px" },
                        lineNumber: { fontSize: "12px" },
                        titleBlock: {
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#f5f5f5",
                            zIndex: 1,
                            padding: "10px",
                            fontWeight: "bold",
                            fontSize: "18px", // Updated for the titles
                            textAlign: "center",
                            borderBottom: "1px solid #d9d9d9"
                        }
                    }}
                />

            </Suspense>
        </div>
    );
};

export default CompareComponent;