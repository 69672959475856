import { cn } from 'src/lib/utils';

const Footer = () => {
  return (
    <footer
      className={cn('bg-black py-6 text-left w-full')}
      style={{
        width: '100%',
        zIndex: 1000,
      }}
    >
      <p
        style={{
          color: '#FFFFFF',
          fontWeight: 'semibold',
          paddingLeft: '20px',
        }}
      >
        CloudFrame &copy; {new Date().getFullYear()} All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
