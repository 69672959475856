import { useEffect, useState } from "react";
import { FaBitbucket, FaCheckCircle, FaGithub, FaGitlab } from "react-icons/fa";
import { TbLogs } from "react-icons/tb";
import './deploy-progress-table.css'

const DeployProgress = ({ selectedPlatform, job, setData }) => {

    const platformTitles = {
        github: 'GitHub',
        gitlab: 'GitLab',
        bitbucket: 'Bitbucket'
    };

    const platformIcons = {
        github: <FaGithub />,
        gitlab: <FaGitlab />,
        bitbucket: <FaBitbucket />
    };

    return (
        <div className="p-6">
      <div className="progress-header flex justify-between items-center mb-6">
        <div className="text-center font-semibold text-lg flex items-center">
          <span className="mr-2">Deploying to GitHub</span>
          <FaGithub size={20} color="#333" />
        </div>
      </div>

      <div className="progress-bar-wrapper h-3 bg-gray-200 rounded overflow-hidden relative">
        <div className="progress-bar-animated h-full w-1/2 bg-gradient-to-r from-blue-400 via-blue-500 to-blue-400"></div>
      </div>

      {job.success && (
        <div className="progress-footer mt-8 flex justify-between items-center">
          <div className="text-green-600 text-sm font-semibold">
            Successfully deployed on {job.completionDate}.
          </div>
          <button className="text-white flex items-center gap-2 bg-cta px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cta">
            <TbLogs /> View Logs
          </button>
        </div>
      )}
    </div>
      );
};

export default DeployProgress;
