import axios from 'axios';
import { useDispatch } from 'react-redux';
import { SharedService } from '../../../../services/shared-service';
import { setActiveModal } from '../../../../redux/app-global';
import { EAppModal } from '../../../../redux/app-global/app-global.types';

const useSubTable = () => {
  const dispatch = useDispatch();

  const showCallChainModal = (name: string, type: string) => {
    SharedService.selectedProgram.name = name;
    SharedService.selectedProgram.type = type === 'Jobs' ? 'jcl' : 'program';
    dispatch(setActiveModal(EAppModal.CALL_CHAIN_MODAL));
  };

  return {showCallChainModal };
};

export default useSubTable;
