import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useEffect, useState } from 'react';
import { StepProps } from 'antd';
import { useGetMissingArtifactsQuery } from '../redux/api-slice';
import { MissingPageService } from '../services/missing-artifacts.service';

const useMissingArtifactStepper = () => {
  const [stepperList, setSteppeList] = useState<StepProps[]>([]);
  const [currentStep, setCurrentStep] = useState(0);

  const dispatch = useDispatch();

  const currentIntent = useSelector(
    (state: RootState) => state.appGlobal.currentIntent,
  );

  const { isError, isLoading, data } = useGetMissingArtifactsQuery();

  useEffect(() => {
    if (isError || isLoading || !currentIntent) {
      setSteppeList([]);
      setCurrentStep(0);
    } else {
      const { missingData = [], ignoredData = [] } = data || {};

      const stepList = MissingPageService.getMissingPageStepperList(
        missingData,
        ignoredData,
        currentIntent,
        dispatch,
      );

      const currentIntentLabel =
        MissingPageService.stepperIntentToLabelMap[currentIntent];

      const currentStep = stepList.findIndex(
        step => step.title === currentIntentLabel,
      );

      setCurrentStep(currentStep);
      setSteppeList(stepList);
    }
  }, [isError, isLoading, data, currentIntent]);

  return { stepperList, currentStep };
};

export default useMissingArtifactStepper;
