import { Steps } from 'antd';
import OverviewCards from '../overview-cards';
import { appTheme } from '../../../../styles/theme';
import { CiPlay1 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import UserOnly from 'src/components/user-only/user-only';
import { useGetProjectDetailQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { getSearchParameter } from 'src/utils/url.utils';
import { useUser } from 'src/hooks/useUser';
import { EProjectStatus } from './stages-component.types';
import { getProjectLead } from './stages-component.service';
import { useDispatch } from 'react-redux';
import {
  setControlCardData,
  setCurrentIntent,
  setDynamicCallData,
  setIsCallChainLoaded,
  setJCLCallData,
  setMissingPrograms,
  setMissingUtilities,
  setProcData,
  setScanCompleted,
  setScanJclFailed,
  setScannerErrorMessage,
  setScanProgress,
  setSmartDocStatus,
} from 'src/redux/app-global';
import { EAppIntent } from 'src/redux/app-global/app-global.types';
import { useEffect } from 'react';

const stages = [
  {
    title: 'Extract',
  },
  {
    title: 'Upload',
  },
  {
    title: 'Readiness',
  },
  {
    title: 'SmartDoc',
  },
  {
    title: 'Transform',
  },
  {
    title: 'Test',
  },
  {
    title: 'Deploy',
  },
];

const StagesComponent = ({projectData}: any) => {
  const navigate = useNavigate();
  const projectId = getSearchParameter('projectId');
  const { data, error, isLoading } = useGetProjectDetailQuery({ projectId });
  const user = useUser();

  const projectLead = getProjectLead(data, projectData);
  const isProjectLead =
    projectLead?.name?.toLowerCase() === user?.userId?.toLowerCase();

  const getCurrentStage = (data, projectData) => {
    if (data?.currentStage?.toLowerCase() === 'default') {
      return 'Extract';
    }
    if (projectData?.currentStage?.toLowerCase() === 'default') {
      return 'Extract';
    }
    return data?.currentStage || projectData?.currentStage || 'NA';
  };

  const currentStage = getCurrentStage(data, projectData);

  const projectStatus =
    data?.projectStatus || projectData?.projectStatus || 'NA';

  const dispatch = useDispatch();

  useEffect(() => {
    const setProjectStatus = () => {
      let projectIntent: EAppIntent = EAppIntent.EXTRACT_ARTIFACT;
      let isScanCompleted = false;
      if (currentStage.toLowerCase() === 'extract') {
        projectIntent = EAppIntent.EXTRACT_ARTIFACT;
      }
      if (currentStage.toLowerCase() === 'upload') {
        projectIntent = EAppIntent.ARTIFACT_UPLOAD;
      }
      if (currentStage.toLowerCase() === 'readiness') {
        projectIntent = EAppIntent.REPORT;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'smartdoc') {
        projectIntent = EAppIntent.SMART_DOC;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'transform') {
        projectIntent = EAppIntent.TRANSFORMATION_PAGE;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'test') {
        projectIntent = EAppIntent.TEST_CHEVRON;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'deploy') {
        projectIntent = EAppIntent.DEPLOY_PAGE;
        isScanCompleted = true;
      }
      dispatch(setScanCompleted(isScanCompleted));

      dispatch(setScanJclFailed(false));
      dispatch(setIsCallChainLoaded(false));
      dispatch(setScanProgress(100));
      dispatch(setMissingPrograms([]));
      dispatch(setMissingUtilities([]));
      dispatch(setSmartDocStatus({}));
      dispatch(setProcData([]));
      dispatch(setControlCardData([]));
      dispatch(setDynamicCallData([]));
      dispatch(setJCLCallData([]));
      dispatch(setScannerErrorMessage(''));
      dispatch(setCurrentIntent(projectIntent));
    };

    if (currentStage) {
      setProjectStatus();
    }
  }, [dispatch, currentStage]);

  const getProjectStatusLabel = (
    currentStage: string,
    projectStatus: EProjectStatus,
  ) => {
    if (user?.role === 'Admin') {
      if (projectStatus === EProjectStatus.COMPLETED) {
        return 'Completed';
      }

      if (projectStatus === EProjectStatus.ARCHIVE) {
        return 'Archived';
      }

      if (currentStage === 'Extract' || currentStage === 'Upload') {
        return 'Not Yet Started';
      }

      return 'Ongoing';
    }

    if (user?.role === 'Member') {
      if (projectStatus === EProjectStatus.COMPLETED) {
        return 'View';
      }

      if (currentStage === 'Extract' || currentStage === 'Upload') {
        return 'Not Yet Started';
      }

      return 'Ongoing';
    }

    return projectStatus;
  };

  const getStatusStyle = status => {
    switch (status.toLowerCase()) {
      case 'active':
        return {
          color: appTheme.colors.greenBase,
          background: 'rgba(219,244,210,.8)',
        };
      case 'archive':
        return {
          color: appTheme.colors.greyBG,
          background: 'rgba(136,139,141)',
        };
      default:
        return {
          color: appTheme.colors.grey10,
          background: 'rgba(255,255,255,.8)',
        };
    }
  };

  const resumeProject = () => {
    navigate(ROUTES.HOME + `?projectId=${projectData?.projectId || projectId}`);
  };

  if (error) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load projects. Please try again later.',
    });
  }

  const disableResume =
    user?.role !== 'Admin' &&
    !isProjectLead &&
    !['enhance', 'test', 'deploy', 'transform'].includes(
      currentStage.toLowerCase(),
    );

  const projectStatusLabel = getProjectStatusLabel(
    data?.currentStage || projectData?.currentStage,
    projectStatus,
  );

  const getResumeButtonLabel = () => {
    if (projectStatusLabel === 'Completed') {
      return 'View';
    }

    if (projectStatusLabel === 'Not Yet Started' && isProjectLead) {
      return 'Start';
    }

    if (projectStatusLabel === 'Not Yet Started' && !isProjectLead) {
      return 'View';
    }

    if (isProjectLead && projectStatusLabel === 'Ongoing') {
      return 'Resume';
    }

    if (
      !isProjectLead &&
      ['enhance', 'test', 'deploy', 'transform'].includes(
        currentStage.toLowerCase(),
      )
    ) {
      return 'Resume';
    }

    if (!isProjectLead && projectStatusLabel === 'Ongoing') {
      return 'View';
    }

    return 'View';
  };

  return (
    <div className="flex-grow">
      {isLoading ? (
        'Loading...'
      ) : (
        <OverviewCards height="373px">
          <div className="relative flex flex-col gap-8 w-full h-full overflow-hidden">
            <Steps
              items={stages}
              size="small"
              labelPlacement="vertical"
              current={stages.findIndex(
                stage =>
                  stage.title.toLowerCase() === currentStage.toLowerCase(),
              )}
            />
            <h3 className="font-semibold mx-4">
              Current Stage:{' '}
              <span className="ml-8 font-light">
                {data?.currentStage === 'DEFAULT' ||
                projectData?.currentStage === 'DEFAULT'
                  ? 'Extract'
                  : (data?.currentStage || projectData?.currentStage || 'NA')
                      .charAt(0)
                      .toUpperCase() +
                    (data?.currentStage || projectData?.currentStage || 'NA')
                      .slice(1)
                      .toLowerCase()}
              </span>
            </h3>
            <h3 className="font-semibold mx-4">
              Status:{' '}
              <span
                className="ml-16 text-sm px-4 py-1 rounded-full"
                style={getStatusStyle(projectStatus)}
              >
                {projectStatusLabel}
              </span>
            </h3>
            <UserOnly>
              <button
                onClick={resumeProject}
                className="bottom-0 right-0 absolute text-cta text-xl font-semibold hover:text-blue-800 flex items-center gap-2"
                disabled={
                  disableResume || projectStatus === EProjectStatus.ARCHIVE
                }
                style={{ opacity: disableResume ? 0.5 : 1 }}
              >
                {getResumeButtonLabel()}
                <span className="mt-0.5">
                  <CiPlay1 />
                </span>
              </button>
            </UserOnly>
          </div>
        </OverviewCards>
      )}
    </div>
  );
};

export default StagesComponent;
