import axios from 'axios';
import {
  downloadBase64FromResponse,
  externalConfig,
} from 'src/utils/misc.utils';

export const getProjectReports = async (projectId: string) => {
  const res = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/report/${projectId}`,
  );
  return res.data.data || [];
};

export const downloadReport = async (projectId: string, reportId: string) => {
  const res = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/report/download/${projectId}/${reportId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  downloadBase64FromResponse(res);
  return res.data;
};
