import {
  Checkbox,
  initializeIcons,
  PrimaryButton,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import { useEffect, useRef, useState } from 'react';
import Dropdown from '../../../components/custom-dropdown/custom-dropdown';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModal,
  setCurrentIntent,
  setIgnoredMissingArtifacts,
  setUploadedMissingArtifacts,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
  IUploadedArtifacts,
  TableData,
} from '../../../redux/app-global/app-global.types';
import React from 'react';
import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import MissingUploadList, {
  UploadMissingListRef,
} from '../../../components/missing-artifact-upload/missing-artifact-upload';
import { helpTexts } from '../../../helpTexts';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import { SharedService } from '../../../services/shared-service';
import { FaSpinner } from 'react-icons/fa';
import { commaSeparated, externalConfig } from '../../../utils/misc.utils';
import { downloadExtractJcl, isValidDataset } from '../../../utils/misc.utils';
import {
  ExtractJclRequest,
  ExtractType,
} from '../extract-artifact/extract-artifact.model';

import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import Swal from 'sweetalert2';
import { MissingPageService } from '../../../services/missing-artifacts.service';
import { useGetMissingMetaDataQuery } from '../../../redux/api-slice';
import { startScan } from '../../../components/upload-list/upload-list.service';
import { getSearchParameter } from 'src/utils/url.utils';
initializeIcons();

const MissingMetaData = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [data, setData] = useState<TableData[]>([]);
  const [files, setFiles] = useState<Record<string, UploadFile>>({});
  const [datasetNames, setDatasetNames] = useState<string>('');
  const [ispdfData, setIspdfData] = useState<string>('');
  const isMounted = useRef(false);
  const missingPages = useSelector(
    (state: RootState) => state.appGlobal.missingPages,
  );
  const [missingArtifacts, setMissingArtifacts] =
    useState<IUploadedArtifacts>();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [ispfError, setISPFError] = useState<string | null>(null);
  const [ispfSuccess, setISPFSuccess] = useState<string | null>(null);
  const dispatch = useDispatch();
  const ignoredMissingArtifacts = useSelector(
    (state: RootState) => state.appGlobal.ignoredMissingArtifacts,
  );
  const [alertShown, setAlertShown] = useState({ missingMetaData: false });
  const missingMetaDataQuery = useGetMissingMetaDataQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const pageHelpText = helpTexts[0].MISSING_METADATA;

  const [loading, setLoading] = useState(false);
  const uploadMissingListRef = useRef<UploadMissingListRef>(null);
  const projectId = getSearchParameter('projectId');

  useEffect(() => {
    if (
      data?.length > 0 &&
      ignoredMissingArtifacts?.missingMetaData &&
      Array.isArray(ignoredMissingArtifacts.missingMetaData) &&
      ignoredMissingArtifacts.missingMetaData.length > 0
    ) {
      const selectedIndexes = {};
      ignoredMissingArtifacts.missingMetaData.forEach(item => {
        const index = data?.findIndex(dataItem => dataItem.name === item.name);

        if (index !== -1) {
          selectedIndexes[index] = true;
        }
      });

      setSelectedRows(prev => ({
        ...prev,
        ...selectedIndexes,
      }));
      setData(prevData =>
        prevData.map(item => ({
          ...item,
          status: !selectedIndexes[item.key] ? 'Ignore' : 'Active',
        })),
      );
    }
  }, [ignoredMissingArtifacts, data?.length]);

  const triggerFileUpload = (event, itemKey) => {
    if (uploadMissingListRef.current) {
      uploadMissingListRef.current.handleFileUpload(event, itemKey);
    }
  };

  const handleCheckboxChange = key => {
    setSelectedRows(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
    setData(prevData =>
      prevData.map(item =>
        item.key === key
          ? { ...item, status: !selectedRows[key] ? 'Ignore' : 'Active' }
          : item,
      ),
    );
  };
  const handleRecordLength = (event, key) => {
    const value = event.target.value;
    setData(prevData =>
      prevData.map(item =>
        item.key === key ? { ...item, recordLength: value } : item,
      ),
    );
  };
  const handleFilesUpdate = updatedFiles => {
    setFiles(updatedFiles);
  };
  const handleClusterName = (event, key) => {
    const value = event.target.value;
    setData(prevData =>
      prevData.map(item =>
        item.key === key ? { ...item, clusterName: value } : item,
      ),
    );
  };
  const sendMetadataInfo = async () => {
    const formData = new FormData();

    const requestBody: any = [];
    data.forEach(row => {
      const item = {
        name: row.name,
        status: row.status || '',
        clusterName: row.clusterName || '',
        recordFormat: row.recordFormat || '',
        recordLength: row.recordLength || '',
        referredIn: row.requiredBy || [],
      };
      requestBody.push(item);
    });

    formData.append('missingData', JSON.stringify(requestBody));

    Object.values(files).forEach(file => {
      if (file) {
        formData.append('fileList', (file as any).originalFile);
      }
    });
    try {
      setLoading(true);
      const response = await axios.post(
        `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/METADATA`,
        formData,
      );

      if (
        response.data?.status !== 'SUCCESS' ||
        response.data?.message === 'Some artifacts are missing '
      ) {
        Swal.fire({
          title: 'Resolve all missing artifacts',
          text:
            response.data?.message ||
            'Failed to upload missing artifacts. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });

        await missingMetaDataQuery.refetch();
        return;
      }

      dispatch(setUploadedMissingArtifacts(missingArtifacts));

      const nextIntent = MissingPageService.getRouteFromAction(
        response.data?.data?.nextAction,
      );

      if (nextIntent === 'REPORT') {
        dispatch(setIgnoredMissingArtifacts(response.data?.data?.ignoredData));
      }

      if (nextIntent === EAppIntent.UPLOADING_ARTIFACT) {
        const scanStarted = await startScan(dispatch);
        if (!scanStarted) {
          return;
        }
      }

      dispatch(setCurrentIntent(nextIntent));
    } catch (err) {
      console.log(err);
      SharedService.genericAlertModal.title =
        'Upload of Missing Artfacts Failed!';
      SharedService.genericAlertModal.description =
        (err as any)?.message || 'Something went wrong !';
      setTimeout(() => {
        dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
      }, 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { missingData, ignoredData } = missingMetaDataQuery.data || {};
    if (missingData || ignoredData) {
      const transformedData: TableData[] = missingData.map(
        (item: any, index: number) => ({
          key: index,
          recordFormat: item.recordFormat,
          name: item.name,
          metaDataName: item.name,
          requiredBy: item.referredIn,
          recordLength: '',
          clusterName: '',
          status: 'Active',
        }),
      );

      const missingDataLen = transformedData.length;
      const selRows = {};
      ignoredData.forEach((item: any, index: number) => {
        selRows[index + missingDataLen + 1] = true;
        transformedData.push({
          key: index + missingDataLen + 1,
          recordFormat: item.recordFormat,
          name: item.name,
          metaDataName: item.name,
          requiredBy: item.referredIn,
          recordLength: '',
          clusterName: '',
          status: 'Ignore',
        } as any);
      });
      setSelectedRows(selRows);
      setData(transformedData);
    }
  }, [missingMetaDataQuery]);

  const handleSelectAllCheckboxChange = (event, checked) => {
    setSelectAll(checked);
    const newSelectedRows = {};
    data.forEach(item => {
      newSelectedRows[item.key] = checked;
    });
    const newData = data.map(item => {
      newSelectedRows[item.key] = checked;
      return { ...item, status: checked ? 'Ignore' : 'Active' };
    });
    setSelectedRows(newSelectedRows);
    setData(newData);
  };
  const options = [
    'Fixed Record',
    'Fixed Block',
    'Fixed Block, ANSI Control',
    'Fixed Block, Machine Control',
    'Variable Record',
    'Variable Block',
    'Variable Block, ANSI Control',
    'Variable Block, Machine Control',
    'Variable Block spanned',
    'VSAM',
  ];
  const handleOptionSelect = (option: string, key: number) => {
    setData(prevData =>
      prevData.map(item =>
        item.key === key ? { ...item, recordFormat: option } : item,
      ),
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validationError = commaSeparated(value.trim());

    if (validationError) {
      setError(validationError);
      setSuccess(null);
    } else {
      setError(null);
      setSuccess(null);
    }
    setDatasetNames(value);
  };
  const handleISPFChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validationError = commaSeparated(value.trim());

    if (validationError) {
      setISPFError(validationError);
      setISPFSuccess(null);
    } else {
      setISPFError(null);
      setISPFSuccess(null);
    }
    setIspdfData(value);
  };
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const trimmedValues = {
      datasetNames: datasetNames.trim(),
      ispfData: ispdfData.trim(),
    };

    const datasetError = commaSeparated(trimmedValues.datasetNames);
    const ispfError = commaSeparated(trimmedValues.ispfData);

    if (datasetError || !trimmedValues.datasetNames) {
      setError(datasetError || 'Dataset name is required.');
      setSuccess(null);
      return;
    }

    if (ispfError || !trimmedValues.ispfData) {
      setISPFError(ispfError || 'ISPF Data name is required.');
      setISPFSuccess(null);
      return;
    }
    const datasetArray = trimmedValues.datasetNames
      .split(',')
      .map(name => name.trim());

    const ispfDatasetArray = trimmedValues.ispfData
      .split(',')
      .map(name => name.trim());

    const data: ExtractJclRequest = {
      dataset: {
        metadata: datasetArray,
        ispf: ispfDatasetArray,
      },
      downloadFor: 'METADATA' as ExtractType,
    };

    try {
      const isDownloadSuccessful = await downloadExtractJcl(data);

      if (isDownloadSuccessful) {
        setSuccess('Data successfully posted and file downloaded!');
        setError(null);
        setDatasetNames('');
        setISPFError(null);
        setIspdfData('');
      } else {
        throw new Error('Failed to post data or download the file.');
      }
    } catch (error) {
      setError('An unexpected error occurred. Please try again.');
      setSuccess(null);
    }
  };

  const headers: React.ReactNode[] = [
    <Checkbox
      checked={selectAll}
      onChange={handleSelectAllCheckboxChange}
      className="mt-2"
      title="Ignore All"
      label="Ignore All"
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
      key="1"
    />,
    'Dataset Name',
    'Artifact',
    'Max Record Length',
    'Record Format',
    'Cluster Name',
  ];

  const rowFields = item => [
    <Checkbox
      checked={selectedRows[item.key] || false}
      onChange={() => handleCheckboxChange(item.key)}
      className="mt-2"
      title="Ignore"
      label="Ignore"
      key={item.key}
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
    />,
    <div
      className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
      key={item.key}
    >
      {item.name}
    </div>,
    item.requiredBy?.jcl.length + item.requiredBy?.step.length <= 4 ? (
      <div
        className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
        key={item.key}
      >
        {item.requiredBy?.jcl.join(', ')}, {item.requiredBy?.step.join(', ')}
      </div>
    ) : (
      <div key={item.key}>
        <TooltipHost
          id={`tooltip-${selectedRows[item.key]}`}
          content={`${item.requiredBy?.jcl.slice(4, item.requiredBy?.jcl.length - 1).join(', ')}, ${item.requiredBy?.step}`}
        >
          <div
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            {item.requiredBy?.jcl.slice(0, 3).join(', ')}
          </div>
          <span
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            ... and {item.requiredBy?.jcl.length + item.requiredBy?.step - 3}{' '}
            more
          </span>
        </TooltipHost>
      </div>
    ),
    <input
      key={item.key}
      type="number"
      placeholder="Record Length"
      value={item.recordLength || ''}
      className={`border border-gray-200 w-full px-2 text-sm py-2 ${selectedRows[item.key] ? 'cursor-not-allowed' : 'cursor-text'}`}
      onChange={e => handleRecordLength(e, item.key)}
    />,
    <Dropdown
      key={item.key}
      options={options}
      selectedOption={item.recordFormat || 'Select'}
      onOptionSelect={option => handleOptionSelect(option, item.key)}
    />,

    <input
      key={item.key}
      type="text"
      placeholder="Cluster Name"
      value={item.clusterName || ''}
      className={`border border-gray-200 w-full px-2 text-sm py-2 ${selectedRows[item.key] ? 'cursor-not-allowed' : 'cursor-text'}`}
      onChange={e => handleClusterName(e, item.key)}
    />,
  ];
  const changeDisplay = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setToggleValue(checked);
  };

  if (missingMetaDataQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (missingMetaDataQuery.isError && !alertShown.missingMetaData) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to fetch Missing Metadata. Please try again later.',
      footer: 'Please contact support',
      showCancelButton: true,
      confirmButtonText: 'Retry',
      cancelButtonText: 'Go Back',
    }).then(result => {
      if (result.isConfirmed) {
        missingMetaDataQuery.refetch();
        setAlertShown({ missingMetaData: false });
      } else {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
      }
    });
    setAlertShown({ missingMetaData: true });
    return null;
  }

  return (
    <div
      className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}
    >
      <div className="p-2 mb-4">
        <StepperComponent />
      </div>
      <div className="mb-2 border rounded-lg px-8 py-8 shadow max-w-full">
        <h1 className="text-3xl font-semibold mb-4">
          Missing Artifacts Workspace
        </h1>
        <hr />
        <div className="flex flex-col gap-4">
          <div className="text-left mt-4 gap-4 text-l font-semibold">
            Missing Metadata Information
          </div>
          <div>
            Upload the missing Metadata or provide the dataset information to
            extract the missing Metadata from the mainframe.
          </div>
          <div className="flex items-center gap-4">
            <HelpTextCallout calloutText={pageHelpText[8].description} />
            <span className="text-sm">Upload Metadata</span>
            <Toggle className="mt-2" onChange={changeDisplay} />
            <span className="text-sm">Extract Metadata from Mainframe</span>
          </div>
          {toggleValue && (
            <div>
              <h3>Enter the dataset names separated by commas:</h3>
              <input
                type="text"
                className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                value={datasetNames}
                onChange={handleChange}
                placeholder="Enter dataset names..."
              />

              {error && (
                <TooltipHost content={error} id="error-tooltip">
                  <div className="mt-2 text-red-500">{error}</div>
                </TooltipHost>
              )}
              {success && (
                <TooltipHost content={success} id="success-tooltip">
                  <div className="mt-2 text-green-500">{success}</div>
                </TooltipHost>
              )}
              <div className="flex items-center space-x-4 mt-4">
                <HelpTextCallout
                  calloutText={
                    <>
                      {pageHelpText[9].description}
                      <a
                        href="https://ask.cloudframe.com/knowledge-base/finding-ispf-libraries"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p style={{ color: 'blue' }}>Finding ISPF Libraries.</p>
                      </a>
                    </>
                  }
                />
                <h3>Enter the ISPF data:</h3>
              </div>
              <input
                type="text"
                className="border border-gray-300 rounded-md w-1/4 p-2"
                value={ispdfData}
                onChange={handleISPFChange}
                placeholder="Enter ISPF data..."
              />

              {ispfError && (
                <TooltipHost content={ispfError} id="error-tooltip">
                  <div className="mt-2 text-red-500">{ispfError}</div>
                </TooltipHost>
              )}
              {ispfSuccess && (
                <TooltipHost content={ispfSuccess} id="success-tooltip">
                  <div className="mt-2 text-green-500">{ispfSuccess}</div>
                </TooltipHost>
              )}
            </div>
          )}
          {toggleValue ? (
            <div>
              {(data.every(
                item => item.status !== 'Active' || item.uploaded !== undefined,
              ) ||
                Object.values(files).length > 0 ||
                toggleValue ||
                true) && (
                <div className="flex flex-row items-center mt-8">
                  <HelpTextCallout calloutText={pageHelpText[7].description} />
                  <PrimaryButton
                    text={'Generate Extraction JCL'}
                    className="rounded-md"
                    styles={{ root: { backgroundColor: '#1363DF' } }}
                    onClick={handleSubmit}
                  />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          {!toggleValue && (
            <>
              <div className="font-semibold mt-4">Upload Metadata as File</div>
              <hr />
            </>
          )}
          <div className="w-full relative overflow-auto">
            <ReusableTable
              items={data}
              headers={headers}
              rowFields={rowFields}
              helpTexts={[...pageHelpText.slice(0, 5), pageHelpText[6]]}
            />
          </div>
          {!toggleValue ? (
            <MissingUploadList
              ref={uploadMissingListRef}
              setData={setData}
              setMissingArtifacts={setMissingArtifacts as any}
              type="Metadata"
              data={data}
              onFilesUpdate={handleFilesUpdate}
              pageHelpText={pageHelpText}
            />
          ) : (
            ''
          )}
        </div>
        {(data.every(
          item =>
            item.status !== 'Active' ||
            (item.recordLength !== '' && item.status !== 'Active') ||
            item.clusterName !== '',
        ) ||
          toggleValue ||
          true) && (
          <div className="flex flex-row gap-4 items-center">
            <PrimaryButton
              onClick={sendMetadataInfo}
              text="Save and Continue"
              className="mt-8 rounded-md"
              styles={{ root: { backgroundColor: '#1363DF' } }}
            />
            {loading && (
              <div className="flex gap-2 mt-4">
                <FaSpinner className="animate-spin" />
                <p>Updating Missing data ....</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default MissingMetaData;
