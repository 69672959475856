import { FaTimes } from 'react-icons/fa';
import { Graphviz } from 'graphviz-react';
import { DependencyGraphProps } from './dependency-graph.types';
import { Badge } from 'src/components/ui/badge';
import { externalConfig } from 'src/utils/misc.utils';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useGetProjectsQuery } from 'src/redux/api-slice';

const DependencyGraph = ({
  isOpen,
  onClose,
  projectData,
  projectDependencies,
}: DependencyGraphProps) => {
  const [dependencyGraph, setDependencyGraph] = useState<string | null>(null);

  const { data: projects, isLoading } = useGetProjectsQuery();

  const getProjectType = useCallback(
    async (projectId: string) => {
      const projectDetails = projects?.find(
        (project: any) => project.projectId === projectId,
      );

      if (!projectDetails) {
        return 'UNKNOWN';
      }

      return projectDetails?.projectType || 'UNKNOWN';
    },
    [projects],
  );

  const buildDependencyGraph = useCallback(
    async (projects: any[]) => {
      let dotString = 'digraph G {\n';

      const processDependencies = async (project: any) => {
        let { projectName, projectType, projectId, dependencyList } = project;

        if (!projectType) {
          projectType = await getProjectType(projectId);
        }

        dotString += `"${projectName}" [label=<${projectName}<br/><font point-size="10">(${projectType})</font>>];\n`;

        for (const dependency of dependencyList) {
          dotString += `"${projectName}" -> "${dependency.projectName}";\n`;

          if (
            dependency.dependencyList &&
            dependency.dependencyList.length >= 0
          ) {
            await processDependencies(dependency);
          }
        }
      };

      for (const project of projects) {
        await processDependencies(project);
      }

      dotString += '}';
      return dotString;
    },
    [getProjectType],
  );

  useEffect(() => {
    if (isOpen && projectData) {
      const selectedProject = projectDependencies.find(
        (project: any) => project.projectName === projectData.projectName,
      );

      const buildGraph = async () => {
        const graph = await buildDependencyGraph([selectedProject]);
        setDependencyGraph(graph);
      };

      buildGraph();
    }
  }, [isOpen, projectData, projectDependencies, buildDependencyGraph]);

  if (!isOpen || !projectData || !dependencyGraph) return null;

  const projectInfo = [
    { key: 'Project Name', value: projectData?.projectName || '' },
    { key: 'Project Type', value: projectData?.projectType || '' },
    { key: 'Status', value: projectData?.projectStatus || '' },
  ];

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-2xl shadow-2xl max-w-3xl w-auto h-[80vh] overflow-y-auto relative">
        <div className="absolute top-4 right-4">
          <button
            onClick={onClose}
            className="flex items-center justify-center w-10 h-10 bg-gray-100 text-gray-600 hover:bg-red-500 hover:text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none shadow-lg"
            aria-label="Close modal"
          >
            <FaTimes size={20} />
          </button>
        </div>
        <div className="p-4">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Project Dependency Graph
          </h1>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <div className="p-4 grid grid-cols-2 gap-6 bg-gray-50 rounded-lg shadow-inner">
                {projectInfo.map((item, index) => (
                  <div key={index} className="flex items-center">
                    <strong className="w-40 text-gray-700">{item.key}:</strong>
                    {item.key === 'Status' ? (
                      <Badge color="green">{item.value}</Badge>
                    ) : (
                      <span className="text-gray-600">{item.value}</span>
                    )}
                  </div>
                ))}
              </div>

              <div className="mt-6 p-4 border rounded-lg bg-gray-100 w-auto shadow-md h-auto flex justify-center items-center">
                <div
                  className="w-full h-full"
                  style={{ backgroundColor: 'white' }}
                >
                  <Graphviz
                    dot={dependencyGraph}
                    options={{
                      width: '100%',
                      height: '18rem',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DependencyGraph;
