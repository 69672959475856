import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  EProjectStatus,
  getProjectDependencies,
  getProjectDetails,
  IProjectApiObject,
} from 'src/pages/dashboard/dashboard.service';
import dayjs from 'dayjs';
import { fetchUsers, IUser } from 'src/pages/admin/users/users.service';
import {
  getDeploySettings,
  getGlobalAdminProjectSettings,
  getGlobalProjectSettings,
  getTransformGlobalAdminProjectSettings,
} from 'src/pages/admin/global-project-settings/global-project-settings.service';
import {
  getInvitesAPI,
  getProjectDetail,
} from 'src/pages/project-overview/project-overview.service';
import { addNote, getProjectNotes } from 'src/pages/notes/notes.service';
import { IProjectFormData } from 'src/pages/project-creation/create-project.types';
import { createNewProject } from 'src/pages/project-creation/project-creation.service';

import { MissingPageService } from '../../services/missing-artifacts.service';
import {
  getLicenseData,
  getLicenseGraph,
  getLicenseMetadata,
  getTotalLicenseCount,
} from 'src/pages/admin/license/license.service';
import { updateProjectDetails } from 'src/pages/admin/drafts/components/drafts.service';
import { getProjectReports } from 'src/pages/project-report-page/project-report-page.service';
import { getProjectSupportTickets } from 'src/pages/support-tickets/support-ticket.service';
import { getDocumentationPageProtectedUrl } from 'src/pages/header/header.service';
import { fetchReportData } from 'src/pages/intent-pages/report/report.service';
import { fetchCallChainArtifactZipData } from 'src/hooks/call-chain.service';
import {
  getAppVersion,
  IAppVersion,
} from 'src/pages/admin/version/version.service';
import { fetchTransformationStatus } from 'src/pages/project-overview/card/stages-component/stages-component.service';
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_GATEWAY_URL }),
  endpoints: builder => ({
    getProjects: builder.query<
      IProjectApiObject[],
      { status?: EProjectStatus } | void
    >({
      queryFn: async arg => {
        const { status } = arg || {};
        const response = await getProjectDetails();

        const filteredResponse = status
          ? response.filter(project => {
              if (status === EProjectStatus.DRAFT)
                return project.teamMemberCount === 0;
              return project.projectStatus === status;
            })
          : response;
        const formattedResponse = filteredResponse.map((project: any) => {
          const timeLine = `${dayjs(project.startDate).format('MMM DD, YYYY')} - ${dayjs(project.endDate).format('MMM DD, YYYY')}`;
          return {
            ...project,
            description: [timeLine, ...(project.description || [])],
          };
        });
        return { data: formattedResponse };
      },
      keepUnusedDataFor: 60,
    }),
    getKnowledgeBaseProtectedUrl: builder.query<{ url: string }, void>({
      queryFn: async () => {
        const response = await getDocumentationPageProtectedUrl();
        return { data: response };
      },
    }),
    getProjectDependencies: builder.query<any, void>({
      queryFn: async () => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const projects = await getProjectDependencies();
            resolve({ data: Array.isArray(projects) ? projects : [] });
          }, 1000);
        });
      },
    }),
    getUsers: builder.query<IUser[], void>({
      queryFn: async () => {
        const users = await fetchUsers();
        return { data: Array.isArray(users) ? users : [] };
      },
    }),
    getAppVersion: builder.query<IAppVersion[], void>({
      queryFn: async () => {
        const version = await getAppVersion();
        return { data: Array.isArray(version) ? version : [] };
      },
    }),
    getGlobalSettings: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const settings = getGlobalProjectSettings(arg.projectId);
        return { data: settings };
      },
    }),
    getLicense: builder.query<any, void>({
      queryFn: async () => {
        const response = await getLicenseData();
        return { data: response };
      },
    }),
    getLicenseGraphAPI: builder.query<any, void>({
      queryFn: async () => {
        const response = await getLicenseGraph();
        return { data: response };
      },
    }),
    getTotalLicense: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const settings = getTotalLicenseCount(arg.projectId);
        return { data: settings };
      },
    }),
    getProjectReportData: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const response = fetchReportData(arg.projectId);
        return { data: response };
      },
    }),
    getGlobalProjectAdminSettings: builder.query<any, void>({
      queryFn: async () => {
        const response = await getGlobalAdminProjectSettings();
        return { data: response };
      },
    }),
    getTransformationGlobalProjectAdminSettings: builder.query<any, void>({
      queryFn: async () => {
        const response = await getTransformGlobalAdminProjectSettings();
        return { data: response };
      },
    }),
    getGlobalProjectDeploySettings: builder.query<any, void>({
      queryFn: async () => {
        const response = await getDeploySettings();
        return { data: response };
      },
    }),
    getProjectDetail: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const settings = await getProjectDetail(arg.projectId);
        return { data: settings };
      },
    }),
    getProjectReports: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const reports = await getProjectReports(arg.projectId);
        return { data: reports };
      },
    }),
    getProjectSupportTickets: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const supportTickets = await getProjectSupportTickets(arg.projectId);
        return { data: supportTickets };
      },
    }),
    getInvitesAPI: builder.query<any, void>({
      queryFn: async () => {
        const response = await getInvitesAPI();
        const formattedResponse = response.map((project: any) => {
          const timeLine = `${dayjs(project.startDate).format('MMM DD, YYYY')} - ${dayjs(project.endDate).format('MMM DD, YYYY')}`;
          return {
            ...project,
            description: [timeLine, ...(project.description || [])],
          };
        });
        return { data: formattedResponse };
      },
    }),
    getProjectNotes: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const notes = await getProjectNotes(arg.projectId);
        return { data: notes };
      },
    }),
    addProjectNote: builder.mutation<
      any,
      { projectId: string; subject: string; description: string }
    >({
      queryFn: async ({ projectId, subject, description }) => {
        const response = await addNote(projectId, subject, description);
        return { data: response };
      },
    }),
    createNewProject: builder.mutation<any, IProjectFormData>({
      queryFn: async project => {
        const response = await createNewProject(project);
        return { data: response };
      },
    }),
    updateProjectTeamMembers: builder.mutation<
      any,
      {
        projectId: number;
        project: { teamMembers: IUser[]; projectLead: IUser };
      }
    >({
      queryFn: async ({ projectId, project }) => {
        const response = await updateProjectDetails(projectId, project);
        return { data: response };
      },
    }),
    getMissingArtifacts: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingArtifacts();
        return { data: response };
      },
    }),
    getMissingDynamicCalls: builder.query<
      { missingData: any; ignoredData: any; programList: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingDynamicCalls();
        return { data: response };
      },
    }),
    getMissingSymbols: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingSymbols();
        return { data: response };
      },
    }),
    getMissingPrograms: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingPrograms();
        return { data: response };
      },
    }),
    getMissingProcs: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingProcs();
        return { data: response };
      },
    }),
    getMissingControlCards: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingControlCards();
        return { data: response };
      },
    }),
    getMissingMetaData: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingMetadata();
        return { data: response };
      },
    }),
    getCallChainZipData: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const response = await fetchCallChainArtifactZipData(arg.projectId);
        return { data: response };
      },
      keepUnusedDataFor: 30,
    }),
    getTransformationStatus: builder.query<any, { projectId: string }>({
      queryFn: async arg => {
        const response = await fetchTransformationStatus(arg.projectId);
        return { data: response };
      },
    }),
    getLicenseMetadata: builder.query<any, void>({
      queryFn: async () => {
        const response = await getLicenseMetadata();
        return { data: response };
      },
    }),
  }),
});

export const {
  useGetProjectDependenciesQuery,
  useGetProjectsQuery,
  useGetUsersQuery,
  useGetAppVersionQuery,
  useGetGlobalSettingsQuery,
  useGetProjectDetailQuery,
  useLazyGetProjectDetailQuery,
  useGetProjectNotesQuery,
  useGetInvitesAPIQuery,
  useAddProjectNoteMutation,
  useCreateNewProjectMutation,
  useUpdateProjectTeamMembersMutation,
  useGetMissingArtifactsQuery,
  useLazyGetMissingArtifactsQuery,
  useGetMissingDynamicCallsQuery,
  useGetMissingSymbolsQuery,
  useGetMissingProcsQuery,
  useGetMissingProgramsQuery,
  useGetMissingControlCardsQuery,
  useGetMissingMetaDataQuery,
  useGetLicenseQuery,
  useGetGlobalProjectAdminSettingsQuery,
  useGetProjectSupportTicketsQuery,
  useGetProjectReportsQuery,
  useGetLicenseGraphAPIQuery,
  useGetTotalLicenseQuery,
  useLazyGetKnowledgeBaseProtectedUrlQuery,
  useGetProjectReportDataQuery,
  useLazyGetCallChainZipDataQuery,
  useLazyGetProjectDependenciesQuery,
  useLazyGetProjectsQuery,
  useLazyGetLicenseMetadataQuery,
  useGetTransformationStatusQuery,
  useGetGlobalProjectDeploySettingsQuery,
  useGetTransformationGlobalProjectAdminSettingsQuery
} = apiSlice;
