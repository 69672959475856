import axios from 'axios';
import { INoteCardProps } from 'src/components/notes-card/notes-card.types';
import { externalConfig } from 'src/utils/misc.utils';

export const getProjectNotes = async (
  projectId: string,
): Promise<INoteCardProps[]> => {
  const api = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/notes/${projectId}`;
  // const api = 'http://localhost:3001/notes';
  const response = await axios.get(api);
  return response.data.data || [];
};

export const addNote = async (
  projectId: string,
  subject: string,
  description: string,
) => {
  const api = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/note/${projectId}`;

  const response = await axios.post(api, {
    subject,
    description,
  });

  if (response.status !== 200) {
    throw new Error(response.data.message);
  }

  console.log('Add note', response.data);
  return response.data;
};
