import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import NotesFloatingButton from '../notes-floating-button/notes-floating-button';
import { useState } from 'react';
import { useAddProjectNoteMutation, useGetProjectNotesQuery } from 'src/redux/api-slice';
import { useToast } from 'src/hooks/use-toast';

const Widget = ({ closePopover }: any) => {
  const searchParams = new URLSearchParams(window.location.search);
  const projectId = searchParams.get('projectId') || '';

  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [addProjectNote, { isLoading, error, data }] =
    useAddProjectNoteMutation();

  const { toast } = useToast();

  const {
    refetch
  } = useGetProjectNotesQuery({ projectId });

  const handleSubmit = async () => {
    const response = await addProjectNote({
      projectId,
      subject,
      description: content,
    });

    if (response.error || !response.data) {
      console.error('Error adding note:', response.error);
      toast({
        title: 'Error',
        description: 'Error adding note! Please try again.',
        className:
          'bg-red-50 text-red-800 border-l-4 border-red-500 p-4 shadow-md rounded-md',
      });
      closePopover();
      return;
    }

    toast({
      title: 'Success',
      description: 'Note added successfully',
    });
    await refetch();
    closePopover();
  };

  return (
    <div className="p-4">
      <div>
        <h1 className="text-md font-semibold mb-2">Add Note</h1>
      </div>
      <div className="w-full h-[1px] bg-greyCardOutline my-2" />
      <div
        style={{
          marginBottom: '.5rem',
          lineHeight: '1.5',
          color: '#374151',
        }}
      >
        <label
          htmlFor="email"
          style={{
            fontSize: '0.8rem',
            lineHeight: '1.75',
            color: '#4a5568',
            marginBottom: 0,
          }}
        >
          Subject *
        </label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={subject}
          placeholder="Subject"
          onChange={e => setSubject(e.target.value)}
          style={{
            width: '100%',
            borderRadius: '0',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            padding: '0.5rem 0.75rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            color: '#333',
            outline: 'none',
            transition: 'color 0.2s ease-in-out',
            height: 'unset',
            fontWeight: '400',
          }}
        />
      </div>
      <div
        style={{
          marginBottom: '.5rem',
          marginTop: '.5rem',
        }}
      >
        <label
          htmlFor="message"
          style={{
            fontSize: '0.8rem',
            lineHeight: '1.75',
            color: '#4a5568',
            marginBottom: 0,
          }}
        >
          Content *
        </label>
        <textarea
          id="content"
          name="content"
          placeholder="Add caption..."
          value={content}
          onChange={e => setContent(e.target.value)}
          style={{
            height: '7rem',
            width: '100%',
            borderRadius: '0',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            padding: '0.5rem 0.75rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            color: '#333',
            outline: 'none',
            transition: 'color 0.2s ease-in-out',
            fontWeight: '400',
          }}
        ></textarea>
      </div>
      <button
        type="submit"
        onClick={handleSubmit}
        disabled={!subject || !content || isLoading}
        style={{
          borderRadius: 4,
          backgroundColor: 'rgb(19,98,223)',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          fontSize: '.9rem',
          color: '#fff',
          borderWidth: 0,
          opacity: !subject || !content ? 0.5 : 1,
        }}
      >
        {isLoading ? 'Loading...' : 'Add Note'}
      </button>
    </div>
  );
};

const AddNotesWidget: React.FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger>
        <NotesFloatingButton onClick={handleTogglePopover} />
      </PopoverTrigger>
      <PopoverContent className="m-4 mb-2 mr-8 bg-gray-50">
        <Widget closePopover={handleTogglePopover} />
      </PopoverContent>
    </Popover>
  );
};

export default AddNotesWidget;
