import TransformSettings from '../project-settings-page/project-transformation';
import SmartDoc from '../project-settings-page/smart-doc';
import ExperimentalConfig from '../project-settings-page/experimental-config-settings';
import { TabProps } from './header-navigation.type';
import DeploySettings from '../project-settings-page/deploy-settings';


const HeaderNavigation: React.FC<TabProps> = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { name: 'SmartDoc' },
    { name: 'Transformation' },
    { name: 'Experimental Config' },
    { name: 'Deploy' },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Transformation':
        return <TransformSettings />;
      case 'SmartDoc':
        return <SmartDoc />;
      case 'Experimental Config':
        return <ExperimentalConfig />;
      case 'Deploy':
        return <DeploySettings />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex space-x-4 mb-6 justify-left">
        {tabs.map(tab => (
          <button
            key={tab.name}
            onClick={() => setActiveTab(tab.name)}
            className={`py-2 px-4 rounded-full ${
              activeTab === tab.name
                ? 'bg-black text-white'
                : 'bg-gray-200 text-black'
            }`}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {renderTabContent()}
    </div>
  );
};

export default HeaderNavigation;
