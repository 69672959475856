import React, { useEffect, useMemo, useState } from 'react';
import {
  FaCheckCircle,
  FaList,
  FaProjectDiagram,
  FaRegClock,
  FaRunning,
  FaUserPlus,
} from 'react-icons/fa';
import 'react-circular-progressbar/dist/styles.css';
import ProjectTile from 'src/components/project-tile/project-tile';
import ProgressSpinner from 'src/components/progress-spinner/progress-spinner';
import { SlOptions } from 'react-icons/sl';
import { AvatarStack } from 'src/components/ui/avatar-stack';
import SearchInput from 'src/components/search-input/search-input';
import { MultiSelect } from 'src/components/ui/multi-select';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { setActiveModal } from 'src/redux/app-global';
import { EAppModal } from 'src/redux/app-global/app-global.types';
import ProjectWidget from '../../project-widget/project-widget';
import { useUser } from 'src/hooks/useUser';
import { useGetInvitesAPIQuery } from 'src/redux/api-slice';
import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';
import {
  downloadProjectReport,
  EProjectStatus,
  IProjectApiObject,
} from '../dashboard.service';
import Swal from 'sweetalert2';
import { INavigationTabProps } from './navigation-tab.interface';
import { useToast } from 'src/hooks/use-toast';
import AdminOnly from 'src/components/admin-only/admin-only';

export const getProgressStateColor = (progress: number) => {
  if (progress < 40) return '#f56565';
  if (progress < 70) return '#ed8936';
  return '#48bb78';
};

const projectTypeList = [
  { value: 'SMART_DOC', label: 'SmartDoc' },
  { value: 'TRANSFORMATION', label: 'Transformation' },
];

const ProjectNavigationTabs = ({
  projects,
  projectInvites,
  isLoading,
  refetchInvites,
  refetchProjects,
}: INavigationTabProps) => {
  const [activeTab, setActiveTab] = useState<string>('All');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedProjectType, setSelectedProjectType] = useState<string[]>([
    'SMART_DOC',
  ]);
  const [selectedTimeLine, setSelectedTimeLine] = useState<string>('Ascending');
  const [selectedProject, setSelectedProject] = useState<
    IProjectApiObject | undefined
  >(undefined);
  const user = useUser();
  const dispatch = useDispatch();
  const activeModal = useSelector(
    (state: RootState) => state.appGlobal.activeModal,
  );

  const { toast } = useToast();

  const navigate = useNavigate();

  const filteredProjects = useMemo(() => {
    if (activeTab === 'Project Type') {
      if (!selectedProjectType.length) return projects;
      return projects.filter(project =>
        selectedProjectType.includes(project.projectType),
      );
    }

    if (activeTab === 'Timeline') {
      if (!selectedTimeLine) return projects;
      return [...projects].sort((a, b) => {
        if (selectedTimeLine === 'Ascending') {
          return Number(new Date(a.endDate)) - Number(new Date(b.endDate));
        } else if (selectedTimeLine === 'Descending') {
          return Number(new Date(b.endDate)) - Number(new Date(a.endDate));
        }
        return Number(new Date(a.endDate)) - Number(new Date(b.endDate));
      });
    }

    let result: IProjectApiObject[] = [];
    switch (activeTab) {
      case 'Invites':
        result = projectInvites || [];
        break;

      case 'Active':
        result = projects.filter(
          project => project.projectStatus === EProjectStatus.ACTIVE,
        );
        break;

      case 'Completed':
        result = projects.filter(
          project => project.projectStatus === EProjectStatus.COMPLETED,
        );
        break;

      case 'Archived':
        result = projects.filter(
          project => project.projectStatus === EProjectStatus.ARCHIVE,
        );
        break;

      default:
        result = projects.filter(
          project => project.projectStatus !== EProjectStatus.ARCHIVE,
        );
    }

    if (searchValue) {
      return result.filter(project =>
        project.projectName.toLowerCase().includes(searchValue.toLowerCase()),
      );
    } else {
      return result;
    }
  }, [
    projects,
    projectInvites,
    activeTab,
    searchValue,
    selectedProjectType,
    selectedTimeLine,
  ]);

  let tabs = [
    { name: 'All', icon: <FaList />, notificationCount: 0 },
    {
      name: 'Invites',
      icon: <FaUserPlus />,
      notificationCount: projectInvites?.length,
    },
    { name: 'Active', icon: <FaRunning />, notificationCount: 0 },
    { name: 'Project Type', icon: <FaProjectDiagram />, notificationCount: 0 },
    { name: 'Timeline', icon: <FaRegClock />, notificationCount: 0 },
    { name: 'Completed', icon: <FaCheckCircle />, notificationCount: 0 },
    { name: 'Archived', icon: <FaList />, notificationCount: 0 },
  ];

  if (user?.role === 'Admin') {
    tabs = tabs.filter(tab => tab.name !== 'Invites');
  }

  const handleInvitation = async (project: { projectId: string }) => {
    if (!project?.projectId) {
      console.warn('Invalid project data. Cannot proceed with archiving.');
      return;
    }
    try {
      const { REACT_APP_GATEWAY_URL } = externalConfig;
      const response = await axios.put(
        `${REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/update-invite-status`,
        {
          projectId: project.projectId,
          status: 'APPROVED',
        },
      );

      if (response?.data?.status === 'SUCCESS') {
        Swal.fire({
          icon: 'success',
          title: 'Invitation Successfully Approved',
          text: `The Invitation has been successfully approved.`,
        });
        refetchInvites();
        refetchProjects();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Invitation Failed',
          text: response?.data?.message,
        });
      }
    } catch (error) {
      console.error('Error archiving the project:', error);
      Swal.fire({
        icon: 'error',
        title: 'Invitation Failed',
        text: 'An error occurred while attempting to invite. Please try again later.',
      });
    }
  };

  const handleArchiveProject = async (project: { projectId: string }) => {
    if (!project?.projectId) {
      console.warn('Invalid project data. Cannot proceed with archiving.');
      return;
    }
    try {
      const { REACT_APP_GATEWAY_URL } = externalConfig;
      const response = await axios.put(
        `${REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/update-status`,
        {
          projectId: project.projectId,
          status: 'ARCHIVE',
        },
      );

      if (response?.data?.status === 'SUCCESS') {
        Swal.fire({
          icon: 'success',
          title: 'Project Archived',
          text: `The project with ID: ${project.projectId} has been successfully archived.`,
        });
        refetchProjects();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Archive Failed',
          text: response?.data?.message,
        });
      }
    } catch (error) {
      console.error('Error archiving the project:', error);
      Swal.fire({
        icon: 'error',
        title: 'Archive Failed',
        text: 'An error occurred while attempting to archive the project. Please try again later.',
      });
    }
  };

  const handleShowProjectDependency = (project: IProjectApiObject) => {
    setSelectedProject(project);
    dispatch(setActiveModal(EAppModal.PROJECT_WIDGET));
  };

  const handleCloseModal = () => {
    dispatch(setActiveModal(null));
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveModal(null));
    };
  }, [dispatch]);

  const renderTabContent = () => {
    if (activeTab === 'Invites') {
      if (filteredProjects.length === 0) {
        return (
          <>
            <div></div>
            <div className="text-center">
              <p className="font-semibold text-2xl text-center text-gray-600">
                No Invites Projects Available
              </p>
              <p className="text-gray-500 text-center text-sm mt-2 mb-6">
                You have no pending project invites at the moment.
              </p>
            </div>
          </>
        );
      }
      return filteredProjects?.map((item: any) => (
        <React.Fragment key={item.projectId}>
          <ProjectTile
            key={item.projectId}
            // onClick={() => handleShowProjectDependency(item)}
            topLeftIcon={
              <ProgressSpinner
                progress={Number(item.completePercentage.toFixed(2))}
                progressColor={getProgressStateColor(item.completePercentage)}
              />
            }
            topRightContent={
              <div>
                <AvatarStack
                  maxAvatarsAmount={3}
                  avatars={
                    item.projectMemberList?.map(member => member.name) || []
                  }
                />
              </div>
            }
            actionCta={
              <button
                onClick={e => {
                  e.stopPropagation();
                  handleInvitation(item);
                }}
                className="px-6 py-2 bg-blue-500 rounded-md text-white text-sm font-semibold"
              >
                Accept
              </button>
            }
            title={item.projectName}
            subTitle={item.projectType}
            {...item}
          />
          {/* <ProjectWidget
            project={selectedProject}
            isOpen={activeModal === EAppModal.PROJECT_WIDGET}
            onClose={handleCloseModal}
          /> */}
        </React.Fragment>
      ));
    }
    return filteredProjects.map(item => (
      <React.Fragment key={item.projectId}>
        <ProjectTile
          key={item.projectId}
          onClick={() => {
            handleShowProjectDependency(item);
          }}
          topLeftIcon={
            <ProgressSpinner
              progress={Number(item.completePercentage.toFixed(2))}
              progressColor={getProgressStateColor(item.completePercentage)}
            />
          }
          topRightContent={
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger className="focus:outline-none">
                  <SlOptions />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuLabel>Project Settings</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => {
                      navigate(
                        ROUTES.PROJECT_OVERVIEW +
                          `?projectId=${item.projectId}`,
                      );
                    }}
                  >
                    View
                  </DropdownMenuItem>
                  <AdminOnly>
                    <DropdownMenuItem
                      onClick={e => {
                        e.stopPropagation();
                        handleArchiveProject({
                          projectId: item.projectId.toString(),
                        });
                      }}
                    >
                      Archive
                    </DropdownMenuItem>
                  </AdminOnly>
                  <DropdownMenuItem
                    onClick={e => {
                      e.stopPropagation();
                      const loadingToast = toast({
                        title: `Downloading Project "${item.projectName}" Report`,
                        description: 'Please wait for a moment...',
                        duration: 20000, // 30 seconds
                        className:
                          'bg-blue-50 text-blue-800 border-l-4 border-blue-500 p-4 shadow-md rounded-md',
                      });
                      downloadProjectReport(
                        item.projectId.toString(),
                        item.projectName,
                      ).finally(() => {
                        loadingToast?.dismiss();
                      });
                    }}
                  >
                    Download Project Report
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          }
          actionCta={
            <div className="min-h-[40px]">
              <AvatarStack
                maxAvatarsAmount={3}
                avatars={
                  item.projectMemberList?.map(member => member.name) || []
                }
              />
            </div>
          }
          title={item.projectName}
          subTitle={item.projectType}
          {...item}
        />
        <ProjectWidget
          project={selectedProject}
          isOpen={activeModal === EAppModal.PROJECT_WIDGET}
          onClose={handleCloseModal}
        />
      </React.Fragment>
    ));
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">My Projects</h1>
      <div className="flex overflow-x-auto space-x-6 pb-6 border-b-2 border-gray-200">
        {tabs.map(tab => (
          <div
            key={tab.name}
            onClick={() => {
              setActiveTab(tab.name);
              dispatch(setActiveModal(null));
            }}
            className={`cursor-pointer flex items-center space-x-4 py-3 px-6 rounded-full transition-all duration-300 ${
              activeTab === tab.name
                ? 'bg-blue-500 text-white shadow-lg scale-100'
                : 'text-gray-700 bg-blue-50 hover:text-blue-600'
            } focus:outline-none`}
          >
            <span className="text-xl">{tab.icon}</span>
            <span className="text-md font-semibold">{tab.name}</span>
            {tab.notificationCount > 0 && (
              <div
                className="ml-2 bg-red-500 text-white rounded-full px-2 text-xs h-[1.5rem] w-[1.5rem] flex justify-center items-center font-semibold"
                // style={{
                //   background: activeTab === tab.name ? 'white' : 'red',
                //   color: activeTab === tab.name ? 'red' : 'white',
                // }}
              >
                {tab.notificationCount}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="py-2 mt-6 pt-1">
        {activeTab === 'Project Type' ? (
          <div className="max-w-md mx-auto">
            <MultiSelect
              options={projectTypeList}
              onValueChange={setSelectedProjectType}
              defaultValue={selectedProjectType}
              placeholder="Select Project Type"
              variant="inverted"
              animation={2}
              maxCount={3}
            />
          </div>
        ) : activeTab === 'Timeline' ? (
          <div className="mx-auto max-w-md">
            <Select
              value={selectedTimeLine}
              onValueChange={newValue => setSelectedTimeLine(newValue)}
            >
              <SelectTrigger className="min-h-14">
                <SelectValue placeholder="Select Timeline" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Ascending">Ascending</SelectItem>
                <SelectItem value="Descending">Descending</SelectItem>
              </SelectContent>
            </Select>
          </div>
        ) : (
          <SearchInput
            placeholder="Search by Project Name..."
            value={searchValue}
            onChange={setSearchValue}
          />
        )}
      </div>
      <div
        style={{
          minHeight:
            Math.max(projects.length, projectInvites?.length) > 3
              ? '35rem'
              : '17rem',
        }}
        className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4"
      >
        {isLoading ? <p>Loading...</p> : renderTabContent()}
      </div>
    </div>
  );
};

export default ProjectNavigationTabs;
