import { useSelector } from 'react-redux';
import SVGExternalLink from '../../../assets/images/svg/external-link.icon';
import Card from '../../../components/card/card';
import { RootState } from '../../../redux/store';
import ProgressBar from '../../../components/progress-bar/progress-bar';

const DefaultUploadingArtifacts = () => {
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );
  const scanProgress = useSelector(
    (state: RootState) => state.appGlobal.scanProgress,
  );
  function openLink(url: string) {
    window.open(url, '_blank');
  }

  return (
    <div className="h-full w-full">
      <div className="text-center flex flex-col justify-center items-center mt-4 gap-4">
        <h1 className="text-3xl font-semibold">{currentContent?.heading}</h1>
        <p className="max-w-[70%]">{currentContent?.description1}</p>
        <div className="my-4 flex flex-col justify-center items-center">
          <p className="pb-2">{currentContent?.processingTxt}</p>
          <div className="w-full my-4">
            <ProgressBar progress={scanProgress || 0} />
          </div>
          <div className="animate-pulse">{scanProgress || 0}%</div>
        </div>
        <p className="mt-2">
          While we process your artifacts, feel free to explore some of our
          fascinating case studies listed below.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 pb-8">
          <Card
            title={currentContent?.cardTitle1}
            description={currentContent?.cardDescription1}
            onClick={() => null}
            customCTA={
              <p
                onClick={() => openLink('https://cloudframe.com/resource/large-credit--card-company-successfully-migrates-two-cobol-applications-with-over-200-batch-jobs-in-record-time')}
                className=" flex flex-row items-center gap-[2px] text-sm px-4 py-[4px] cursor-pointer bg-grey20 rounded-full shadow-sm hover:shadow-md hover:scale-105"
              >
                Read more{' '}
                <span>
                  <SVGExternalLink />
                </span>
              </p>
            }
          />
          <Card
            title={currentContent?.cardTitle2}
            description={currentContent?.cardDescription2}
            onClick={() => null}
            customCTA={
              <p
                onClick={() => openLink('https://cloudframe.com/resource/financial--services-firm-accelerates-application-modernization-by-automating-junit-test-case-generation')}
                className=" flex flex-row items-center gap-[2px] text-sm px-4 py-[4px] cursor-pointer bg-grey20 rounded-full shadow-sm hover:shadow-md hover:scale-105"
              >
                Read more{' '}
                <span>
                  <SVGExternalLink />
                </span>
              </p>
            }
          />
          <Card
            title={currentContent?.cardTitle3}
            description={currentContent?.cardDescription3}
            onClick={() => null}
            customCTA={
              <p
                onClick={() => openLink('https://cloudframe.com/resource/global---bank-finds-a-self-funding-approach-to-mainframe-modernization-with-gft-and-cloudframe')}
                className=" flex flex-row items-center gap-[2px] text-sm px-4 py-[4px] cursor-pointer bg-grey20 rounded-full shadow-sm hover:shadow-md hover:scale-105"
              >
                Read more{' '}
                <span>
                  <SVGExternalLink />
                </span>
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultUploadingArtifacts;
