import { Button, Checkbox, Form, Input, message, Modal, Select, Upload } from "antd";
import { useState } from "react";
import { FaBitbucket, FaGithub, FaGitlab } from "react-icons/fa";
import { UploadOutlined } from '@ant-design/icons';
import { externalConfig } from "src/utils/misc.utils";
import { getSearchParameter } from "src/utils/url.utils";
import axios from "axios";
import Swal from "sweetalert2";

const ModalDeploy = ({ setRepoModalVisible, isRepoModalVisible, selectedPlatform, formSubmitted, data, index, postValidation, repositories }) => {

    const [fileList, setFileList] = useState<any[]>([]);
    const projectId = getSearchParameter('projectId');
    
    const props = {
        onRemove: (file: any) => {
            setFileList((prevFileList) => {
                const index = prevFileList.indexOf(file);
                const newFileList = prevFileList.slice();
                newFileList.splice(index, 1);
                return newFileList;
            });
        },
        beforeUpload: (file: any) => {
            setFileList((prevFileList) => [...prevFileList, file]);
            return false;
        },
        fileList,
    };

    const handleUpload = () => {
        if (fileList.length === 0) {
            message.error('Please select a file before uploading.');
            return;
        }
        message.success('Upload successful!');
    };

    const platformTitles = {
        github: 'GitHub',
        gitlab: 'GitLab',
        bitbucket: 'Bitbucket'
    };

    const platformIcons = {
        github: <FaGithub />,
        gitlab: <FaGitlab />,
        bitbucket: <FaBitbucket />
    };
    const handleRepoModalClose = () => {
        setRepoModalVisible(false);
    };

    const [form] = Form.useForm();

    const handleFormSubmit = () => {
        form
            .validateFields()
            .then(async (values) => {
                console.log('Form values:', values);
                const requestBody = {
                    repositoryName: values.repository,
                    artifactName: data[index].jobName,
                    commitMessage: values.commitMessage,
                    branchName: values.branch
                }
                const url = `${externalConfig.REACT_APP_TEST_SERVICE}/deploy/${projectId}`;
                try {                              
                    formSubmitted();
                    const response = await axios.post(url,requestBody)
                    if(response.data.status!== 'SUCCESS') {                        
                        Swal.fire({
                            icon: 'error',
                            title: 'Server Error',
                            text: `${response.data.message} Please contact CloudFrame Support Team for further assistance.`,
                            confirmButtonColor: '#f27474',
                        });                        
                        postValidation(false, response.data.data?.artifactName, response.data.data?.deployStatus, response.data.data?.deployedDate, response.data.data?.deployUrl);
                    }
                    else {
                        postValidation(true, response.data.data?.artifactName, response.data.data?.deployStatus, response.data.data?.deployedDate, response.data.data?.deployUrl);
                    }
                } catch (error) {
                    postValidation(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Unable to push artifacts to Github. Please contact CloudFrame Support Team for further assistance.',
                        confirmButtonColor: '#f27474',
                    });
                }
            })
            .catch((errorInfo) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Failed',
                    text: 'Please enter all the necessary details required for deploying to GitHub.',
                    confirmButtonColor: '#f27474',
                });
            });
    };
    return (
        <Modal
            title={
                <div className="text-center font-semibold text-lg flex items-center justify-center">
                    {platformIcons[selectedPlatform]}
                    <span className="ml-2">Deploy to {platformTitles[selectedPlatform]}</span>
                </div>
            }
            visible={isRepoModalVisible}
            onOk={handleFormSubmit}
            onCancel={handleRepoModalClose}
            footer={[
                <Button key="back" onClick={handleRepoModalClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleFormSubmit}>
                    Deploy
                </Button>,
            ]}
        >
            <hr className="my-4 border-gray-300" />
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    branch: 'master',
                    commitMessage: 'Initial Commit',
                    visibility: 'public',
                    autoInit: true,
                    accessToken: 'someAccessToken',
                }}
            >
                
                <Form.Item label="Repository Name" name="repository">
                <Select placeholder="Select a Repository">
                    {repositories.map((repo, index) => (
                    <Select.Option key={index} value={repo}>
                        {repo}
                    </Select.Option>
                    ))}
                </Select>
                </Form.Item>
                <Form.Item label="Branch Name" name="branch" rules={[{ required: true, message: 'Please enter a branch name!' }]}>
                    <Input placeholder="Enter branch name (default: master)" />
                </Form.Item>
                <Form.Item
                    label="Commit Message"
                    name="commitMessage"
                    rules={[{ required: true, message: 'Please enter a commit message!' }]}
                >
                    <Input placeholder="Enter a commit message" />
                </Form.Item>
                {/*hidden for now */}
                {/* <Form.Item label="Repository Visibility" name="visibility">
                    <Select>
                        <Select.Option value="public">Public</Select.Option>
                        <Select.Option value="private">Private</Select.Option>
                        <Select.Option value="internal">Internal</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="autoInit" valuePropName="checked">
                    <Checkbox>Initialize with README</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Upload Deployment Script File (Optional)"
                    name="deploymentScript"
                >
                    <div>
                        <Upload {...props} multiple>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </div>
                </Form.Item> */}
            </Form>
        </Modal>
    )
}
export default ModalDeploy;