import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetProjectDetailQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { getProjectLead } from '../card/stages-component/stages-component.service';

const ProjectOverviewTile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('projectId') || ''; // || 'bd8dfb56-e06e-47d0-b37a-49a536e3c271';

  const { data, error, isLoading } = useGetProjectDetailQuery({ projectId });

  const projectLead = getProjectLead(data);

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load projects. Please try again later.',
    });
  }

  return (
    <div className="">
      <div className="p-6 bg-white rounded-md shadow-md">
        <div className="space-y-11">
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Project Name :
            </span>
            <span style={{ width: '10rem' }}>{data?.projectName || 'NA'}</span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Project Lead :
            </span>
            <span style={{ width: '10rem' }}>{projectLead?.name || 'NA'}</span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Project Timeline :
            </span>
            <span style={{ width: '10rem' }}>
              {formatDate(data?.startDate) || 'NA'}
              {data?.endDate ? ` - ${formatDate(data.endDate)}` : ''}
            </span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Project Type :
            </span>
            <span style={{ width: '10rem' }}>
              {data?.projectType
                ? data.projectType.charAt(0).toUpperCase() +
                  data.projectType.slice(1).toLowerCase()
                : 'NA'}
            </span>
          </div>
          <div className="flex justify-between">
            <span style={{ width: '10rem' }} className="font-bold">
              Complete Percentage :
            </span>
            <span style={{ width: '10rem' }}>
              {data?.completePercentage !== undefined &&
              data?.completePercentage !== null
                ? `${data.completePercentage.toFixed(2)}%`
                : 'NA'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverviewTile;
