import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export const getProjectDetail = async (projectId: string) => {
  try {
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/${projectId}`,
    );
    return res.data.data;
  } catch (error) {
    console.error('Error fetching project details:', error);
    return [];
  }
};

export const getInvitesAPI = async (): Promise<[]> => {
  try {
    // const res = await axios.get(`http://localhost:3001/projects`);
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/invite-members`,
    );
    return (
      res.data.data?.map((project: any) => {
        return {
          ...project,
          description: [
            `Stage Name - ${project.stage || 'DEFAULT'}`,
            `${project.teamMemberCount} Members`,
          ],
        };
      }) || []
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};
