import {
  FaFolderOpen,
  FaRunning,
  FaClock,
  FaCheckCircle,
} from 'react-icons/fa';
import ProjectNavigationTabs from './navigation-tab/navigation-tab';
import DashboardTile from 'src/components/dashboard-tile/dashboard-tile';
import ProjectDependency from './project-dependency/project-dependency';
import AdminWrapper from './admin-wrapper/admin-wrapper';
import useDashboard from './useDashboard';
import { FaBoxArchive } from 'react-icons/fa6';
import SVGLoader from 'src/assets/images/svg/loader.icon';

const tilesData = (summary: any) => [
  {
    title: 'Total Projects',
    value: summary.totalProjects,
    icon: <FaFolderOpen />,
    bgColor: 'bg-blue-200',
    percentageRate: '45%',
    iconColor: 'text-blue-500',
    percentageColor: 'text-green-500',
  },
  {
    title: 'Running Projects',
    value: summary.runningProjects,
    icon: <FaRunning />,
    bgColor: 'bg-yellow-100',
    percentageRate: '50%',
    iconColor: 'text-yellow-500',
    percentageColor: 'text-yellow-500',
  },
  {
    title: 'Dependency Hold',
    value: summary.blockedProjects,
    icon: <FaClock />,
    bgColor: 'bg-rose-200',
    percentageRate: '10%',
    iconColor: 'text-rose-400',
    percentageColor: 'text-red-500',
  },
  {
    title: 'Completed Projects',
    value: summary.completedProjects,
    icon: <FaCheckCircle />,
    bgColor: 'bg-green-200',
    percentageRate: '100%',
    iconColor: 'text-green-500',
    percentageColor: 'text-green-600',
  },
  {
    title: 'Archived Projects',
    value: summary.archivedProjects,
    icon: <FaBoxArchive />,
    bgColor: 'bg-ddlGrey20',
    percentageRate: '20%',
    iconColor: 'text-gray-500',
    percentageColor: 'text-gray-600',
  },
];

const Dashboard = () => {
  const {
    user,
    projects,
    summary,
    projectInvites,
    projectDependencies,
    selectedDateFilter,
    isLoading,
    refetchInvites,
    setSelectedDateFilter,
    refetchProjects,
  } = useDashboard();

  if (isLoading) {
    return (
      <div className="bg-whiteSmoothLight flex justify-center items-center h-screen -mt-[50px]">
        <div className="text-center">
          <SVGLoader />
          <p>Please wait...</p>
        </div>
      </div>
    );
  }

  return (
    <AdminWrapper>
      <div className="p-4 pb-[5rem] bg-[#F3F4F4] w-full">
        <div
          className="p-8 bg-white w-full"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
        >
          <div className="mb-8">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-2xl font-bold mb-2">
                Welcome back {user?.userId}
              </h1>

              <p className="text-sm font-semibold -mt-4 px-6 py-[4px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105">
                Role: {user?.role}
              </p>
            </div>
            <p className="text-lg text-gray-600">
              Manage and transform your COBOL projects effortlessly. Get started
              by selecting a project to convert into Java.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 bg-white">
            {tilesData(summary).map((tile, index) => (
              <DashboardTile
                key={index}
                title={tile.title}
                value={tile.value}
                icon={tile.icon}
                selectedDateFilter={selectedDateFilter}
                setSelectedDateFilter={setSelectedDateFilter}
                bgColor={tile.bgColor}
                iconColor={tile.iconColor}
                percentageColor={tile.percentageColor}
              />
            ))}
          </div>
        </div>
        <div>
          <div
            className="p-8 mt-[1rem] rounded-md bg-white"
            style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
          >
            <ProjectNavigationTabs
              isLoading={isLoading}
              refetchInvites={refetchInvites}
              refetchProjects={refetchProjects}
              projects={projects}
              projectInvites={projectInvites}
            />
          </div>
          <div
            className="p-8 mt-[1rem] rounded-md bg-white"
            style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
          >
            <ProjectDependency projectDependencies={projectDependencies} />
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default Dashboard;
