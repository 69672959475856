import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { VscRefresh } from "react-icons/vsc";
import { FaBitbucket, FaCheck, FaChevronDown, FaChevronUp, FaDownload, FaGithub, FaGitlab, FaTimes } from "react-icons/fa";
import { downloadCode } from "../transformation-page/transformation.service";
import "./deploy-chevron.css"
import ModalDeploy from "./modal-deploy/modal-deploy";
import DeployProgress from "./modal-deploy/deploy-progress-table";
import { externalConfig } from "src/utils/misc.utils";
import { getSearchParameter } from "src/utils/url.utils";
import axios from "axios";
import Swal from "sweetalert2";
import SVGLoader from "src/assets/images/svg/loader.icon";
import { helpTexts } from "src/helpTexts";
import HelpTextCallout from "src/components/help-text-callout/help-text-callout";

interface JobData {
    jobName: string;
    validated: string;
    progress: number;
    success: boolean;
    expanded: boolean;
    completionDate: string;
    deployStatus?: string;
    deployedDate?: string;
    isProgress?: boolean;
    deployUrl?: string;
}

const DeployChevron = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRepoModalVisible, setRepoModalVisible] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState('github');
    const [isFormSubmit, setIsFormSubmit] = useState(false)
    const projectId = getSearchParameter('projectId');
    const [data, setData] = useState<JobData[]>([]);
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState(-1);
    const [repositories, setRepositories] = useState<string[] | null>([]);
    const pageHelpText = helpTexts[0].DEPLOY_PAGE;
    const showModal = (index: number) => {
        setIndex(index);
        handleRepoModalOpen('github');
    };
    useEffect(() => {
        const fetchDeployStatus = async () => {
            setLoading(true)
            const url = `${externalConfig.REACT_APP_TEST_SERVICE}/deploy/status/${projectId}`;
            try {
                const response = await axios.get(url);
                const updatedData = response.data.data?.map((item) => ({
                    jobName: item.artifactName,
                    validated: item.deployStatus,
                    progress: 0,
                    success: item.deployStatus === 'PASS' ? true : false,
                    completionDate: item.deployedDate,
                    deployStatus: item.deployStatus,
                    deployUrl: item.deployUrl || '',
                }));
                setData(updatedData);
                setLoading(false)

            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Server Error',
                    text: 'Unable to fetch artifacts status for Deploy. Please contact CloudFrame Support Team for further assistance.',
                    confirmButtonColor: '#f27474',
                });
            }
        }
        const fetchRepositories = async () => {
            const url = `${externalConfig.REACT_APP_TEST_SERVICE}/deploy/repositories`;
            try {
                const response = await axios.get(url);
                if(response.data.data) {
                    setRepositories(response.data.data);
                }
                if(response.data.status === 'ERROR') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unable to Fetch Repositories',
                        text: `${response.data?.message} Please contact CloudFrame Support Team for further assistance.`,
                        confirmButtonColor: '#f27474',
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Server Error',
                    text: 'Unable to fetch artifacts status for Deploy. Please contact CloudFrame Support Team for further assistance.',
                    confirmButtonColor: '#f27474',
                });
            }
        }
        fetchDeployStatus();
        fetchRepositories();
    }, [])
    const formSubmitted = () => {
        setIsFormSubmit(true);
        setRepoModalVisible(false);
        setIsModalVisible(false);
        startProgress();
    }

    const postValidation = (status: boolean, artifactName: string, deployedStatus: string, deployedDate: string, deployUrl: string) => {
        const newData = [...data];
        newData[index].isProgress = false;
        if (status){            
            newData[index].success = deployedStatus === 'PASS' ? true : false;
            newData[index].deployStatus = deployedStatus;
            newData[index].completionDate = deployedDate;
            newData[index].deployUrl = deployUrl;
        }
        else
            newData[index].deployStatus = 'FAIL';
        setData(newData);
    }
    const startProgress = () => {
        const newData = [...data];
        newData[index].isProgress = true;
        setData(newData);
    }

    const handleRepoModalOpen = (platform: string) => {
        setSelectedPlatform(platform);
        setRepoModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleExpandToggle = (index) => {
        const updatedData = [...data];
        updatedData[index].expanded = !updatedData[index].expanded;
        setData(updatedData);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const platformTitles = {
        github: 'GitHub',
    };

    const platformIcons = {
        github: <FaGithub />,
        gitlab: <FaGitlab />,
        bitbucket: <FaBitbucket />
    };

    const getDeploymentUrl = (platform, repoUrl) => {
        const platformUrls = {
            github: `https://github.com/${repoUrl}`,
            gitlab: `https://gitlab.com/${repoUrl}`,
            bitbucket: `https://bitbucket.org/${repoUrl}`,
        };
        return platformUrls[platform];
    };

    return (
        <div>
            <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm"
                data-v0-t="card"
            >
                <div className="flex flex-col space-y-4 p-6 bg-[#0a61dc] text-white">
                    <div className='flex flex-row'>
                        <h2 className="text-2xl font-bold text-white">Jobs Ready for Deployment</h2>
                    </div>
                    <p className="text-sm text-white">The Deploy stage allows you to push the transformed code to GitHub. Once deployment is initiated, CodeNavigator establishes a connection with GitHub, commits the changes, and deploys the code.</p>
                </div>
                <hr />
                <div className="p-6">
                    <div className="relative w-full overflow-auto">
                        {loading ?
                            <div className="flex flex-col items-center justify-center">
                                <SVGLoader width={60} height={60} />
                                <p className="pb-2">Please wait...</p>
                            </div>
                            : (data.length === 0 ?
                                <div className="text-center p-4 text-red-600">
                                    Please transform artifacts in Transform Page to Deploy the
                                    artifacts.
                                </div>
                                : <table className="w-full caption-bottom text-sm">
                                    <thead className="[&amp;_tr]:border-b ">
                                        <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[150px]">
                                                <div className='flex items-center gap-2 justify-start text-sm '>Artifact Name<HelpTextCallout calloutText={pageHelpText[0].description}/></div>
                                            </th>
                                            <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[250px]">
                                                <div className='flex items-center gap-2 justify-center text-sm ml-4'>Deployment Status<HelpTextCallout calloutText={pageHelpText[1].description}/></div>
                                            </th>
                                            <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[250px]">
                                            <div className='flex items-center gap-2 justify-center text-sm ml-4'>Latest Deployment<HelpTextCallout calloutText={pageHelpText[2].description}/></div>
                                            </th>
                                            <th className="h-12 px-4 text-right align-middle font-medium text-muted-foreground w-[250px]">
                                            <div className='flex items-center gap-2 justify-end text-sm'>Deploy<HelpTextCallout calloutText={pageHelpText[3].description}/></div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="[&amp;_tr:last-child]:border-0">
                                        {data?.map((job, index) => (
                                            <React.Fragment key={index}>
                                                <tr
                                                    className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted`}
                                                >
                                                    <td className="p-4 align-middle text-left font-medium">
                                                        {job.jobName}
                                                    </td>
                                                    <td className="p-4 align-middle w-[250px] text-center text-green-400 font-semibold">
                                                        {job.success ? (
                                                            <FaCheck className="text-green-500 inline-block" title='Success' />
                                                        ) : (
                                                            job.deployStatus === 'FAIL' ? (
                                                                <FaTimes className="text-red-500 inline-block" title='Failed' />
                                                            ) : <div className='text-sm rounded-full bg-yellow-500 w-3.5 h-3.5 inline-block' title='Pending...'></div>
                                                        )}
                                                    </td>
                                                    <td className="p-4 align-middle w-[250px] text-center text-green-400 font-semibold">
                                                        {job.completionDate ? (
                                                            <div className="text-gray-500 text-sm font-semibold">
                                                                {job.completionDate}
                                                            </div>
                                                        ) : (
                                                            <div className="text-gray-400 text-sm font-semibold">
                                                                ----
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="p-4 align-middle w-[250px] text-right">
                                                        {(job.success && job.deployUrl !== '') ? (
                                                            <div className="flex gap-2 items-center justify-end">
                                                                <HelpTextCallout calloutText={pageHelpText[5].description}/>
                                                                <a
                                                                    href={job.deployUrl}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="flex items-center cursor-pointer text-sm bg-[#2ea44f] text-white px-4 py-2 rounded-md border border-transparent shadow-sm hover:bg-[#2c974b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2ea44f] transition-colors duration-200"
                                                                >
                                                                    <span className="mr-2">View in {platformTitles[selectedPlatform]}</span>
                                                                    {platformIcons[selectedPlatform]}
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <div className="flex gap-2 items-center justify-end text-sm">
                                                                <HelpTextCallout calloutText={pageHelpText[4].description}/>
                                                                <button className={`bg-cta text-white p-2 rounded-md ${job.isProgress ? 'opacity-50' : ''}`} onClick={() => showModal(index)} disabled={job.isProgress} >{job.deployStatus === 'FAIL' ? 'Retry Deploy' : 'Deploy Job'}</button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                                {isFormSubmit && job.isProgress && (
                                                    <tr className="bg-white">
                                                        <td className="p-4 bg-gray-100" colSpan={5}>
                                                            <div className="p-6 border bg-white border-blue-200 shadow-lg rounded-lg">
                                                                <DeployProgress
                                                                    selectedPlatform={selectedPlatform}
                                                                    job={job}
                                                                    setData={setData}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            )}

                    </div>
                </div>
            </div>
            <Modal
                title={
                    <div className="text-center font-semibold text-lg">
                        Deploy Job
                    </div>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <hr className="my-4 border-gray-300" />
                <div className="space-y-6">
                    {/* GitHub */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaGithub className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800 text-[22px]">Deploy to GitHub</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('github')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div>
                    {/* <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaBitbucket className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800 text-[22px]">Deploy to Bitbucket</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('bitbucket')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaGitlab className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800 text-[22px]">Deploy to GitLab</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('gitlab')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div> */}
                </div>
            </Modal>
            <ModalDeploy
                isRepoModalVisible={isRepoModalVisible}
                setRepoModalVisible={setRepoModalVisible}
                selectedPlatform={selectedPlatform}
                formSubmitted={formSubmitted}
                data={data}
                index={index}
                postValidation={postValidation}
                repositories = {repositories}
            />
        </div >
    )
}
export default DeployChevron;