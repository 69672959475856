import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '../../../components/ui/select';
import { Textarea } from 'src/components/ui/textarea';
import {
  EProjectStages,
  IProjectDependencyProps,
} from '../create-project.types';
import { useMemo, useState } from 'react';
import { useGetProjectsQuery } from 'src/redux/api-slice';
import { appTheme } from 'src/styles/theme';
import { AvatarStack } from 'src/components/ui/avatar-stack';

export const ProjectDependency = ({
  projectFormData,
  handleProjectFormDataChange,
}: IProjectDependencyProps) => {
  const [searchText, setSearchText] = useState('');

  const { isLoading, data: projects, isError } = useGetProjectsQuery();

  const filteredProjects = useMemo(() => {
    if (!searchText) {
      return projects || [];
    }
    return (
      projects?.filter(project =>
        project.projectName
          .toLowerCase()
          .includes(searchText.trim().toLowerCase()),
      ) || []
    );
  }, [searchText, projects]);

  return (
    <div>
      <div className="mb-6">
        <p className="text-sm font-semibold">Dependency Details</p>
        <br />

        <div className="-mb-4 space-y-3">
          <div>
            <div className="mb-6 space-y-2">
              <label className="block text-gray-600 text-sm">
                Select Dependent Projects
              </label>
              <input
                type="text"
                className=" w-[calc(100%-.4rem)] py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                placeholder="Search Projects"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </div>

            {isLoading ? (
              'Loading'
            ) : isError ? (
              'Unable to fetch projects!'
            ) : projects && projects.length > 0 ? (
              <div className="flex justify-start w-full overflow-auto gap-4 pb-6">
                {filteredProjects.map(project => {
                  const isSelectedProject =
                    projectFormData.dependentProjects.some(
                      p => p.projectId === project.projectId,
                    );

                  return (
                    <div
                      key={project.projectId}
                      className="flex flex-col items-center w-40 cursor-pointer p-2 rounded-md"
                      style={{
                        borderWidth: 1,
                        borderColor: isSelectedProject
                          ? appTheme.colors.darkGrey
                          : appTheme.colors.greyCardOutline,
                        background: isSelectedProject
                          ? appTheme.colors.whiteSmoothLight
                          : appTheme.colors.whiteSmooth,
                      }}
                      onClick={() => {
                        if (isSelectedProject) {
                          handleProjectFormDataChange(
                            'dependentProjects',
                            projectFormData.dependentProjects.filter(
                              p => p.projectId !== project.projectId,
                            ),
                          );
                        } else {
                          handleProjectFormDataChange('dependentProjects', [
                            ...projectFormData.dependentProjects,
                            {
                              projectId: project.projectId,
                              projectName: project.projectName,
                              reason: '',
                              stage: EProjectStages.EXTRACT,
                            },
                          ]);
                        }
                      }}
                    >
                      <AvatarStack
                        maxAvatarsAmount={1}
                        avatars={[project.projectName]}
                      />
                      <div
                        className="text-gray-700 mt-2 text-sm overflow-hidden whitespace-nowrap text-overflow-ellipsis"
                        title={project.projectName}
                      >
                        {project.projectName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {project.projectType}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              'No active projects found'
            )}
          </div>
        </div>

        <hr className="my-4" />

        <p className="text-sm">Selected Dependent Projects</p>
        <br />

        <div className="flex flex-row justify-start w-full overflow-auto gap-4 items-center -mt-2">
          {projectFormData.dependentProjects.map(dependentProject => (
            <div
              key={dependentProject.projectId}
              className="min-w-[210px] flex items-center border border-gray-300 p-2 rounded-md"
            >
              <div className="text-gray-600 p-2">
                <div className="block text-sm border-b pb-2 -mx-4 px-4">
                  {dependentProject.projectName || dependentProject.projectId}
                </div>
                <div className="block text-sm mt-2">
                  <Select
                    value={dependentProject.stage}
                    onValueChange={value => {
                      const updatedDependentProjects =
                        projectFormData.dependentProjects.map(project => {
                          if (
                            project.projectId === dependentProject.projectId
                          ) {
                            return {
                              ...project,
                              stage: value,
                            };
                          }

                          return project;
                        });
                      handleProjectFormDataChange(
                        'dependentProjects',
                        updatedDependentProjects,
                      );
                    }}
                  >
                    <SelectTrigger className="w-full px-3 py-2 border border-gray-300 rounded flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="w-full mt-2 bg-white border border-gray-300 rounded shadow-lg">
                      {Object.values(EProjectStages).map(option => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="mt-2">
                  <Textarea
                    placeholder="Enter dependency reason..."
                    rows={3}
                    className="resize-none rounded-md border border-input bg-white p-3 text-sm focus:outline-none focus:ring-1 focus:ring-ring"
                    onChange={e => {
                      const value = e.target.value;

                      const updatedDependentProjects =
                        projectFormData.dependentProjects.map(project => {
                          if (
                            project.projectId === dependentProject.projectId
                          ) {
                            return {
                              ...project,
                              dependencyReason: value,
                            };
                          }

                          return project;
                        });
                      handleProjectFormDataChange(
                        'dependentProjects',
                        updatedDependentProjects,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
