import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaGithub } from 'react-icons/fa';
import Dropdown from 'src/components/custom-dropdown/custom-dropdown';
import { useGetGlobalProjectAdminSettingsQuery, useGetGlobalProjectDeploySettingsQuery } from 'src/redux/api-slice';
import { externalConfig } from 'src/utils/misc.utils';
import Swal from 'sweetalert2';

const DeploySettings = () => {
  const { data, error, isLoading } = useGetGlobalProjectDeploySettingsQuery();

  const [settings, setSettings] = useState({
    deployToken: data?.data?.deployToken || '',
    id: data?.data?.id || 0,
  });

  useEffect(() => {
    setSettings({
      deployToken: data?.data?.deployToken || '',
      id: data?.data?.id || 0,
    })
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Unable to load global Deplpy Settings. Please try again later.',
      });
    }
  }, [error,data]);
  

  const handleUpdateOverrideSettings = async () => {
    try {
      const response = await axios.put(
        `${externalConfig.REACT_APP_GATEWAY_URL}/cf-test-service/deploy/token`,
        {
          deployToken: data?.data?.deployToken || settings.deployToken || '',
          id:data?.data?.id || 0,
        }
      );

      if (response?.data?.status === 'SUCCESS') {
        Swal.fire({
          icon: 'success',
          title: 'Deploy Settings Updated',
          text: response?.data?.message,
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Deploy Settings Error',
          text: response?.data?.message,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error updating override settings', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDeployTokenChange = (e) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      deployToken: e.target.value,
    }));
  };

  const noProjectSettings = settings && Object.keys(settings).length === 0;

  return (
    <div className="p-1 min-h-screen">
      <div className="max-w-3xl bg-white shadow-md rounded-lg p-6">
        {isLoading ? (
          <div>Loading...</div>
        ) : noProjectSettings ? (
          <div className="text-center text-lg text-gray-500">No Deploy Settings</div>
        ) : (
          <>
            <>
              <div className="mt-6">
                <label className="flex items-center gap-2 text-md font-medium mb-2"><FaGithub />GitHub Access Token</label>
                <input
                  type="password"
                  value={settings.deployToken}
                  onChange={handleDeployTokenChange}
                  className="border rounded-md p-2 w-full shadow-sm mt-2"
                />
              </div>
            </>
          </>
        )}
        {isLoading || noProjectSettings ? null : (
          <div className="mt-6 flex justify-end">
            <button
              onClick={handleUpdateOverrideSettings}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200 flex items-center gap-2"
            >
              <FaGithub /> Save Deploy Settings
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeploySettings;
