import { Button, Input, message, Modal, Select, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { convertEBCDICToASCIIAndSetContent } from "../test-chevron/utility-methods/ebcdic2ascii";
import { SharedService } from "../../../services/shared-service";
import { setActiveModal } from "../../../redux/app-global";
import { EAppModal } from "../../../redux/app-global/app-global.types";
import { FaJava } from "react-icons/fa";
import TrexIcon from "../../../assets/images/svg/t-rex";
import detectFileEncodingAndLanguage from 'detect-file-encoding-and-language';

const { Option } = Select;

interface FileReadResult {
    content: string;
    isAscii: boolean;
    encoding: string;
}

const MarketplaceModal = ({ selectedTool, isModalVisible, handleCancel }) => {
    const [javaFile, setJavaFile] = useState<File | null>(null);
    const [mfFile, setMfFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [recordLength, setRecordLength] = useState('')
    const [recordType, setRecordType] = useState('Fixed');
    const dispatch = useDispatch();


    const handleRecordTypeChange = (value) => {
        setRecordType(value);
    };

    const handleRecordLengthChange = (e) => {
        setRecordLength(e.target.value);
    };

    const handleSubmit = () => {
        if (javaFile && mfFile && recordLength !== '') {
            handleFileUpload();
        } else {
            window.alert('Please fill the necessary details!')
        }
    }
    const readFileAsText = async (file: File): Promise<FileReadResult> => {
        const detected = await detectFileEncodingAndLanguage(file);
        const encoding = detected.encoding || 'EBCDIC';
        console.log(encoding)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result as string;
                // const detected = jschardet.detect(result);
                // const encoding = detected.encoding || 'unknown';
                let isAscii = true;
                for (let i = 0; i < result.length; i++) {
                    if (result.charCodeAt(i) > 127) {
                        isAscii = false;
                        break;
                    }
                }
                resolve({ content: result, isAscii, encoding });
            };
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };

    const handleFileUpload = async () => {
        setLoading(true);
        SharedService.javaFileName = javaFile?.name || '';
        SharedService.mfFileName = mfFile?.name || '';
        try {

            const javaPromise = readFileAsText(javaFile!).then(async ({ content, encoding }) => {
                if (encoding !== 'EBCDIC') {
                    const cfContent = content || '';
                    return { cfContent, encoding };
                }
                const { cfContent } = await convertEBCDICToASCIIAndSetContent(javaFile!, "Java", recordType, parseInt(recordLength));
                return { cfContent, encoding };
            });


            const mfPromise = readFileAsText(mfFile!).then(async ({ content, encoding }) => {
                if (encoding !== 'EBCDIC') {
                    const mfContent = content || '';
                    return { mfContent, encoding };
                }
                const { mfContent } = await convertEBCDICToASCIIAndSetContent(mfFile!, "MainFrame", recordType, parseInt(recordLength));
                return { mfContent, encoding };
            });


            const [resolvedJavaContent, resolvedMfContent] = await Promise.all([javaPromise, mfPromise]);


            const { cfContent: javaFileContent, encoding: javaEncoding } = resolvedJavaContent;
            const { mfContent: mfFileContent, encoding: mfEncoding } = resolvedMfContent;

            SharedService.javaFileContent = javaFileContent;
            SharedService.mfFileContent = mfFileContent;

            SharedService.javaEncoding = javaEncoding;
            SharedService.mfEncoding = mfEncoding;

            setTimeout(() => {
                setLoading(false);
            }, 2000);

            if (javaFileContent === mfFileContent) {
                dispatch(setActiveModal(EAppModal.COMPARE_SUCCESS));
            } else {
                dispatch(setActiveModal(EAppModal.COMPARE_FAIL));
            }

        } catch (error) {
            console.error("Error reading files:", error);
            setLoading(false);
        }

        handleCancel();
    }

    const handleUpload = (info, fileType: 'java' | 'mf') => {
        if (info.file.size > 1024 * 1024) {
            message.error(`${info.file.name} is larger than 1MB! Please select a smaller file.`);
            return;
        }
        if (fileType === 'java') setJavaFile(info.file);
        if (fileType === 'mf') setMfFile(info.file);

        message.success(`${info.file.name} file uploaded successfully.`);
    };

    return (
        <Modal
            title={`${(selectedTool as any).name}`}
            open={isModalVisible}
            onCancel={handleCancel}
            centered
            onOk={handleSubmit}
            okText={'Compare'}
            width={'80%'}
        >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8 p-4">
                <div className="flex flex-col gap-8 border-r-2 p-4">
                    <img src={(selectedTool as any).image} alt={(selectedTool as any).name} className="mb-4 border p-2 shadow-sm rounded-lg" height={'20%'} width={'20%'} />
                    {/* <h2 className="text-xl font-semibold">{(selectedTool as any).name}</h2> */}
                    <p className="text-gray-700">{(selectedTool as any).expandedDescription}</p>
                </div>
                <div>
                    <label className="block font-semibold mb-2">Record Length</label>
                    <Input required placeholder="Enter Record Length" className="mb-4" type="number" value={recordLength} onChange={handleRecordLengthChange} />

                    <label className="block font-semibold mb-2">Record Type</label>
                    <Select placeholder="Select Record Type" className="w-full mb-4" onChange={handleRecordTypeChange} defaultValue={"fixed"}>
                        <Option value="fixed">Fixed</Option>
                        <Option value="variable">Variable</Option>
                    </Select>

                    <div className="py-4 flex items-center justify-between">
                        <label className="font-semibold flex gap-2 items-center"><FaJava size={28} className='text-orange-500' /> Upload Java Output File</label>
                        <div className='flex gap-2 items-center'>
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    handleUpload({ file }, 'java');
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                            {javaFile && <p className="text-sm text-green-500">Selected: {javaFile.name}</p>}
                        </div>
                    </div>
                    <div className="py-4 flex items-center justify-between">
                        <label className="font-semibold flex gap-2 items-center"><TrexIcon height={28} width={28} fill='#1363DF' /> Upload MainFrame Output File</label>
                        <div className='flex gap-2 items-center'>
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    handleUpload({ file }, 'mf');
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                            {mfFile && <p className="text-sm text-green-500">Selected: {mfFile.name}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default MarketplaceModal;