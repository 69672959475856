import axios from 'axios';
import { appTheme } from 'src/styles/theme';
import { externalConfig } from 'src/utils/misc.utils';

export const fetchSupportTickets = async () => {
  return [
    {
      id: 'CF-3456',
      title: 'Initial Code Analysis',
      authorName: 'John Doe',
      status: 'TODO',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3457',
      title: 'Bug Fixing',
      authorName: 'Jane Smith',
      status: 'IN_PROGRESS',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3458',
      title: 'Feature Enhancement',
      authorName: 'Alex Johnson',
      status: 'IN_PROGRESS',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3459',
      title: 'UI Design Issue',
      authorName: 'Emily Brown',
      status: 'TODO',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3460',
      title: 'Database Connection Error',
      authorName: 'Michael Wilson',
      status: 'DONE',
      type: 'Bug',
      link: '#',
    },
    {
      id: 'CF-3461',
      title: 'Performance Optimization',
      authorName: 'David Thompson',
      status: 'TODO',
      type: 'Feature',
      link: '#',
    },
  ];
};

export const getProjectSupportTickets = async (projectId: string) => {
  const apiUrl = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/support-ticket/${projectId}`;
  // const apiUrl = 'http://localhost:3001/support-tickets';
  const res = await axios.get(apiUrl);
  return res.data.data || [];
};

export const ticketStatusToColorMap = {
  'Dev - Blocked': {
    background: '#FFE6E6',
    color: '#000000',
  },
  'To Do': {
    background: '#E6ECFF',
    color: '#000000',
  },
  'Dev Done': {
    background: '#E6FFE6',
    color: '#000000',
  },
  'Analysis In-Progress': {
    background: '#FFF0E6',
    color: '#000000',
  },
  'QA Done': {
    background: '#E6FFFA',
    color: '#000000',
  },
  'Waiting for Support': {
    background: '#FFE6F9',
    color: '#000000',
  },
  'In Progress with product team': {
    background: '#F0E6FF',
    color: '#000000',
  },
  'Customer Validation In-progress': {
    background: '#FFFDE6',
    color: '#000000',
  },
  'Fixed- Release Pending': {
    background: '#E6F9FF',
    color: '#000000',
  },
  Closed: {
    background: '#E6E6E6',
    color: '#000000',
  },
};
