import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export async function updateProjectDetails(projectId: number, project: any) {
  const payload = {
    projectMembers:
      project.teamMembers.map(member => ({
        memberType: member.id === project.projectLead?.id ? 'LEAD' : 'MEMBER',
        userId: member.id,
      })) || [],
  };

  const response = await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/${projectId}`,
    payload,
  );
  return response.data;
}
