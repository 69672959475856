import { useDispatch } from 'react-redux';
import { setActiveModal, setCurrentIntent } from '../../../redux/app-global';
import { EAppIntent, EAppModal } from '../../../redux/app-global/app-global.types';
import SVGSuccess from '../../../assets/images/svg/success.icon';
import SVGAlert from '../../../assets/images/svg/alert.icon';

const CompareFailModal = () => {
  const dispatch = useDispatch();

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div className="relative overflow-auto max-w-xl mx-auto max-h-[44rem] mt-[2%] flex w-full flex-col border rounded-lg bg-white p-8">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            paddingTop: '1.5rem',
          }}
        >
          <div>
            <SVGAlert />
          </div>
          <div style={{ marginTop: '2rem' }}>
            <p className="text-xl font-semibold">Functional Equivalence Failed!</p>
          </div>
          <div style={{ marginTop: '.5rem' }}>
            <p style={{ fontSize: '1rem' }}>
            There are discrepancies in the files you uploaded. CloudFrame will provide further support to ensure full functional equivalence, leveraging the artifacts you submitted along with any additional insights you may offer.
            </p>
          </div>
          <div>
            <div className="flex fex-row gap-4 mt-8">
              <button
                className="rounded border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                onClick={() => {
                  dispatch(setActiveModal(EAppModal.COMPARE_VIEW))
                }}
              >
                View Files
              </button>
              <button
                style={{ borderWidth: 1, minWidth: '8rem', borderRadius: 4 }}
                onClick={() => dispatch(setActiveModal(null))}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareFailModal;