import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModal,
  setCurrentIntent,
  setScanCompleted,
  setScanJclFailed,
  setScannerErrorMessage,
} from '../../redux/app-global';
import SVGAlert from '../../assets/images/svg/alert.icon';
import { EAppIntent, EAppModal } from '../../redux/app-global/app-global.types';
import { RootState } from '../../redux/store';
import { scrollInformationCenterToEnd } from '../../utils/misc.utils';

const ScanJCLFailedModal = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );

  function handleRestart() {
    dispatch(setScanCompleted(false));
    dispatch(setScannerErrorMessage(''));
    dispatch(setActiveModal(null));
    dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
    dispatch(setScanJclFailed(false));
    dispatch(setScannerErrorMessage(''));
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div className="relative overflow-auto max-w-xl mx-auto max-h-[44rem] mt-[2%] flex w-full flex-col border rounded-lg bg-white p-8">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            paddingTop: '1.5rem',
          }}
        >
          <div>
            <SVGAlert />
          </div>
          <div style={{ marginTop: '2rem' }}>
            <p className="text-xl font-semibold">Processing Stopped</p>
          </div>
          <div style={{ marginTop: '.5rem' }}>
            <p style={{ fontSize: '1rem' }}>
              The processing of your JCL artifact has been stopped because it
              requires dependent artifacts. Please upload the necessary
              dependencies (LISTINGS) for your JCL. For further assistance,
              please contact our support team.
            </p>
          </div>
          <div>
            <div className="flex fex-row gap-4 mt-8">
              {/* <button
                className="rounded border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                onClick={() => {
                  dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
                  dispatch(setActiveModal(EAppModal.LEAD_INFORMATION));
                  setTimeout(() => {
                    scrollInformationCenterToEnd();
                  }, 100);
                }}
              >
                Contact us
              </button> */}
              <button
                className="rounded border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                style={{ borderWidth: 1, minWidth: '8rem', borderRadius: 4 }}
                onClick={handleRestart}
              >
                Start Again
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanJCLFailedModal;
