import axios from 'axios';
import { downloadBase64FromResponse, externalConfig } from '../../../utils/misc.utils';
import { SESSION_HEADER } from '../../../constants/app.constant';
import { getSearchParameter } from 'src/utils/url.utils';
import { text } from 'stream/consumers';

export const downloadLog = async () => {
  try {
    const res = await axios.get(
      externalConfig.REACT_APP_API_URL + '/download/scan-log',
    );
    downloadBase64FromResponse(res);
  } catch (err) {
    console.log(err);
  }
};

export const downloadCode = async (name: string) => {
  try {
    const projectId = getSearchParameter('projectId');
    const sessionId = sessionStorage.getItem(SESSION_HEADER) as string;
    const response = await fetch(
      `${externalConfig.REACT_APP_API_URL}/download/generated-code/${projectId}/${name}`,
      {
        method: 'GET',
        headers: {
          'X-CF-SESSIONID': sessionId,
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.arrayBuffer();
    const blob = new Blob([data]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name + '.zip';
    link.click();
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
  }
};

export const startTransformation = async (
  names: { name: string; service: boolean }[],
  type: string,
) => {
  const projectId = getSearchParameter('projectId');
  const res = await axios.post(
    `${externalConfig.REACT_APP_API_URL}/transform/start/${projectId}`,
    {
      artifactsInfo: names,
      type,
    },
  );
  return res.data;
};

export const stopTransformation = async (
  names: { name: string; service: boolean }[],
  type: string,
) => {
  const projectId = getSearchParameter('projectId');
  const res = await axios.post(
    `${externalConfig.REACT_APP_API_URL}/transform/stop/${projectId}`,
    {
      artifactsInfo: names,
      type,
    },
  );
  return res.data;
};

export const downloadErrorLog = async (name: string) => {
  try {
    const projectId = getSearchParameter('projectId');
    const sessionId = sessionStorage.getItem(SESSION_HEADER) as string;
    const response = await fetch(
      `${externalConfig.REACT_APP_API_URL}/download/scan-log/${projectId}/${name}`,
      {
        method: 'GET',
        headers: {
          'X-CF-SESSIONID': sessionId,
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      },
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.arrayBuffer();
    const textContent = new TextDecoder().decode(data);
    const blob = new Blob([textContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}_Error_Logs.txt`;
    link.click();
    URL.revokeObjectURL(url);  
    return textContent;
  } catch (err) {
    console.log(err);
    return null;
  }
};
