import React, { useState, useEffect } from 'react';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { Switch } from 'src/components/ui/switch';
import {
    Select,
    SelectTrigger,
    SelectContent,
    SelectItem,
    SelectValue,
} from 'src/components/ui/select';
import { helpTexts } from 'src/helpTexts';
import { useGetGlobalSettingsQuery, useGetProjectDetailQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { useUser } from 'src/hooks/useUser';
import { useLocation } from 'react-router-dom';
import { externalConfig } from 'src/utils/misc.utils';
import axios from 'axios';
import { getProjectLead } from 'src/pages/project-overview/card/stages-component/stages-component.service';

const TransformationSettings = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('projectId') || '';

    const { data, isLoading, error, refetch } = useGetGlobalSettingsQuery({ projectId });

    const { data : projects} = useGetProjectDetailQuery({ projectId });

    const [settings, setSettings] = useState(data?.settings || {});

    useEffect(() => {
        if (data?.settings) {
            setSettings(data.settings);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Unable to load global settings. Please try again later.',
            });
        }
    }, [error]);

    const handleUpdateOverrideSettings = async () => {
        try {
            const filteredSettings = {
                abendCode: settings.abendCode,
                packageNameSpace2: settings.packageNameSpace2,
                addCobolLines: settings.addCobolLines,
                compileJava: settings.compileJava,
                statelessPojos: settings.statelessPojos,
                generateControlAdvice: settings.generateControlAdvice
            };
            const response = await axios.put(
                `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project-settings/${projectId}`,
                {
                    settings: filteredSettings,
                }
            );
            if (response?.data?.status === 'SUCCESS') {
                Swal.fire({
                    icon: 'success',
                    title: 'Global Project Settings Updated',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
                refetch();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Project Settings Error',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
            }
        } catch (error) {
            console.error("Error updating override settings", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred. Please try again later.',
                confirmButtonText: 'OK',
            });
        }
    };


    const updateSetting = (index, updates) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            ...updates
        }));
    };

    const handleInputChange = (index, newValue) => {
        updateSetting(index, newValue);
    };

    const handleToggle = (index, fieldName) => {
        const currentValue = settings[fieldName];
        const newValue = currentValue === 'true' ? 'false' : 'true';
        updateSetting(index, { [fieldName]: newValue });
    };

    const handleSelectChange = (index, fieldName, newValue) => {
        updateSetting(index, { [fieldName]: newValue });
    };

    const FieldWrapper = ({ label, helpText, children }) => (
        <div className="flex flex-col">
            <label className="block text-md font-medium mb-2 flex items-center">
                {label}
                <HelpTextCallout calloutText={helpText} />
            </label>
            {children}
        </div>
    );

    const InputField = ({ label, value, onChange, helpText, isDisabled }) => (
        <FieldWrapper label={label} helpText={helpText}>
            <input
                type="text"
                value={value}
                onChange={onChange}
                disabled={isDisabled}
                className={`w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${isDisabled ? 'bg-gray-200' : 'bg-white'}`}
            />
        </FieldWrapper>
    );

    const SelectField = ({ label, value, onChange, helpText, isDisabled }) => {
        const options = [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' }
        ];

        return (
            <FieldWrapper label={label} helpText={helpText}>
                <Select onValueChange={onChange} value={value}  disabled={isNotCheckTransformation}
            >
                <SelectTrigger className={!isProjectLead || isNotCheckTransformation ? 'bg-gray-200 cursor-not-allowed' : ''}>

                        <SelectValue placeholder="Select an option" />
                    </SelectTrigger>
                    <SelectContent>
                        {options.map(option => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </FieldWrapper>
        );
    };
    const ToggleField = ({ label, isChecked, onChange, helpText, isDisabled }) => (
        <FieldWrapper label={label} helpText={helpText}>
            <div className="flex items-center">
                <span className={`mr-3 ${isChecked === 'true' ? "font-bold" : "font-medium"}`}>Yes</span>
                <Switch
                    checked={isChecked === 'true'}
                    onCheckedChange={onChange}
                    className="mx-2"
                    disabled={isDisabled}
                />
                <span className={`ml-3 ${isChecked === 'false' ? "font-bold" : "font-medium"}`}>No</span>
            </div>
        </FieldWrapper>
    );

    const pagHelpTexts = helpTexts[0].PROJECT_TRANSFORM_SETTINGS;

    const user = useUser();

    const isAuthorizedError = data?.status === 'ERROR';
    const noProjectSettings = settings && Object.keys(settings).length === 0;

    const isNotCheckTransformation = data?.type !== 'TRANSFORMATION';

    const projectLead = getProjectLead(projects);
    const isProjectLead =
      projectLead?.name?.toLowerCase() === user?.userId?.toLowerCase();

    return (
        <div className="p-1 bg-gray-100 flex justify-left items-left">
            <div className="bg-white shadow-2xl rounded-lg p-8 w-full max-w-4xl">
                {isLoading ? (
                    <div>Loading...</div>
                ) : isAuthorizedError ? (
                    <div className="text-center text-lg text-red-500">
                        {data?.message}
                    </div>
                ) : noProjectSettings ? (
                    <div className="text-center text-lg text-gray-500">
                        No Project Settings
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-6">
                        <InputField
                            label="Abend Code"
                            value={settings?.abendCode}
                            onChange={e => handleInputChange(0, { abendCode: e.target.value })}
                            helpText={pagHelpTexts[0].description}
                            isDisabled={!isProjectLead || isNotCheckTransformation}
                        />
                        <div className="flex space-x-4">
                            <FieldWrapper
                                label="Package Namespace"
                                helpText={pagHelpTexts[1].description}
                            >
                                <div className="flex space-x-2 w-full">
                                    <input
                                        type="text"
                                        value={settings?.packageNameSpace1}
                                        onChange={e => handleInputChange(0, { packageNameSpace1: e.target.value })}
                                        className={`w-1/2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${user?.role?.toLowerCase() === 'member' || isNotCheckTransformation ? 'bg-gray-200' : 'bg-white'}`}
                                        disabled={user?.role?.toLowerCase() === 'member' || isNotCheckTransformation || !isProjectLead}
                                    />
                                    <input
                                        type="text"
                                        value={settings?.packageNameSpace2}
                                        onChange={e => handleInputChange(0, { packageNameSpace2: e.target.value })}
                                        className={`w-1/2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${isNotCheckTransformation ? 'bg-gray-200' : 'bg-white'}`}
                                        disabled={!isProjectLead || isNotCheckTransformation}
                                    />
                                </div>
                            </FieldWrapper>
                        </div>

                        <SelectField
                            label="Add COBOL Lines"
                            value={settings?.addCobolLines}
                            onChange={value => handleSelectChange(2, 'addCobolLines', value)}
                            helpText={pagHelpTexts[4].description}
                            isDisabled={!isProjectLead || isNotCheckTransformation}
                        />
                        <SelectField
                            label="Compile Java"
                            value={settings?.compileJava}
                            onChange={value => handleSelectChange(3, 'compileJava', value)}
                            helpText={pagHelpTexts[2].description}
                            isDisabled={!isProjectLead || isNotCheckTransformation}
                        />
                        <ToggleField
                            label="Stateless POJOs"
                            isChecked={settings?.statelessPojos}
                            onChange={() => handleToggle(4, 'statelessPojos')}
                            helpText={pagHelpTexts[5].description}
                            isDisabled={!isProjectLead || isNotCheckTransformation}
                        />
                        <ToggleField
                            label="Generate Control Advice"
                            isChecked={settings?.generateControlAdvice}
                            onChange={() => handleToggle(5, 'generateControlAdvice')}
                            helpText={pagHelpTexts[3].description}
                            isDisabled={!isProjectLead || isNotCheckTransformation}
                        />
                    </div>
                )}
                {!isLoading && !noProjectSettings && !isAuthorizedError && !isNotCheckTransformation && isProjectLead && (
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={handleUpdateOverrideSettings}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
                        >
                            Save Transformation Settings
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TransformationSettings;
