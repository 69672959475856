import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
interface DropdownProps {
  options: string[],
  selectedOption?: string,
  onOptionSelect: (option: string) => void
}
const Dropdown:React.FC<DropdownProps> = ({ options, selectedOption, onOptionSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0, direction: 'down' });
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const requestRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  const getOptionTextColor = (option) => {
    switch (option) {
      case 'Active':
        return '#10b981';
      case 'Ignore':
        return '#f59e0b';
      case 'Assembler':
        return '#ef4444';
      case 'Abend':
          return '#808080';
      default:
        return '#374151';
    }
  };

  const updateDropdownPosition = () => {
    if (buttonRef.current) {
      const rect = (buttonRef as any)?.current.getBoundingClientRect();
      const availableSpaceBelow = window.innerHeight - rect.bottom;
      const availableSpaceAbove = rect.top;

      if (availableSpaceBelow < 200 && availableSpaceAbove > availableSpaceBelow) {
        setDropdownPosition({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          direction: 'up'
        });
      } else {
        setDropdownPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          direction: 'down'
        });
      }
    }
    (requestRef as any).current = requestAnimationFrame(updateDropdownPosition);
  };

  useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();
    } else {
      cancelAnimationFrame((requestRef as any)?.current);
    }

    return () => cancelAnimationFrame((requestRef as any)?.current);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !(dropdownRef as any)?.current.contains(event.target) &&
        buttonRef.current &&
        !(buttonRef as any)?.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left">
      <div ref={buttonRef}>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium font-semibold hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
          style={{
            color: getOptionTextColor(selectedOption)
          }}
          onClick={toggleDropdown}
        >
          {selectedOption}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen &&
        ReactDOM.createPortal(
          <div
            ref={dropdownRef}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            style={{
              position: 'fixed',
              width: '14rem', // Tailwind's w-56 corresponds to 14rem
              height: '12rem',
              overflow: 'auto',
              marginTop: dropdownPosition.direction === 'down' ? '0.5rem' : undefined,
              marginBottom: dropdownPosition.direction === 'up' ? '0.5rem' : undefined,
              borderRadius: '0.375rem', // Tailwind's rounded-md corresponds to 0.375rem
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Tailwind's shadow-lg
              backgroundColor: 'white',
              border: '1px solid #d1d5db', // Tailwind's ring-1 ring-gray-300
              outline: 'none',
              zIndex: 10,
              top: dropdownPosition.direction === 'down' ? `${dropdownPosition.top}px` : undefined,
              bottom: dropdownPosition.direction === 'up' ? `${window.innerHeight - dropdownPosition.top}px` : undefined,
              left: `${dropdownPosition.left}px`,
            }}
          >
            <div className="py-1">
              {options.map((option) => (
                <a
                  key={option}
                  style={{
                    display: 'block',
                    padding: '0.5rem 1rem', 
                    fontSize: '0.875rem',
                    fontWeight: '600',
                    backgroundColor: 'transparent', 
                    textDecoration: 'none',
                    color: getOptionTextColor(option),
                    cursor: 'pointer',
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f3f4f6'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                  role="menuitem"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </a>
              ))}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default Dropdown;
