import type { Router } from '@remix-run/router';
import { EAppIntent } from '../redux/app-global/app-global.types';

export class SharedService {
  static handleOption = null;
  static isOnPrem = true;
  static genericModalMessage = '';
  static reportAPICount = 0; //Used for keeping count of number of times reportAPI is triggered
  static documentationHtmlContent = '';
  static documentationProgramName = '';
  static javaFileContent = '';
  static mfFileContent = '';
  static genericAlertModal = {
    title: '',
    description: '',
  };
  static missingPrograms = new Set<string>();
  static missingUtilities = new Set<string>();
  static router: Router | null = null;
  static selectedProgram: { name: string; type: 'jcl' | 'program' } = {
    name: '',
    type: 'jcl',
  };

  static homeEvent = new CustomEvent('Home', {});
  static appEvent = new CustomEvent('App', {});
  static nextMissingPage: EAppIntent | '' = '';
  static isOpenAiActive = true;
  static javaFileName = '';
  static mfFileName = '';
  static javaEncoding = '';
  static mfEncoding = '';
  static refetchProjectsInDashBoard: (() => Promise<void>) | undefined =
    undefined;
}
