import { IUser } from '../admin/users/users.service';

export interface CreateProjectProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface ProjectDetailsProps {
  projectFormData: IProjectFormData;
  handleProjectFormDataChange: (
    key: string,
    value: string | IUser | IUser[] | null,
  ) => void;
}

export interface IDependentProject {
  projectId: number;
  projectName?: string;
  stage: string;
  reason: string;
}

export interface IProjectFormData {
  projectName: string;
  projectType: string;
  startDate: string;
  endDate: string;
  organizationName: string;
  organizationId: string;
  teamMembers: IUser[];
  projectLead: IUser | null;
  dependentProjects: IDependentProject[];
}

export interface IProjectDependencyProps {
  projectFormData: IProjectFormData;
  handleProjectFormDataChange: (
    key: string,
    value: string | IUser | IUser[] | IDependentProject[] | null,
  ) => void;
}

export enum EProjectStages {
  UPLOAD = 'UPLOAD',
  READINESS = 'READINESS',
  TRANSFORM = 'TRANSFORM',
  DEFAULT = 'DEFAULT',
  EXTRACT = 'EXTRACT',
}
