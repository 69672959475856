import React, { useState } from 'react';
import SVGChevron from '../../../assets/images/svg/chevron.icon';
import { appTheme } from '../../../styles/theme';

enum FileStatus {
    VALID = 'VALID',
    INVALID = 'NOT_VALID',
    IGNORED = 'IGNORED',
    FUTURESUPPORT = 'FUTURESUPPORT',
    TIME_OUT = 'TIME_OUT',
}

interface FileValidationStatus {
    name: string;
    status: FileStatus;
}

interface ValidationSectionProps {
    validationProgressStatus: Record<string, FileValidationStatus>;
}

const statusProps = {
    [FileStatus.VALID]: {
        bgColor: 'rgba(219,244,210,.8)',
        textColor: 'text-green-700',
        name: 'Valid Files',
        status: 'Valid',
    },
    [FileStatus.INVALID]: {
        bgColor: appTheme.colors.redSmooth,
        textColor: 'text-red-600',
        name: 'Irrelevant Files',
        status: 'Irrelevant',
    },
    [FileStatus.IGNORED]: {
        bgColor: appTheme.colors.grey20,
        textColor: 'text-gray-700',
        name: 'Ignored Files',
        status: 'Ignored',
    },
    [FileStatus.FUTURESUPPORT]: {
        bgColor: 'rgba(255,223,214,.7)',
        textColor: 'text-red-600',
        name: 'Supported in Future',
        status: 'Supported in Future',
    },
    [FileStatus.TIME_OUT]: {
        bgColor: 'rgba(255,223,214,.7)',
        textColor: 'text-red-600',
        name: 'Validation Timed Out',
        status: 'Timed Out',
    },
};

const ValidationSection: React.FC<ValidationSectionProps> = ({ validationProgressStatus }) => {
    const [openSections, setOpenSections] = useState<Record<FileStatus, boolean>>({
        [FileStatus.VALID]: false,
        [FileStatus.INVALID]: false,
        [FileStatus.IGNORED]: false,
        [FileStatus.FUTURESUPPORT]: false,
        [FileStatus.TIME_OUT]: false,
    });

    const toggleSection = (status: FileStatus) => {
        setOpenSections((prev) => ({
            ...prev,
            [status]: !prev[status],
        }));
    };

    const categorizedFiles: Record<FileStatus, FileValidationStatus[]> = {
        [FileStatus.VALID]: [],
        [FileStatus.INVALID]: [],
        [FileStatus.IGNORED]: [],
        [FileStatus.FUTURESUPPORT]: [],
        [FileStatus.TIME_OUT]: [],
    };

    Object.keys(validationProgressStatus).forEach((fileName) => {
        const file = validationProgressStatus[fileName];
        if (categorizedFiles[file.status]) {
            categorizedFiles[file.status].push(file);
        }
    });

    return (
        <div className="p-2 w-full mb-4">
            <h2 className="text-l font-semibold text-indigo-700 mb-4 text-center">Validated Files Status</h2>
            <div className='border border-gray-100 shadow-md rounded-md p-6 mt-4'>
                <p className="text-gray-600 text-sm font-medium mb-4">
                The files listed below have completed the validation process and are categorized by their status. Files that are still pending validation will appear once they are processed.
                </p>

                {/* Sections */}
                {Object.values(FileStatus).map((status) => (
                    categorizedFiles[status].length > 0 && (
                        <div key={status} className="border-b border-gray-200 pb-3 mb-4 mt-2">
                            <div
                                className="flex justify-between items-center cursor-pointer transition duration-200"
                                onClick={() => toggleSection(status)}
                            >
                                <div className="flex items-center">
                                    <h3 className="text-sm font-semibold text-gray-900">{statusProps[status].name}</h3>
                                    <div
                                        style={{ background: statusProps[status].bgColor }}
                                        className="ml-3 px-3 py-1 text-xs font-semibold rounded-full"
                                    >
                                        {categorizedFiles[status].length} {categorizedFiles[status].length === 1 ? 'file' : 'files'}
                                    </div>
                                </div>
                                <SVGChevron
                                    className={`transition-transform transform ${openSections[status] ? 'rotate-180' : ''
                                        }`}
                                />
                            </div>

                            {/* File List */}
                            {openSections[status] && (
                                <div className="mt-2 p-4 bg-gray-50 rounded-lg space-y-2">
                                    {categorizedFiles[status].map((file, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-between items-center p-3 bg-white shadow-sm rounded-md transition hover:bg-gray-100"
                                        >
                                            <div className="text-sm font-medium text-gray-700">{file.name}</div>
                                            <span
                                                className="text-xs px-3 py-1 rounded-full"
                                                style={{
                                                    color: statusProps[status].textColor,
                                                    background: statusProps[status].bgColor,
                                                }}
                                            >
                                                {statusProps[status].status}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default ValidationSection;
