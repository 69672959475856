import { ILayoutView } from './layout-view.types';
import WireframeFooter from '../../pages/footer/footer';

const LayoutView = (props: ILayoutView) => {
  const { children } = props;

  return (
    <div>
      <div className="flex flex-col" style={{ height: 'calc(100vh - 100px)' }}>
        <div className="flex-grow overflow-hidden">{children}</div>
      </div>
    </div>
  );
};

export { LayoutView };
