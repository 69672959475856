import { useEffect } from 'react';
import { FaCheckCircle, FaRocket, FaTimesCircle } from 'react-icons/fa';
import { SharedService } from 'src/services/shared-service';

const stages = [
  {
    name: 'Extract',
    title: 'Extract',
    steps: [
      {
        name: 'Extract',
        title: 'Extract',
        isCompleted: true,
        id: 0,
      },
      {
        name: 'Readiness',
        title: 'Readiness',
        isCompleted: true,
        id: 1,
      },
    ],
  },
  {
    name: 'Transform',
    title: 'Transform',
    steps: [
      {
        name: 'Transform',
        title: 'Transform',
        isCompleted: false,
        id: 0,
      },
    ],
  },
  {
    name: 'Test',
    title: 'Test',
    steps: [
      {
        name: 'Test',
        title: 'Test',
        isCompleted: false,
        id: 0,
      },
    ],
  },
];

const Stages = () => {
  useEffect(() => {
    window.dispatchEvent(SharedService.homeEvent);

    return () => {
      window.dispatchEvent(SharedService.appEvent);
    };
  }, []);
  const handleResumeStep = (stage: any) => {
    console.log(stage);
  };

  return (
    <div className="flex justify-center items-center min-h-screen space-x-4 -mt-[5%]">
      {stages.map(stage => (
        <div
          key={stage.name}
          className=" border border-gray-300 bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:shadow-xl cursor-pointer w-96 h-[25rem] flex flex-col"
          id="wl0bec5427"
          data-v0-t="card"
          onClick={() => handleResumeStep(stage)}
        >
          <div className="flex-col space-y-2 bg-indigoBase text-white p-4 flex items-center justify-between h-[7rem]">
            <div className="flex items-center gap-4">
              <FaRocket fill="#fff" className="w-8 h-8" />
              <h3 className="whitespace-nowrap tracking-tight text-xl font-bold text-white">
                {stage.title}
              </h3>
            </div>
            <div
              role="progressbar"
              data-state="indeterminate"
              data-max="100"
              className="relative h-2 overflow-hidden rounded-full bg-white/20 w-full"
            >
              <div
                data-state="indeterminate"
                data-max="100"
                className="h-full w-full flex-1 bg-white transition-all"
                style={{ transform: 'translateX(-20%)' }}
              ></div>
            </div>
          </div>
          <div className="flex-grow">
            <div className="p-4 grid gap-6 mt-4">
              {stage.steps.map(step => (
                <div key={step.id} className="flex items-center gap-4">
                  {step.isCompleted ? (
                    <FaCheckCircle className="w-5 h-5 text-green-500" />
                  ) : (
                    <FaTimesCircle className="w-5 h-5 text-red-500" />
                  )}

                  <span className="text-base font-medium">{step.title}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 border-t border-gray-300">
            <button className="w-full py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-200">
              Resume
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stages;
