import React from 'react';

interface IInvalidFileDetails {
    name: string;
    type: string;
}

interface InvalidFilesTableProps {
    invalidFileData: Record<string, IInvalidFileDetails[]>;
}

const InvalidFilesTable: React.FC<InvalidFilesTableProps> = ({ invalidFileData }) => {
    return (
        <div className="max-w-full overflow-x-auto px-1">
            <div className="mb-6">
                <h1 className="font-bold text-center py-2">IRRELEVANT FILES INFORMATION</h1>
                <p className="px-4 py-4 w-9/10">
                    The table below shows a list of irrelevant and ignored files identified within your uploaded artifacts. These files are not critical for your modernization process and can be safely disregarded.
                    If you believe there are any discrepancies, feel free to reach out to our support team for assistance.
                </p>
            </div>
            <div
                className="shadow-lg overflow-y-auto rounded-lg bg-white border border-gray-200"
                style={{ maxHeight: '33rem' }}
            >
                <table className="min-w-full">
                    <thead className="sticky top-0 z-10 bg-red-200 border-b-2 border-red-500">
                        <tr>
                            <th className="py-4 px-4 text-left text-lg font-semibold text-red-700 whitespace-nowrap">
                                File Name
                            </th>
                            <th className="py-4 px-4 text-left text-lg font-semibold text-red-700 whitespace-nowrap">
                                File Type
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {Object.entries(invalidFileData).map(([childName, files]) =>
                            files.length > 0 &&
                            files.map((file, index) => {
                                const isIrrelevant = file.type.toLowerCase().includes('invalid');
                                return (
                                    <tr
                                        key={`${childName}-${index}`}
                                        className="hover:bg-gray-100 transition duration-200 ease-in-out"
                                    >
                                        <td className="py-3 px-4 text-gray-900">
                                            {file.name}
                                        </td>
                                        <td
                                            style={{
                                                padding: '12px 16px',
                                                fontWeight: '500',
                                                color: isIrrelevant ? '#ef4444' : 'orange'
                                              }}
                                        >
                                            {isIrrelevant ? 'Irrelevant file' : 'Ignored file'}
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InvalidFilesTable;
