import { useCallback, useMemo, useState } from 'react';
import { useUser } from 'src/hooks/useUser';
import dayjs from 'dayjs';
import {
  useGetInvitesAPIQuery,
  useGetProjectDependenciesQuery,
  useGetProjectsQuery,
} from 'src/redux/api-slice';
import { SharedService } from 'src/services/shared-service';

const useDashboard = () => {
  const [selectedDateFilter, setSelectedDateFilter] = useState<
    'all' | 'month' | 'year'
  >('all');
  const {
    data: projectInvites,
    isLoading,
    refetch: refetchInvites,
  } = useGetInvitesAPIQuery();
  const projectsQuery = useGetProjectsQuery();
  const projectDependenciesQuery = useGetProjectDependenciesQuery();
  const [loading, setLoading] = useState(false);

  const user = useUser();

  const refetchProjects = useCallback(async () => {
    setLoading(true);
    await projectsQuery.refetch();
    await projectDependenciesQuery.refetch();
    setLoading(false);
  }, [projectsQuery, projectDependenciesQuery]);

  SharedService.refetchProjectsInDashBoard = refetchProjects;

  const filteredProjects = useMemo(() => {
    if (projectsQuery.isLoading) return [];

    const projects = projectsQuery.data || [];

    if (selectedDateFilter === 'all') return projects;

    const now = dayjs();
    return projects.filter(project => {
      const startDate = dayjs(project.startDate);
      if (selectedDateFilter === 'month') {
        return startDate.isSame(now, 'month');
      } else if (selectedDateFilter === 'year') {
        return startDate.isSame(now, 'year');
      }
      return true;
    });
  }, [projectsQuery, selectedDateFilter]);

  const filteredProjectDependencies = useMemo(() => {
    if (projectDependenciesQuery.isLoading) return [];

    const projectDependencies = projectDependenciesQuery.data || [];

    return projectDependencies.filter((project: any) => {
      return filteredProjects.some(
        (p: any) => p.projectId === project.projectId,
      );
    });
  }, [filteredProjects, projectDependenciesQuery]);

  const summary = useMemo(() => {
    const projectSummary = {
      totalProjects: 0,
      completedProjects: 0,
      runningProjects: 0,
      archivedProjects: 0,
      blockedProjects: filteredProjectDependencies?.length || 0,
    };

    filteredProjects.forEach(project => {
      projectSummary.totalProjects += 1;
      if (project.projectStatus === 'COMPLETED') {
        projectSummary.completedProjects += 1;
      } else if (
        project.projectStatus === 'ACTIVE' ||
        project.projectStatus === 'DRAFT'
      ) {
        projectSummary.runningProjects += 1;
      } else if (project.projectStatus === 'ARCHIVE') {
        projectSummary.archivedProjects += 1;
      }
    });

    projectSummary.runningProjects =
      (0 || projectSummary.runningProjects) - projectSummary.blockedProjects;

    return projectSummary;
  }, [filteredProjects, filteredProjectDependencies]);

  return {
    user,
    projects: filteredProjects,
    summary,
    projectInvites,
    isLoading:
      loading ||
      isLoading ||
      projectsQuery.isLoading ||
      projectDependenciesQuery.isLoading,
    projectDependencies: filteredProjectDependencies,
    selectedDateFilter,
    setSelectedDateFilter,
    refetchInvites,
    refetchProjects,
  };
};

export default useDashboard;
