import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ValidationProgressBar from "./validation-progress-bar";

const ValidationProgress = () => {
  const validationProgress = useSelector(
    (state: RootState) => state.appGlobal.validationProgress
  ) || 0;

  return (
    <div className="w-full flex flex-col items-center justify-center gap-6 p-6 bg-white border border-gray-100 shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-900 text-center mb-2">
        Validating Your File
      </h2>
      <p className="text-gray-600 text-center max-w-md">
        The file validation process is in progress. This may take a few moments depending on the file size. Thank you for your patience!
      </p>
      <div className="w-full max-w-lg">
        <ValidationProgressBar progress={validationProgress || 0} />
      </div>
      <div className="text-gray-700 font-semibold text-center text-sm mt-3">
        {validationProgress}% Complete
      </div>
    </div>
  );
};

export default ValidationProgress;
