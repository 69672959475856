import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import MobileNotSupported from './components/mobile-not-supported';
import './index.css';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { fetchUiConfig } from './services/global-service';
import { externalConfig } from './utils/misc.utils';
import { initSentry } from './utils/sentry.util';

fetchUiConfig()
  .then(data => {
    externalConfig.REACT_APP_GATEWAY_URL =
      data.REACT_APP_GATEWAY_URL || process.env.REACT_APP_GATEWAY_URL;
    externalConfig.REACT_APP_API_URL =
      data.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
    externalConfig.REACT_APP_REPORT_API_URL =
      data.REACT_APP_REPORT_API_URL || process.env.REACT_APP_REPORT_API_URL;
    externalConfig.REACT_APP_CHAT_API_URL =
      data.REACT_APP_CHAT_API_URL || process.env.REACT_APP_CHAT_API_URL;
    externalConfig.REACT_APP_LOGIN_URL =
      data.REACT_APP_LOGIN_URL || process.env.REACT_APP_LOGIN_URL;
    externalConfig.REACT_APP_WS_URL =
      data.REACT_APP_WS_URL || process.env.REACT_APP_WS_URL;
    externalConfig.REACT_APP_AI_URL =
      data.REACT_APP_AI_URL || process.env.REACT_APP_AI_URL;
    externalConfig.REACT_APP_REPORT_SERVICE =
      data.REACT_APP_REPORT_SERVICE || process.env.REACT_APP_REPORT_SERVICE;
    externalConfig.REACT_APP_TEST_SERVICE =
      data.REACT_APP_TEST_SERVICE || process.env.REACT_APP_TEST_SERVICE;

    initSentry(
      externalConfig.REACT_APP_API_URL,
      externalConfig.REACT_APP_WS_URL,
    );
  })
  .catch(error => {
    // console.error(error);
  })
  .finally(() => {
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement,
    );
    root.render(
      <React.StrictMode>
        {isMobile && !isTablet ? (
          <MobileNotSupported />
        ) : (
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        )}
      </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
