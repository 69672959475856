import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVGLoader from '../../assets/images/svg/loader.icon';
import ChatBot from '../../components/chatbot/chatbot';
import FeedbackButton from '../../components/feedback-button/feedback-button';
import {
  setActiveModal,
  setCurrentContent,
  setCurrentIntent,
  setScanCompleted,
} from '../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
  EArtifactStatus,
} from '../../redux/app-global/app-global.types';
import { RootState } from '../../redux/store';
import { intentToPageMap } from '../intent-pages/intent-to-page-map';
import { externalConfig } from '../../utils/misc.utils';
import Chevron, {
  IChevronItemProps,
} from '../../components/chatbot/chevron/chevron';
import { helpTexts } from '../../helpTexts';
import HelpTextCallout from '../../components/help-text-callout/help-text-callout';
import { SharedService } from 'src/services/shared-service';
import {
  useGetMissingArtifactsQuery,
  useGetProjectDetailQuery,
} from '../../redux/api-slice';
import { MissingPageService } from '../../services/missing-artifacts.service';
import ChatBotOnPrem from '../../components/chatbot-onprem/chatbot';
import ExtractArtifactPage from '../intent-pages/extract-artifact/extract-artifact';
import AboutUsPage from '../intent-pages/about-us/about-us';
import { getSearchParameter } from 'src/utils/url.utils';

const Home = () => {
  const projectId = getSearchParameter('projectId');

  const { data, refetch } = useGetProjectDetailQuery({ projectId });

  const artifactStatus = useSelector(
    (state: RootState) => state.appGlobal.artifactStatus,
  );

  const getCurrentStage = data => {
    if (data?.currentStage?.toLowerCase() === 'default') {
      return 'Extract';
    }
    return data?.currentStage || 'NA';
  };

  const currentStage = getCurrentStage(data);

  const currentIntent = useSelector(
    (state: RootState) => state.appGlobal.currentIntent,
  );

  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );

  const scanCompleted = useSelector(
    (state: RootState) => state.appGlobal.scanCompleted,
  );

  const license = useSelector((state: RootState) => state.appGlobal.license);

  const missingArtifactQuery = useGetMissingArtifactsQuery(undefined, {
    selectFromResult: ({ data, isLoading, isError, isUninitialized }) => ({
      data,
      isUninitialized,
      isLoading,
      isError,
    }),
  });

  const nextMissingPage = useMemo(() => {
    if (missingArtifactQuery.isLoading || missingArtifactQuery.isError) {
      return '';
    }

    const result = MissingPageService.getNextMissingPageIntentFromMissingData(
      missingArtifactQuery.data?.missingData,
    );
    return result;
  }, [missingArtifactQuery]);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (!currentIntent && SharedService.isOnPrem) {
  //     dispatch(setCurrentIntent(EAppIntent.EXTRACT_ARTIFACT));
  //   }
  // }, [currentIntent, dispatch]);

  console.log({
    scanCompleted,
    isUninitialized: !missingArtifactQuery.isUninitialized,
    isLoading: !missingArtifactQuery.isLoading,
    curStage: ['readiness', 'transform', 'smartdoc', 'test'].includes(
      currentStage.toLowerCase(),
    ),
    missingData: missingArtifactQuery.data?.missingData,
    nextMissingPage:
      !MissingPageService.getNextMissingPageIntentFromMissingData(
        missingArtifactQuery.data?.missingData,
      ),
    uploading: currentIntent !== EAppIntent.UPLOADING_ARTIFACT,
    intent:
      license?.licensedProduct.toLowerCase() !== 'smartdoc'
        ? EAppIntent.TRANSFORMATION_PAGE
        : null,
  });

  const chevronItems: IChevronItemProps[] = [
    {
      text: 'Extract',
      title: 'Extract your Artifacts',
      info: 'You will need a mainframe person at this stage, to help extracting artifacts for your project.',
      intent: EAppIntent.EXTRACT_ARTIFACT,
      isActive:
        !currentIntent ||
        currentIntent === EAppIntent.EXTRACT_ARTIFACT ||
        currentIntent === EAppIntent.JCL_ARTIFACT_INSTRUMENTATION ||
        currentIntent === EAppIntent.JCL_CC_ARTIFACT_INSTRUMENTATION ||
        currentIntent === EAppIntent.JCL_PROC_ARTIFACT_INSTRUMENTATION ||
        currentIntent === EAppIntent.LISTING_ARTIFACT_INSTRUMENTATION,
    },
    {
      title: 'Upload your Artifacts',
      text: 'Upload',
      info: 'You don’t need mainframe expertise to upload artifacts, but having a mainframe expert at this stage can help.',
      intent: EAppIntent.ARTIFACT_UPLOAD,
      isActive:
        currentIntent === EAppIntent.ARTIFACT_UPLOAD ||
        currentIntent === EAppIntent.UPLOADING_ARTIFACT,
    },
    {
      text: 'Readiness',
      title: 'Readiness for Artifact Transformation',
      info: 'You don’t need mainframe expertise at this stage. But having a mainframe expert can assist in analyzing the assessment reports.',
      intent: scanCompleted ? EAppIntent.REPORT : null,
      isActive:
        currentIntent === EAppIntent.REPORT ||
        currentIntent === EAppIntent.MISSING_SYMBOLS ||
        currentIntent === EAppIntent.RESOLVE_DYNAMIC_CALLS ||
        currentIntent === EAppIntent.MISSING_PROC ||
        currentIntent === EAppIntent.MISSING_PROGRAM ||
        currentIntent === EAppIntent.MISSING_CONTROLCARD ||
        currentIntent === EAppIntent.MISSING_METADATA ||
        currentIntent === EAppIntent.CALLCHAIN,
    },
    {
      text: 'SmartDoc',
      title: 'Generate Documentation for uploaded programs',
      info: 'You don’t need mainframe expertise while generating documentation. But having a mainframe expert at this stage can help you to understand the generated SmartDoc.',
      intent:
        SharedService.isOnPrem && scanCompleted ? EAppIntent.SMART_DOC : null,
      isActive: currentIntent === EAppIntent.SMART_DOC,
    },
    {
      title: 'License required for this feature',
      text: 'Transform',
      info: 'Anyone with Java experience can perform transformation.',
      intent:
        scanCompleted &&
        !missingArtifactQuery.isUninitialized &&
        !missingArtifactQuery.isLoading &&
        ['readiness', 'transform', 'smartdoc', 'test'].includes(
          currentStage.toLowerCase(),
        ) &&
        missingArtifactQuery.data?.missingData &&
        !MissingPageService.getNextMissingPageIntentFromMissingData(
          missingArtifactQuery.data?.missingData,
        ) &&
        currentIntent !== EAppIntent.UPLOADING_ARTIFACT &&
        (license?.licensedProduct.toLowerCase() !== 'smartdoc'
          ? EAppIntent.TRANSFORMATION_PAGE
          : null),
      isActive: currentIntent === EAppIntent.TRANSFORMATION_PAGE,
    },
    // {
    //   info: 'Any one with Java experience can Enhance',
    //   title: 'License required for this feature',
    //   text: 'Enhance',
    //   intent: null,
    // },
    {
      title: 'License required for this feature',
      text: 'Test',
      intent:
        license?.Environment === 'ONPREM' &&
        scanCompleted &&
        (['transform', 'test'].includes(currentStage.toLowerCase()) ||
          Object.values(artifactStatus).some(
            status => status === EArtifactStatus.SUCCESS,
          )) &&
        !missingArtifactQuery.isUninitialized &&
        !missingArtifactQuery.isLoading &&
        missingArtifactQuery.data?.missingData &&
        !MissingPageService.getNextMissingPageIntentFromMissingData(
          missingArtifactQuery.data?.missingData,
        ) &&
        currentIntent !== EAppIntent.UPLOADING_ARTIFACT &&
        license?.licensedProduct.toLowerCase() !== 'smartdoc'
          ? EAppIntent.TEST_CHEVRON
          : null,
      info: 'You will need both mainframe and Java expert to test and validate the transformed code.',
      isActive: currentIntent === EAppIntent.TEST_CHEVRON,
    },
    {
      title: 'License required for this feature',
      text: 'Deploy',
      intent: license?.Environment === 'ONPREM' &&
      scanCompleted &&
      (['transform', 'test'].includes(currentStage.toLowerCase()) ||
          Object.values(artifactStatus).some(
            status => status === EArtifactStatus.SUCCESS,
      )) &&
      !missingArtifactQuery.isUninitialized &&
      !missingArtifactQuery.isLoading &&
      missingArtifactQuery.data?.missingData &&
      !MissingPageService.getNextMissingPageIntentFromMissingData(
        missingArtifactQuery.data?.missingData,
      ) &&
      currentIntent !== EAppIntent.ARTIFACT_UPLOAD &&
      currentIntent !== EAppIntent.UPLOADING_ARTIFACT
        ? EAppIntent.DEPLOY_PAGE
        : null,
      info: 'Any one with Java experience can Deploy',
      isActive: currentIntent === EAppIntent.DEPLOY_PAGE,
    },
  ];

  const pageHelpText = helpTexts[0].INFORMATION_CENTER;

  useEffect(() => {
    window.dispatchEvent(SharedService.homeEvent);

    return () => {
      window.dispatchEvent(SharedService.appEvent);
    };
  }, []);

  useEffect(() => {
    if (currentIntent === EAppIntent.REPORT) {
      refetch();
    }

    let timer: any = null;
    if (currentIntent === EAppIntent.HOME) {
      dispatch(setCurrentIntent(EAppIntent.ABOUT_US));
      return;
    }
    setIsLoading(true);

    const selectedIntent = () => {
      if (currentStage.toLowerCase() === 'extract') {
        return EAppIntent.EXTRACT_ARTIFACT;
      } else if (currentStage.toLowerCase() === 'readiness') {
        dispatch(setScanCompleted(true));
        return (
          EAppIntent.REPORT ||
          EAppIntent.MISSING_SYMBOLS ||
          EAppIntent.RESOLVE_DYNAMIC_CALLS ||
          EAppIntent.MISSING_PROC ||
          EAppIntent.MISSING_PROGRAM ||
          EAppIntent.MISSING_CONTROLCARD ||
          EAppIntent.MISSING_METADATA ||
          EAppIntent.CALLCHAIN
        );
      } else if (currentStage.toLowerCase() === 'upload') {
        return EAppIntent.ARTIFACT_UPLOAD;
      } else if (currentStage.toLowerCase() === 'transform') {
        return EAppIntent.TRANSFORMATION_PAGE;
      } else if (currentStage.toLowerCase() === 'test') {
        return EAppIntent.TEST_CHEVRON;
      } else if (currentStage.toLowerCase() === 'deploy') {
        return EAppIntent.DEPLOY_PAGE;
      }
      return;
    };
    if (
      !currentContent ||
      !currentContent?.intent ||
      currentContent?.intent != currentIntent
    ) {
      console.log(currentIntent);
      axios
        .post(`${externalConfig.REACT_APP_CHAT_API_URL}/intent/content`, {
          intent: currentIntent || selectedIntent(),
        })
        .then((resp: any) => {
          if (resp.data?.data) {
            dispatch(setCurrentContent(resp.data.data));
          } else {
            dispatch(setCurrentContent({}));
          }
          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
        });
    } else {
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentIntent]);

  const hideChevron = !currentIntent && !SharedService.isOnPrem;
  currentIntent === EAppIntent.ABOUT_US ||
    currentIntent === EAppIntent.CF_SERVICES ||
    currentIntent === EAppIntent.COBOL_TO_JAVA ||
    currentIntent === EAppIntent.CODE_ACCURACY ||
    currentIntent === EAppIntent.HOME ||
    currentIntent === EAppIntent.CALLCHAIN ||
    currentIntent === EAppIntent.SAMPLE_REPORT;

  return (
    <div className="flex flex-row bg-whiteSmoothLight h-full pb-4 w-full relative">
      <div
        style={{ minWidth: '18rem', width: '25%' }}
        className="mx-4 my-4 mb-0 pb-0 shadow-md rounded-md mr-0"
      >
        {SharedService.isOnPrem ? <ChatBotOnPrem /> : <ChatBot />}
      </div>
      <div
        style={{ width: '73%', marginLeft: '1%' }}
        className="h-full pb-4 flex-grow"
      >
        <div className="my-4 shadow-md h-full mb-8 px-4 py-4 bg-white rounded-md">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row item-center">
              <h1
                className="text-md text-darkGrey font-semibold"
                style={{ marginTop: '1px' }}
              >
                Information Center
              </h1>
              <HelpTextCallout calloutText={pageHelpText[0].description} />
            </div>
            <div className="flex flex-row items-center gap-2">
              {/* {currentIntent === EAppIntent.EXTRACT_ARTIFACT && (
                <p
                  onClick={() => {
                    dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
                  }}
                  className="text-sm px-4 py-[2px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105"
                >
                  Back to Upload Artifact
                </p>
              )} */}
              {currentIntent &&
                [
                  EAppIntent.JCL_ARTIFACT_INSTRUMENTATION,
                  EAppIntent.JCL_CC_ARTIFACT_INSTRUMENTATION,
                  EAppIntent.JCL_PROC_ARTIFACT_INSTRUMENTATION,
                  EAppIntent.LISTING_ARTIFACT_INSTRUMENTATION,
                ].includes(currentIntent) && (
                  <p
                    onClick={() => {
                      dispatch(setCurrentIntent(EAppIntent.EXTRACT_ARTIFACT));
                    }}
                    className="text-sm px-4 py-[2px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105"
                  >
                    Go back
                  </p>
                )}
              {scanCompleted &&
                ![
                  EAppIntent.REPORT,
                  EAppIntent.TRANSFORMATION_PAGE,
                  EAppIntent.TRANSFORM_SETTINGS,
                  EAppIntent.TRANSFORMATION_REPORT,
                  EAppIntent.SMART_DOC,
                  EAppIntent.DEPLOY_PAGE,
                ].includes(currentIntent as any) && (
                  <>
                    <HelpTextCallout
                      calloutText={
                        currentIntent === EAppIntent.CALLCHAIN
                          ? 'Clicking on this button will exit the canvas view and returns to the assessment report page.'
                          : 'You are viewing the report. Click "View Report" to view details'
                      }
                    />
                    <p
                      onClick={() => {
                        dispatch(setCurrentIntent(EAppIntent.REPORT));
                      }}
                      className="text-sm px-4 py-[2px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105"
                    >
                      {currentIntent === EAppIntent.CALLCHAIN
                        ? 'Go Back to Report'
                        : 'View Report'}
                    </p>
                  </>
                )}
              {scanCompleted &&
                nextMissingPage &&
                [EAppIntent.REPORT].includes(currentIntent as any) && (
                  <div className="flex items-center gap-2">
                    <HelpTextCallout
                      calloutText={pageHelpText[1].description}
                    />
                    <p
                      onClick={() => {
                        dispatch(setCurrentIntent(nextMissingPage));
                      }}
                      className="text-sm px-4 py-[2px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105"
                    >
                      Missing Artifact Workspace
                    </p>
                  </div>
                )}
            </div>
          </div>

          <hr className="w-full h-[1px] mt-2 bg-greyCardOutline" />
          <div
            id="information-center"
            className="w-full h-[calc(100%-34px)] mt-2 overflow-auto"
          >
            {isLoading ? (
              <div className=" h-full flex flex-col justify-center items-center my-auto pb-[7%]">
                <div className="flex flex-col items-center justify-center">
                  <p className="pb-2">Please wait...</p>
                  <SVGLoader width={60} height={60} />
                </div>
              </div>
            ) : (
              <div className="h-full">
                {[
                  'extract',
                  'upload',
                  'readiness',
                  'smartdoc',
                  'transform',
                  'deploy',
                  'test',
                  'enhance',
                ].includes(currentStage.toLowerCase()) && (
                  <Chevron items={chevronItems} />
                )}
                {intentToPageMap(currentIntent, currentStage)}
              </div>
            )}
            <div />
          </div>
        </div>
      </div>
      {!SharedService.isOnPrem && (
        <div className="absolute bottom-8 right-10">
          <FeedbackButton
            onClick={() => {
              dispatch(setActiveModal(EAppModal.FEEDBACK));
            }}
          />
        </div>
      )}
    </div>
  );
};

export { Home };
