import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Badge } from 'src/components/ui/badge';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useGetUsersQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import { userStatusToColorMap } from './users.service';
import { ICreateNewUserFormData } from './create-new-user/create-new-user.types';
import CreateNewUserModal from './create-new-user';
import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

const UsersPage = () => {
  const [alertsShown, setAlertsShown] = useState({ users: false });
  const [showCreateNewUser, setShowCreateNewUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { isLoading, error, data: users , refetch} = useGetUsersQuery();

  if (error && !alertsShown.users) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load users. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, users: true });
  }

  const handleDeleteUser = async(id: number) => {
    const result = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'Are you sure you want to deactivate this user?',
      showCancelButton: true,
      confirmButtonText: 'Yes, deactivate it!',
      cancelButtonText: 'No, cancel!',
    });
    if (result.isConfirmed) {
      await axios.delete(`${externalConfig.REACT_APP_LOGIN_URL}/user/${id}`);
      await refetch();
      Swal.fire('Deactivated!', 'User has been deactivated.', 'success');
    }
  };

  const handleCreateUser = (userDetails: ICreateNewUserFormData) => {
    setShowCreateNewUser(false);
    refetch();
  };

  const handleEditUser = async(userId : number) => {
    try{
      const {data: userData } = await axios.get(`${externalConfig.REACT_APP_LOGIN_URL}/user/${userId}`);
      setSelectedUser(userData);
      setShowCreateNewUser(true);
      refetch();
    }catch(err){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to fetch user details',
      })
      }
    };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setShowCreateNewUser(false);
  }

  return (
    <div className="flex flex-row bg-[#F3F4F4] min-h-[100vh]">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'Users'} />
      </div>
      <div className="mt-8 ml-8 w-full">
        <div className="w-full">
          <h1 className="text-2xl font-bold">User Management</h1>
          <p className="mt-2">Easily view, manage, and edit user accounts.</p>

          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="mt-[0rem] flex flex-col">
              <div className="ml-auto mr-8">
                <button
                  onClick={() => {
                    setShowCreateNewUser(true);
                  }}
                  className="mb-4 bg-green-600 text-white hover:bg-green-700 px-4 py-2 rounded-md shadow-md transition duration-150 transform hover:scale-105"
                >
                  Create New User
                </button>
              </div>
              <div
                className="p-8  rounded-md bg-white mr-6"
                style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
              >
                <div>
                  <Table>
                    <TableCaption>A list of users.</TableCaption>
                    <TableHeader>
                      <TableRow>
                        <TableHead>ID</TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead>Role</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead className="text-left">Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {users?.length === 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            className="text-center font-medium"
                          >
                            No users found
                          </TableCell>
                        </TableRow>
                      )}
                      {users?.map(user => (
                        <TableRow key={user.id}>
                          <TableCell className="font-medium">
                            {user.id}
                          </TableCell>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>
                            {user.role.charAt(0).toUpperCase() +
                              user.role.slice(1)}
                          </TableCell>
                          <TableCell>
                            <Badge
                              style={{
                                background: user.active
                                  ? userStatusToColorMap["ACTIVE"]?.background
                                  : userStatusToColorMap["INACTIVE"]?.background,
                                color: user.active
                                  ? userStatusToColorMap["ACTIVE"]?.color
                                  : userStatusToColorMap["INACTIVE"]?.color,
                              }}
                              variant="default"
                            >
                              {user.active ? "ACTIVE" : "INACTIVE"}
                            </Badge>
                          </TableCell>
                          <TableCell className="text-left">
                            {/*<div className="flex justify-center space-x-12">*/}
                              <button 
                              onClick={() => handleEditUser(user.id)}
                              disabled={!user.active}
                              className={`flex items-center ml-0 px-4 py-2 rounded-md shadow-md transition duration-150 transform hover:scale-105 ${
                                user.active ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                              }`}
                              >
                                <FiEdit size={20} className="mr-1" />{' '}
                                <span className="text-xs font-medium">
                                  Edit
                                </span>
                              </button>
                              {/*
                              <button
                                onClick={() => handleDeleteUser(user.id)}
                                disabled={!user.active}
                                className={`flex items-center px-4 py-2 rounded-md shadow-md transition duration-150 transform hover:scale-105 ${
                                  user.active ? 'bg-red-600 text-white hover:bg-red-700' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                              >
                                <FiTrash2 size={20} className="mr-1" />
                                <span className="text-xs font-medium">
                                Deactivate
                                </span>
                              </button>
                              
                            </div>*/}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showCreateNewUser && (
        <CreateNewUserModal
          onSubmit={handleCreateUser}
          onClose={handleCloseModal}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default UsersPage;
