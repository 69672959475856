import React, { useEffect, useState } from 'react';
import { ISubTable } from '../table';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import useSubTable from './useSubTable';
import axios from 'axios';
import { RootState } from '../../../../redux/store';
import {
  EAppModal,
  ESmartDocStatus,
} from '../../../../redux/app-global/app-global.types';
import { setActiveModal } from '../../../../redux/app-global';
import { SharedService } from '../../../../services/shared-service';
import Swal from 'sweetalert2';
import HelpTextCallout from '../../../../components/help-text-callout/help-text-callout';
import { Spin, Tooltip } from 'antd';
import SmartDocSpinner from '../../../../assets/images/svg/smartDocLoader.icon';
import { MdBlock } from 'react-icons/md';
import { externalConfig } from '../../../../utils/misc.utils';
import { getSearchParameter } from 'src/utils/url.utils';
import { IoIosWarning } from 'react-icons/io';

const SubTable = (table: ISubTable) => {
  const dispatch = useDispatch();
  const smartDocStatus = useSelector(
    (state: RootState) => state.appGlobal.smartDocStatus || {},
  );
  const { showCallChainModal } = useSubTable();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterOption, setFilterOption] = useState<string>('All');

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [generating, setGenerating] = useState<Record<string, boolean>>({});
  const projectId = getSearchParameter('projectId');

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onClickDocumentation = async (name: string) => {
    setGenerating(prev => ({ ...prev, [name]: true }));
    await handleViewDocumentation(name);
  };
  const handleViewDocumentation = async (key: string) => {
    axios
      .get(
        `${externalConfig.REACT_APP_API_URL}/scan/smart-doc/${projectId}/${key}`,
      )
      .then(res => {
        SharedService.documentationHtmlContent = res.data;
        SharedService.documentationProgramName = key;
        dispatch(setActiveModal(EAppModal.DOCUMENTATION_MODAL));
        setGenerating(prev => ({ ...prev, [key]: false }));
      })
      .catch(err => {
        console.error('Unable to fetch documentation: ', err);
        setGenerating(prev => ({ ...prev, [key]: false }));
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch the documentation. Please contact Support Team',
          icon: 'error',
          confirmButtonText: 'Close',
        });
      });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const debounce = (func: any, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const filterRows = (name: string) => {
    if (
      searchQuery &&
      !name.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return false;
    }
    if (
      filterOption === 'Documentation in progress' &&
      smartDocStatus[name] !== ESmartDocStatus.INPROGRESS
    ) {
      return false;
    }
    if (
      filterOption === 'Documentation finished' &&
      smartDocStatus[name] !== ESmartDocStatus.SUCCESS
    ) {
      return false;
    }
    if (
      filterOption === 'Documentation failed' &&
      smartDocStatus[name] !== ESmartDocStatus.FAIL
    ) {
      return false;
    }
    return true;
  };

  return (
    <div key={table.title}>
      <div
        className="pt-4 ml-0 flex items-center justify-between bg-gray-100 p-4 rounded-md shadow-md"
        onClick={toggleCollapse}
      >
        <h2 className="text-2xl font-semibold text-gray-800">{table.title}</h2>
        <button className="ml-2 text-gray-600 hover:text-gray-800 transition-colors duration-200">
          {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      {!isCollapsed && (
        <>
          <div className="flex items-center justify-between mb-4 mt-4 ml-6">
            <div className="flex flex-row gap-2">
              <input
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="flex-1 rounded-md border border-input px-3 py-2 text-sm max-w-[20rem]"
              />
              {/* <select
                value={filterOption}
                onChange={handleFilterChange}
                className="flex-none rounded-md border border-input px-3 py-2 text-sm"
                style={{ minWidth: '200px' }}
              >
                <option value="All">All</option>
                <option value="Transformation in progress">
                  Documentation in progress
                </option>
                <option value="Transformation finished">
                  Documentation finished
                </option>
                <option value="Transformation failed">
                  Documentation failed
                </option>
              </select> */}
            </div>
          </div>
          <hr />
          <div className="p-6">
            <div className="relative w-full overflow-auto">
              <table className="w-full caption-bottom text-sm">
                <thead className="[&amp;_tr]:border-b ">
                  <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[250px]">
                      <div className="flex items-center gap-2 text-sm">
                        Name{' '}
                        <HelpTextCallout
                          calloutText={table.helpTexts[1].description}
                        />
                      </div>
                    </th>
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[350px]">
                      <div className="flex items-center gap-2 text-sm">
                        Actions{' '}
                        <HelpTextCallout
                          calloutText={table.helpTexts[2].description}
                        />
                      </div>
                    </th>
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[100px]">
                      <div className="flex items-center gap-2 text-sm">
                        SmartDoc{' '}
                        <HelpTextCallout
                          calloutText={table.helpTexts[3].description}
                        />
                      </div>
                    </th>
                    <th
                      className={`h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[100px]`}
                    >
                      <div className="flex items-center gap-2 text-sm">
                        <span
                          className={`${Object.keys(smartDocStatus).length === 0 ? 'opacity-50' : ''}`}
                        >
                          AI Enhanced SmartDoc Status
                        </span>
                        {Object.keys(smartDocStatus).length === 0 ? (
                          <HelpTextCallout
                            calloutText={table.helpTexts[4].description}
                          />
                        ) : (
                          <HelpTextCallout
                            calloutText={table.helpTexts[5].description}
                          />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="[&amp;_tr:last-child]:border-0">
                  {table.rows
                    .filter(row => filterRows(row.artifactName))
                    .map(row => (
                      <React.Fragment key={row.artifactName}>
                        <tr
                          className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted`}
                        >
                          <td className="p-4 align-middle font-medium">
                            {row.artifactName}
                          </td>
                          <td className="p-4 align-middle w-[250px]">
                            <button
                              onClick={() =>
                                showCallChainModal(
                                  row.artifactName,
                                  table.title,
                                )
                              }
                              className="px-3 py-1 bg-blue-500 text-white rounded-md text-sm"
                            >
                              View Callchain
                            </button>
                          </td>
                          <td className="p-4 w-[200px]">
                            {row.startDocStatus === 'enable' ? (
                              <button
                                className={`flex items-center px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors duration-200`}
                                onClick={() =>
                                  onClickDocumentation(row.artifactName)
                                }
                              >
                                {generating[row.artifactName]
                                  ? 'Fetching Report...'
                                  : 'Show Report'}
                              </button>
                            ) : (
                              <div className="flex">
                                <Tooltip
                                  title="Smart documentation for this artifact is not enabled as the license limit is exceeded. Please contact CloudFrame Support Team for assitance."
                                  overlayInnerStyle={{
                                    backgroundColor: '#000',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    padding: '8px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <IoIosWarning className="text-red-600 ml-8" />
                                </Tooltip>
                              </div>
                            )}
                          </td>
                          <td className="p-4 align-middle w-[200px]">
                            {smartDocStatus[row.artifactName] ===
                            ESmartDocStatus.INPROGRESS ? (
                              <div className="flex justify-center">
                                <Tooltip
                                  title="AI Enhanced Documentation is in progress. Please wait for a while"
                                  overlayInnerStyle={{
                                    backgroundColor: '#000',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    padding: '8px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <SmartDocSpinner height={40} width={40} />
                                </Tooltip>
                              </div>
                            ) : smartDocStatus[row.artifactName] ===
                              ESmartDocStatus.FAIL ? (
                              <div className="flex justify-center">
                                <Tooltip
                                  title="AI Enhanced Documentation failed. Please contact CloudFrame Support"
                                  overlayInnerStyle={{
                                    backgroundColor: '#000',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    padding: '8px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <FaTimes className="text-red-500 inline-block" />
                                </Tooltip>
                              </div>
                            ) : smartDocStatus[row.artifactName] ===
                              ESmartDocStatus.SUCCESS ? (
                              <div className="flex justify-center text-green-500">
                                <Tooltip
                                  title="AI Enhanced Documentation is completed successfully. You can view it using the show report button"
                                  overlayInnerStyle={{
                                    backgroundColor: '#000',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    padding: '8px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <FaCheck />
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="flex justify-center">
                                <Tooltip
                                  title={
                                    'AI Enhanced Documentation cannot be generated because there are no required LLM Parameters provided by the admin.'
                                  }
                                  overlayInnerStyle={{
                                    backgroundColor: '#000',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    padding: '8px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <MdBlock className="text-red-500" />
                                </Tooltip>
                              </div>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubTable;
