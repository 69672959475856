import { AvatarStack } from 'src/components/ui/avatar-stack';
import { ProjectWidgetProps } from './project-widget.types';
import { FaTimes } from 'react-icons/fa';
import { Badge } from 'src/components/ui/badge';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import StagesComponent from '../project-overview/card/stages-component/stages-component';

const ProjectWidget = ({ isOpen, onClose, project }: ProjectWidgetProps) => {
  if (!isOpen) return null;

  const navigate = useNavigate();

  const overviewProject = () => {
    navigate(ROUTES.PROJECT_OVERVIEW + `?projectId=${project?.projectId}`);
  };

  return (
    <div className={`fixed inset-0 bg-gray-900 bg-opacity-5 flex items-center justify-center z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
      <div className="bg-white p-6 rounded-2xl shadow-2xl max-w-3xl w-full h-[90%] flex flex-col relative">
        <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10 py-2 border-b">
          <h2 className="text-lg font-bold">{project?.projectName}</h2>
          <button
            onClick={onClose}
            className="flex items-center justify-center w-9 h-9 bg-gray-100 text-gray-600 hover:bg-red-500 hover:text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none shadow-lg"
            aria-label="Close modal"
          >
            <FaTimes size={15} />
          </button>
        </div>

        <div className="overflow-y-auto flex-1 max-h-[70vh] space-y-6">
          <div className="grid grid-cols-2 gap-4 bg-gray-100 p-4 rounded-lg shadow-sm">
            <p className="text-base">
              <strong>Project Type:</strong> <span className="ml-2">{project?.projectType}</span>
            </p>
            <p className="text-base">
              <strong>Current Stage:</strong> <span className="ml-2">{project?.currentStage === 'DEFAULT' ? 'EXTRACT' : project?.currentStage}</span>
            </p>
            <p className="text-base flex items-center">
              <strong>Status:</strong> 
              <span className="ml-2">
                <Badge color="green" className="px-2 py-1">{project?.projectStatus}</Badge>
              </span>
            </p>
          </div>

          <p><strong>Project Members</strong></p>
          <div className="flex flex-wrap gap-6 my-4">
            {project?.projectMemberList && project.projectMemberList.length > 0 ? (
              project.projectMemberList.map((member, index) => (
                <div key={index} className="flex flex-col items-center text-center pl-1">
                  <AvatarStack maxAvatarsAmount={1} avatars={[member.name]} className="ring-2 ring-indigo-300 rounded-full" />
                  <p className="text-sm mt-2 font-semibold">{member.name}</p>
                  <p className="text-xs text-gray-600">{member.workProfile}</p>
                  <p className="text-xs text-gray-500 italic">
                    {member.inviteStatus === 'SENT' ? 'AWAITING APPROVAL' : member.inviteStatus}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray-500 text-center w-full">No project members</p>
            )}
          </div>

          <div className="bg-gray-100 shadow-md p-2 w-[97%] overflow-x-auto">
            <div className="flex space-x-4">
              <StagesComponent
                projectData={project}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4 sticky bottom-0 bg-white py-4 border-t">
          <button
            className="bg-blue-600 text-white text-sm px-3 py-2 rounded-md"
            onClick={overviewProject}
          >
            View Project
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectWidget;
