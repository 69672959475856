import axios from 'axios';
import localforage from 'localforage';
import { useEffect, useRef } from 'react';
import 'react-chatbot-kit/build/main.css'; // this has to be placed before the App.css
import { useDispatch, useSelector } from 'react-redux';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import AppModals from './components/app-modals/app-modals';
import WireframeHeader from './pages/header/header';
import { LayoutView } from './components/layout-view/layout-view';
import { SESSION_HEADER, publicEmailDomains } from './constants/app.constant';
import setAuthHeaders from './interceptors/auth.interceptor';
import {
  setActiveModal,
  setArtifactStatus,
  setArtifactStatusObject,
  setControlCardData,
  setCurrentIntent,
  setDynamicCallData,
  setLicenseStatusDetails,
  setNextMissingPageTest,
  setProcData,
  setProgramData,
  setScanCompleted,
  setScanProgress,
  setScannerErrorMessage,
  setSmartDocStatus,
  setTransformationProgress,
  setTransformationStatus,
  setUploadedArtifacts,
  setUploadedMissingArtifacts,
  setValidationFiles,
  setValidationProgress,
  updateTransformationProgress,
} from './redux/app-global';
import {
  EAppIntent,
  EAppModal,
  EArtifactStatus,
  ESmartDocStatus,
} from './redux/app-global/app-global.types';
import { RootState, store } from './redux/store';
import { loadLicenseDetails } from './services/global-service';
import {
  externalConfig,
  scrollInformationCenterToTop,
} from './utils/misc.utils';
import 'react-tooltip/dist/react-tooltip.css';
import AppNavigation, { ROUTES } from './App.navigation';
import { SharedService } from './services/shared-service';
import NotesFloatingButton from './components/notes-floating-button/notes-floating-button';
import AddNotesWidget from './components/add-notes-widget/add-notes-widget';
import { Toaster } from './components/ui/toaster';
import { MissingPageService } from './services/missing-artifacts.service';
import { useLazyGetMissingArtifactsQuery } from './redux/api-slice';
import { getSearchParameter } from './utils/url.utils';
import WireframeFooter from './pages/footer/footer';

localforage.config({
  name: 'cfCodeNavigator',
  storeName: 'cfCodeNavigatorStore',
  description: 'Local storage for storing code navigator data',
  version: 1.0,
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
});

setAuthHeaders();

function App() {
  const dispatch = useDispatch();
  const currentIntent = useSelector(
    (state: RootState) => state.appGlobal.currentIntent,
  );
  const currentIntentRef = useRef(currentIntent);
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const userLoginDetails = useSelector(
    (state: RootState) => state.appGlobal.userLoginDetails,
  );
  const [triggerGetMissingArtifacts] = useLazyGetMissingArtifactsQuery();

  SharedService.isOnPrem = license?.Environment?.includes('ONPREM') || false;
  const currentRoute = SharedService.router?.state.location.pathname;

  useEffect(() => {
    currentIntentRef.current = currentIntent;
    scrollInformationCenterToTop();
  }, [currentIntent]);

  useEffect(() => {
    const sessionId = sessionStorage.getItem(SESSION_HEADER);
    if (!sessionId) {
      const uuid = uuidv4();
      sessionStorage.setItem(SESSION_HEADER, uuid.toString());
    }
    setAuthHeaders();
  }, []);

  useEffect(() => {
    async function loadPublicEmailDomains() {
      if (publicEmailDomains.domains.size === 0) {
        try {
          const basePath = process.env.REACT_APP_BASE_PATH;
          const response = await axios.get(
            `${basePath}docs/public-email-domains.txt`,
          );
          const lines: string[] = response.data.split('\n');
          publicEmailDomains.domains = new Set(lines);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
          } else {
            console.error('Error fetching public email domains:', error);
          }
        }
      }
    }
    loadLicenseDetails(dispatch);
    loadPublicEmailDomains();
    if (
      process.env.REACT_APP_CF_ENV === 'onprem' &&
      (!license || !license.Environment) &&
      sessionStorage.getItem('onprem-redirect') !== 'true'
    ) {
      sessionStorage.setItem('onprem-redirect', 'true');
      window.location.reload();
    }
  }, []);

  const handleSmartDocStatus = (status: any) => {
    const newStatus: Record<string, ESmartDocStatus> = {};
    if (status.status === 'DOC_GEN_FAILED')
      newStatus[status.programName] = ESmartDocStatus.FAIL;
    else if (status.status === 'DOC_GEN_COMPLETED')
      newStatus[status.programName] = ESmartDocStatus.SUCCESS;
    else newStatus[status.programName] = ESmartDocStatus.INPROGRESS;
    dispatch(setSmartDocStatus(newStatus));
  };
  const handleLicenseStatusDetails = (data: any) => {
    const licenseStatusDetails = {
      licenseStatus: data.licenseStatus,
      message: data.message,
    };

    dispatch(setLicenseStatusDetails(licenseStatusDetails));
  };
  const handleTransformationStatus = (status: any) => {
    const newStatus: Record<string, number> = {};
    dispatch(setTransformationStatus(status));
    const newArtifactStatus: Record<string, EArtifactStatus> = {};

    Object.keys(status).forEach(key => {
      let statusOfKey = 0;

      if (
        status[key]?.PACKAGING === 'PASS' &&
        status[key]?.CODE_TRANSFORMATION === 'PASS' &&
        status[key]?.COMPILATION === 'PASS' &&
        status[key]?.CODE_GENERATION === 'PASS'
      ) {
        newArtifactStatus[key] = EArtifactStatus.SUCCESS;
      }

      if (
        status[key]?.PACKAGING === 'FAILED' ||
        status[key]?.CODE_TRANSFORMATION === 'FAILED' ||
        status[key]?.COMPILATION === 'FAILED' ||
        status[key]?.CODE_GENERATION === 'FAILED'
      ) {
        newArtifactStatus[key] = EArtifactStatus.ERROR;
      }

      if (['PASS', 'FAILED'].includes(status[key]?.PACKAGING)) {
        statusOfKey = 100;
      } else if (
        ['PASS', 'FAILED'].includes(status[key]?.CODE_TRANSFORMATION)
      ) {
        statusOfKey = 75;
      } else if (['PASS', 'FAILED'].includes(status[key]?.COMPILATION)) {
        statusOfKey = 50;
      } else if (['PASS', 'FAILED'].includes(status[key]?.CODE_GENERATION)) {
        statusOfKey = 25;
      } else {
        statusOfKey = 0;
      }

      newStatus[key] = statusOfKey;
    });

    dispatch(setArtifactStatusObject(newArtifactStatus));
    dispatch(updateTransformationProgress(newStatus));
  };

  const handleScanCompleteEvent = async () => {
    dispatch(setScanCompleted(true));
    SharedService.reportAPICount = 0;
    dispatch(setUploadedMissingArtifacts(undefined));
    dispatch(setNextMissingPageTest([]));
    dispatch(setProcData([]));
    dispatch(setProgramData([]));
    dispatch(setControlCardData([]));
    dispatch(setDynamicCallData([]));
    if (SharedService.isOnPrem) {
      try {
        const response = await triggerGetMissingArtifacts();
        dispatch(setUploadedMissingArtifacts(undefined));

        if (response.data?.missingData) {
          const filteredPages = Object.entries(
            response.data?.missingData,
          ).filter(([_, value]) => (value as any).length > 0);

          if (filteredPages.length > 0) {
            SharedService.nextMissingPage =
              MissingPageService.getNextMissingPageIntentFromMissingData(
                response.data?.missingData,
              );
            dispatch(setActiveModal(EAppModal.MISSING_ARTIFACTS));
          } else {
            dispatch(setCurrentIntent(EAppIntent.REPORT));
            dispatch(setActiveModal(null));
          }
        } else {
          dispatch(setNextMissingPageTest([]));
          dispatch(setCurrentIntent(EAppIntent.REPORT));
          dispatch(setActiveModal(null));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const state = store.getState();
      if (
        state.appGlobal.currentIntent === EAppIntent.UPLOADING_ARTIFACT ||
        state.appGlobal.currentIntent === EAppIntent.REPORT
      ) {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
        dispatch(setActiveModal(null));
      } else {
        dispatch(setActiveModal(EAppModal.SCANNING_COMPLETED));
      }
    }
  };

  useEffect(() => {
    let client: Stomp.Client | null = null;
    let shouldAttemptReconnect = true;
    let reconnectTimeoutId: NodeJS.Timeout | null = null; // Variable to store timeout ID

    const connectWebSocket = () => {
      // Clear any existing reconnection attempt before starting a new one
      if (reconnectTimeoutId !== null) {
        clearTimeout(reconnectTimeoutId);
        reconnectTimeoutId = null;
      }

      try {
        const socket = new SockJS(`${externalConfig.REACT_APP_WS_URL}`);
        socket.onerror = error => {
          console.error('Socket error:', error);
          attemptReconnect();
        };
        client = Stomp.over(socket);
        client.debug = str => {
          if (process.env.NODE_ENV === 'development') {
            console.log('Socket debug:', str);
          }
        };

        client.connect(
          {
            headers: {
              Authorization: sessionStorage.getItem(SESSION_HEADER),
            },
          },
          () => {
            console.log('WebSocket Connected');
            client?.subscribe('/topic/status', message => {
              try {
                const data = JSON.parse(message.body);
                const projectId = getSearchParameter('projectId');

                if (
                  data &&
                  data.projectId &&
                  data.projectId === projectId &&
                  data.jobName === 'transformExecution'
                ) {
                  handleLicenseStatusDetails(data || {});
                  handleTransformationStatus(data.statusDetails || {});
                  return;
                }

                if (
                  data &&
                  data.projectId &&
                  data.projectId === projectId &&
                  data.jobName === 'validationStatus'
                ) {
                  console.log('Converted Socket data:', data);
                  dispatch(
                    setValidationProgress(
                      parseInt(data.validationPercentage) || 0,
                    ),
                  );
                  dispatch(setValidationFiles(data.filesAndParents));
                  return;
                }
                if (
                  data &&
                  data.projectId &&
                  data.projectId === projectId &&
                  data.jobName === 'scanExecution' &&
                  data.status === 'RUNNING'
                ) {
                  console.log('Converted Socket data:', data);
                  dispatch(
                    setScanProgress(parseInt(data.completedPercentage) || 0),
                  );
                  return;
                }
                if (data && data.projectId) {
                  if (projectId === data.projectId) {
                    console.log('Converted Socket data:', data);
                    if (data.isScanReportGenerated === false) {
                      // dispatch(setScanJclFailed(true));
                      dispatch(setUploadedArtifacts(undefined));
                      dispatch(setActiveModal(EAppModal.SCAN_JCL_FAILED));
                    } else if (data.jobName === 'scanExecution') {
                      handleScanCompleteEvent();
                    } else if (data.jobName === 'scanExecutionError') {
                      dispatch(setScannerErrorMessage(data.errorMessage));
                    }
                  }
                }

                if (
                  data &&
                  data.projectId &&
                  data.projectId === projectId &&
                  data.jobName === 'smartDocStatus'
                ) {
                  console.log('Converted Socket data:', data);
                  return handleSmartDocStatus(data || {});
                }
              } catch (error) {
                console.error('Error processing message:', error);
              }
            });
          },
          error => {
            console.error('Connection error:', error);
            attemptReconnect();
          },
        );
      } catch (error) {
        console.error('WebSocket Error: ', error);
        attemptReconnect();
      }
    };

    const attemptReconnect = () => {
      if (shouldAttemptReconnect) {
        // Clear any existing reconnection attempt
        if (reconnectTimeoutId !== null) {
          clearTimeout(reconnectTimeoutId);
        }
        reconnectTimeoutId = setTimeout(() => {
          console.log('Attempting to reconnect WebSocket...');
          connectWebSocket();
        }, 5000); // Reconnect after 5 seconds
      }
    };

    connectWebSocket();

    return () => {
      shouldAttemptReconnect = false;
      // Clear the reconnection attempt when the component is about to unmount
      if (reconnectTimeoutId !== null) {
        clearTimeout(reconnectTimeoutId);
      }
      if (client && client.connected) {
        client.disconnect(() => {
          console.log('WebSocket Disconnected');
        });
      }
    };
  }, []);

  useEffect(() => {
    const isModalShown = sessionStorage.getItem('activeModal');
    if (!isModalShown) {
      dispatch(setActiveModal(EAppModal.ABOUT_US));
      sessionStorage.setItem('activeModal', 'true');
    }
  }, [dispatch]);

  return (
    <>
      {(process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_CF_ENV == 'onprem' ||
        window.location.hostname === 'deploy.cloudframe.com' ||
        window.location.hostname === '192.168.20.18' ||
        window.location.hostname === 'cn.cloudframe.com' ||
        window.location.hostname === '50.174.16.194' ||
        window.location.hostname === '192.168.20.35' ||
        window.location.hostname === '192.168.20.25' ||
        window.location.hostname === 'demo.chatbot.cloudframe.com') && (
        <div
          className="h-[100px] w-full"
          style={{ background: 'rgb(19, 99, 223)' }}
        >
          <WireframeHeader />
        </div>
      )}
      <LayoutView>
        <main className="h-full">
          <AppNavigation />
        </main>
        <Toaster />
        <AppModals />
        {/* {license?.Environment === 'ONPREM' &&
          ![
            ROUTES.LOGIN,
            ROUTES.DASHBOARD,
            ROUTES.ADMIN_GLOBAL_PROJECT_SETTINGS,
            ROUTES.ADMIN_NOTES,
            ROUTES.ADMIN_REPORTS,
          ].includes(currentRoute as any) && (
            <div className="fixed bottom-8 right-10">
              {userLoginDetails && (
                <div className="h-14 w-14 bg-grey20 rounded-full flex items-center justify-center">
                  <AddNotesWidget />
                </div>
              )}
            </div>
          )} */}
      </LayoutView>
    </>
  );
}

export default App;
