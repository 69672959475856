import { convert2Ascii } from "./convert2ascii";

export async function convertEBCDICToASCIIAndSetContent(file: any, type: string, fileType: string, recordLength: number): Promise<{ mfContent: string, cfContent: string }> {
  let fileData = new Blob([file]);

  let mfContent = '';
  let cfContent = '';

  const getBuffer = (fileData: any): Promise<Uint8Array> => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileData);
      reader.onload = () => {
        let arrayBuffer = reader.result;
        if (arrayBuffer) {
          let bytes = new Uint8Array(arrayBuffer as ArrayBuffer);
          resolve(bytes);
        } else {
          reject(new Error("Failed to read file as ArrayBuffer."));
        }
      };
      reader.onerror = () => reject(new Error("File reading error."));
    });
  };

  try {
    // Wait for the file buffer to be ready
    const data = await getBuffer(fileData);

    // Handle the variable length file type condition
    if (fileType === "Variable Length") {
      recordLength = 0;
    }

    // Convert the data from EBCDIC to ASCII
    const result = convert2Ascii(data, recordLength);
    
    // Prepare the content
    let output = result.join('\n');

    // Set the content based on the type
    if (type === "MainFrame") {
      mfContent = output;
    } else {
      cfContent = output;
    }

    // Return both contents as a Promise result
    return { mfContent, cfContent };

  } catch (error) {
    console.error("Error during file conversion:", error);
    throw error;
  }
}
