import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { IProjectArtifactsProps } from './project-artifacts.types';
import { Badge } from 'src/components/ui/badge';
import { FaEye, FaProjectDiagram } from 'react-icons/fa';

const ProjectArtifacts = ({ rows }: IProjectArtifactsProps) => {
  
  const handleShowProjectDependency = (project: any) => {
    console.log(project);
    alert('Show Project Artifact Modal');
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-2">Associated Artifacts </h1>
      <p className="text-lg text-gray-600 max-w-[80%]">
        View and manage the files you&apos;ve uploaded for this project. Ensure
        all necessary artifacts are in place to smoothly transition your COBOL
        project to Java.
      </p>
      <div className="mt-8">
        <Table>
          <TableCaption>A list of your project artifacts.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead>Artifact Name</TableHead>
              <TableHead>Artifact Type</TableHead>
              <TableHead>Artifact State</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Modified Date</TableHead>
              <TableHead>Created By</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.map((project: any) => (
              <TableRow key={project.id}>
                <TableCell className="w-[15rem]">{project.artifactName || 'NA'}</TableCell>
                <TableCell className="min-w-[5rem]">{project.artifactType || 'NA'}</TableCell>
                <TableCell className="w-[15rem]">{project.artifactState|| 'NA'}</TableCell>
                <TableCell className="min-w-[5rem]">{project.tokenProcessorStatus || 'NA'}</TableCell>
                <TableCell>{project.modifiedDate || 'NA'}</TableCell>
                <TableCell>{project.createdBy || 'NA'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ProjectArtifacts;
