import {
  Checkbox,
  initializeIcons,
  PrimaryButton,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import { useEffect, useRef, useState } from 'react';
import SVGTrash from '../../../assets/images/svg/trash.icon';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import MissingUploadBtn from '../../../components/missing-upload-button/missing-upload-button';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModal,
  setControlCardData,
  setCurrentIntent,
  setIgnoredMissingArtifacts,
  setUploadedMissingArtifacts,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
  IUploadedArtifacts,
  TableData,
} from '../../../redux/app-global/app-global.types';
import React from 'react';
import { SharedService } from '../../../services/shared-service';
import {
  commaSeparated,
  downloadExtractJcl,
  externalConfig,
  isValidDataset,
} from '../../../utils/misc.utils';
import { VALID_CONTROL_FILE_TYPES } from './missing-controlcard-types';
import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import MissingUploadList, {
  UploadMissingListRef,
} from '../../../components/missing-artifact-upload/missing-artifact-upload';
import {
  ExtractJclRequest,
  ExtractType,
} from '../extract-artifact/extract-artifact.model';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import { FaSpinner } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { MissingPageService } from '../../../services/missing-artifacts.service';
import { useGetMissingControlCardsQuery } from '../../../redux/api-slice';
import { startScan } from '../../../components/upload-list/upload-list.service';
import { getSearchParameter } from 'src/utils/url.utils';
initializeIcons();

const MissingControlCard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
  const [files, setFiles] = useState<Record<string, UploadFile>>({});
  const [selectAll, setSelectAll] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [missingArtifacts, setMissingArtifacts] =
    useState<IUploadedArtifacts>();
  const [selectedRows, setSelectedRows] = useState({});
  const [data, setData] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(false);
  const controlcardData = useSelector(
    (state: RootState) => state.appGlobal.controlCardData,
  );
  const ignoredMissingArtifacts = useSelector(
    (state: RootState) => state.appGlobal.ignoredMissingArtifacts,
  );
  const [alertShown, setAlertShown] = useState({ missingControlCard: false });
  const missingControlCardQuery = useGetMissingControlCardsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const uploadMissingListRef = useRef<UploadMissingListRef>(null);
  const pagHelpTexts = helpTexts[0].MISSING_CONTROLCARD;
  const projectId = getSearchParameter('projectId');

  useEffect(() => {
    if (
      data?.length > 0 &&
      ignoredMissingArtifacts?.missingControlCard &&
      Array.isArray(ignoredMissingArtifacts.missingControlCard) &&
      ignoredMissingArtifacts.missingControlCard.length > 0
    ) {
      const selectedIndexes = {};
      ignoredMissingArtifacts.missingControlCard.forEach(item => {
        const index = data?.findIndex(dataItem => dataItem.name === item.name);

        if (index !== -1) {
          selectedIndexes[index] = true;
        }
      });

      setSelectedRows(prev => ({
        ...prev,
        ...selectedIndexes,
      }));
      setData(prevData =>
        prevData.map(item => ({
          ...item,
          status: !selectedIndexes[item.key] ? 'Ignore' : 'Active',
        })),
      );
    }
  }, [ignoredMissingArtifacts, data?.length]);

  const triggerFileUpload = (event, itemKey) => {
    if (uploadMissingListRef.current) {
      uploadMissingListRef.current.handleFileUpload(event, itemKey);
    }
  };
  const handleFilesUpdate = updatedFiles => {
    setFiles(updatedFiles);
  };
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const handleCheckboxChange = key => {
    setSelectedRows(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
    setData(prevData =>
      prevData.map(item =>
        item.key === key
          ? { ...item, status: !selectedRows[key] ? 'Ignore' : 'Active' }
          : item,
      ),
    );
  };

  const sendControlCardInfo = async () => {
    const formData = new FormData();
    const requestBody: any = [];
    data.forEach(row => {
      const item = {
        name: row.name,
        status: row.status || '',
        file: row.uploaded || '',
        referredIn: row.requiredBy || [],
      };
      requestBody.push(item);
    });

    formData.append('missingData', JSON.stringify(requestBody));

    Object.values(files).forEach(file => {
      console.log((file as any).originalFile);
      if (file) {
        formData.append('fileList', (file as any).originalFile);
      }
    });

    try {
      setLoading(true);
      const response = await axios.post(
        `${externalConfig.REACT_APP_REPORT_API_URL}/missing-artifact/${projectId}/CONTROL_CARD`,
        formData,
      );

      if (
        response.data?.status !== 'SUCCESS' ||
        response.data?.message === 'Some artifacts are missing '
      ) {
        Swal.fire({
          title: 'Resolve all missing artifacts',
          text:
            response.data?.message ||
            'Failed to upload missing artifacts. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });

        await missingControlCardQuery.refetch();
        return;
      }

      dispatch(setUploadedMissingArtifacts(missingArtifacts));
      dispatch(setControlCardData(data));

      const nextIntent = MissingPageService.getRouteFromAction(
        response.data?.data?.nextAction,
      );

      if (nextIntent === 'REPORT') {
        dispatch(setIgnoredMissingArtifacts(response.data?.data?.ignoredData));
      }

      if (nextIntent === EAppIntent.UPLOADING_ARTIFACT) {
        const scanStarted = await startScan(dispatch);
        if (!scanStarted) {
          return;
        }
      }

      dispatch(setCurrentIntent(nextIntent));
    } catch (err) {
      console.log(err);
      SharedService.genericAlertModal.title =
        'Upload of Missing Artfacts Failed!';
      SharedService.genericAlertModal.description =
        (err as any)?.message || 'Something went wrong !';
      setTimeout(() => {
        dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
      }, 0);
    } finally {
      setLoading(false);
    }
  };
  console.log(data);
  useEffect(() => {
    if (controlcardData && controlcardData?.length > 0) {
      setData(controlcardData);
      return;
    }

    const { missingData = [], ignoredData = [] } =
      missingControlCardQuery.data || {};

    const transformedData: TableData[] = missingData.map(
      (item: any, index: number) => ({
        key: index,
        name: item.name,
        requiredBy: item.referredIn,
        uploaded: null,
        status: 'Active',
      }),
    );
    const missingLen = transformedData.length;
    let selRows = {};
    ignoredData.forEach((item: any, index: number) => {
      selRows[index + missingLen + 1] = true;
      transformedData.push({
        key: index + missingLen + 1,
        name: item.name,
        requiredBy: item.referredIn,
        uploaded: null,
        status: 'Ignore',
      } as any);
    });

    setSelectedRows(selRows);
    setData(transformedData);
  }, [missingControlCardQuery]);

  const handleSelectAllCheckboxChange = (event, checked) => {
    if (!Array.isArray(data)) {
      console.error('Data is not an array or is undefined');
      return;
    }
    setSelectAll(checked);
    const newSelectedRows = {};
    data.forEach(item => {
      newSelectedRows[item.key] = checked;
    });
    setSelectedRows(newSelectedRows);
    const newData = data.map(item => {
      newSelectedRows[item.key] = checked;
      return { ...item, status: checked ? 'Ignore' : 'Active' };
    });
    setSelectedRows(newSelectedRows);
    setData(newData);
  };
  const changeDisplay = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setToggleValue(checked);
  };
  const headers: React.ReactNode[] = [
    <Checkbox
      checked={selectAll}
      onChange={handleSelectAllCheckboxChange}
      title="Ignore All"
      label="Ignore All"
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
      key="1"
    />,
    'Control Card',
    'Required by JCL/Proc',
    !toggleValue && !data?.every(item => item.uploaded) && 'Upload',
  ];
  const rowFields = item => [
    <Checkbox
      checked={selectedRows[item.key] || false}
      onChange={() => handleCheckboxChange(item.key)}
      title="Ignore"
      label="Ignore"
      key={item.key}
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
    />,
    <div
      className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
      key={item.key}
    >
      {item.name}
    </div>,
    item.requiredBy?.jcl.length <= 4 ? (
      <div
        className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
        key={item.key}
      >
        {item.requiredBy?.jcl?.join(', ')},{' '}
        {item.requiredBy?.ddName?.join(', ')}
      </div>
    ) : (
      <div key={item.key}>
        <TooltipHost
          id={`tooltip-${selectedRows[item.key]}`}
          content={`${item.requiredBy?.jcl.slice(4, item.requiredBy?.jcl.length - 1).join(', ')}, ${item.requiredBy?.ddName}`}
        >
          <div
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            {item.requiredBy?.jcl.slice(0, 3).join(', ')}
          </div>
          <span
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            ... and {item.requiredBy?.jcl.length + item.requiredBy?.ddName - 3}{' '}
            more
          </span>
        </TooltipHost>
      </div>
    ),
    selectedRows[item.key] ? (
      item.uploaded ? (
        <div className="flex items-center gap-2 text-gray-400">
          {item.uploaded.name}
        </div>
      ) : (
        ''
      )
    ) : (
      !item.uploaded &&
      !toggleValue && (
        <MissingUploadBtn
          handleFileUpload={triggerFileUpload}
          item={item}
          accept={`${VALID_CONTROL_FILE_TYPES.map(type => '.' + type).join(',')}`}
        />
      )
    ),
  ];

  const [datasetNames, setDatasetNames] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validationError = commaSeparated(value.trim());

    if (validationError) {
      setError(validationError);
      setSuccess(null);
    } else {
      setError(null);
      setSuccess(null);
    }
    setDatasetNames(value);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const validationError = commaSeparated(datasetNames.trim());
    if (validationError) {
      setError(validationError);
      setSuccess(null);
      return;
    }
    if (!datasetNames.trim()) {
      setError('Dataset name is required.');
      setSuccess(null);
      return;
    }

    const datasetArray = datasetNames.split(',').map(name => name.trim());

    const data: ExtractJclRequest = {
      dataset: {
        cc: datasetArray,
      },
      downloadFor: 'CC' as ExtractType,
    };

    const isDownloadSuccessful = await downloadExtractJcl(data);

    if (isDownloadSuccessful) {
      setSuccess('Data successfully posted and file downloaded!');
      setError(null);
      setDatasetNames('');
    } else {
      setError('Failed to post data or download the file. Please try again.');
      setSuccess(null);
    }
  };

  if (missingControlCardQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (missingControlCardQuery.isError && !alertShown.missingControlCard) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to fetch Missing Control Card details. Please try again later.',
      footer: 'Please contact support',
      showCancelButton: true,
      confirmButtonText: 'Retry',
      cancelButtonText: 'Go Back',
    }).then(result => {
      if (result.isConfirmed) {
        missingControlCardQuery.refetch();
        setAlertShown({ missingControlCard: false });
      } else {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
      }
    });
    setAlertShown({ missingControlCard: true });
    return null;
  }

  return (
    <div
      className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}
    >
      <div className="p-2 mb-4">
        <StepperComponent />
      </div>
      <div className="mb-2 border rounded-lg px-8 py-8 shadow max-w-full">
        <h1 className="text-3xl font-semibold mb-4">
          Missing Artifacts Workspace
        </h1>
        <hr />
        <div className="flex flex-col gap-4">
          <div className="text-left mt-4 gap-4 text-l font-semibold">
            Missing Control Card Information
          </div>
          <div>
            Upload the missing Control Card or provide the dataset information
            to extract the missing Control Card from the mainframe.
          </div>
          <div className="flex items-center gap-4">
            <HelpTextCallout calloutText={pagHelpTexts[4].description} />
            <span className="text-sm">Upload Control Card</span>
            <Toggle className="mt-2" onChange={changeDisplay} />
            <span className="text-sm">Extract Control Card from Mainframe</span>
          </div>

          {!toggleValue && (
            <>
              <div className="font-semibold mt-4">
                Upload Control Card as File
              </div>
              <hr />
            </>
          )}
          {toggleValue && (
            <>
              <div className="flex flex-col gap-2">
                <h3>Enter the dataset names separated by commas:</h3>
                <input
                  type="text"
                  className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                  value={datasetNames}
                  onChange={handleChange}
                  placeholder="Enter dataset names..."
                />
                {error && (
                  <TooltipHost content={error} id="error-tooltip">
                    <div className="mt-2 text-red-500">{error}</div>
                  </TooltipHost>
                )}
                {success && (
                  <TooltipHost content={success} id="success-tooltip">
                    <div className="mt-2 text-green-500">{success}</div>
                  </TooltipHost>
                )}
                <div>
                  {toggleValue &&
                    ((Array.isArray(data) &&
                      data.length > 0 &&
                      data.every(
                        item =>
                          item.status !== 'Active' || item.uploaded !== null,
                      )) ||
                    Object.values(files).length > 0 ||
                    toggleValue ? (
                      <div className="flex flex-row items-center mt-8">
                        <HelpTextCallout
                          calloutText={pagHelpTexts[6].description}
                        />
                        <PrimaryButton
                          text={'Generate Extraction JCL'}
                          className="rounded-md"
                          styles={{ root: { backgroundColor: '#1363DF' } }}
                          onClick={handleSubmit}
                        />
                      </div>
                    ) : null)}
                </div>
              </div>
            </>
          )}

          <ReusableTable
            items={data}
            headers={headers}
            rowFields={rowFields}
            helpTexts={pagHelpTexts}
          />
          {!toggleValue ? (
            <MissingUploadList
              ref={uploadMissingListRef}
              setData={setData}
              setMissingArtifacts={setMissingArtifacts as any}
              type="Control Card"
              data={data}
              onFilesUpdate={handleFilesUpdate}
              pageHelpText={pagHelpTexts}
            />
          ) : (
            ''
          )}
        </div>
        <div></div>
        {!toggleValue && (
          <div className="flex flex-row gap-4 items-center">
            <PrimaryButton
              text="Save and Continue"
              className="mt-8 rounded-md"
              styles={{ root: { backgroundColor: '#1363DF' } }}
              onClick={sendControlCardInfo}
              disabled={loading}
            />
            {loading && (
              <div className="flex gap-2 mt-4">
                <FaSpinner className="animate-spin" />
                <p>Updating Missing data ....</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default MissingControlCard;
