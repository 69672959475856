import ArtifactAssessmentSetps from '../../components/diagrams/cf-overview.diagram';
import { EAppIntent } from '../../redux/app-global/app-global.types';
import AboutUsPage from './about-us/about-us';
import ArtifactInfo from './artifact-info/artifact-info';
import CallChainPage from './call-chain/call-chain';
import CodeAccuracyPage from './code-accuracy/code-accuracy';
import CodeConversionProcess from './code-coversion-process/code-coversion-process';
import ExtractArtifactPage from './extract-artifact/extract-artifact';
import CloudframeServicesPage from './info-pages/cloudframe-services';
import JCLArtifactExtractPage from './jcl-artifact-extract/jcl-artifact-extract';
import JCLCCExtractPage from './jcl-cc-extract/jcl-cc-extract';
import JCLProcExtractPage from './jcl-proc-extract/jcl-proc-extract';
import ListingArtifactExtractPage from './listing-artifact-extract/listing-artifact-extract';
import MissingSymbols from './missing-symbols/missing-symbols';
import MissingControlCard from './missing-controlcard/missing-controlcard';
import MissingMetaData from './missing-metadata/missing-metadata';
import MissingProcs from './missing-procs/missing-procs';
import MissingPrograms from './missing-program/missing-programs';
import ReportPage from './report/report';
import SampleReportPage from './report/samplereport';
import TransformationPage from './transformation-page/transformation-page';
import UploadArtifact from './upload-artifact/upload-artifact';
import DefaultUploadingArtifacts from './uploading-artifacts/uploading-artifacts';
import ResolveDynamic from './missing-resolve-dynamic-calls/resolve-dynmaic-calls';
import TransformSettings from './transform-settings/transform';
import TransformationReport from './transformation-report/transformation-report';
import SmartDoc from './smart-doc/smart-doc';
import TestChevron from './test-chevron/test-chevron';
import Marketplace from './marketplace-page/marketplace';
import DeployChevron from './deploy-chevron/deploy-chevron';


export const intentToPageMap = (intent: EAppIntent | null, currentStage: string) => {
  const normalizedStage = currentStage.toLowerCase();
  const defaultStagePages = {
    extract: <ExtractArtifactPage />,
    upload: <UploadArtifact />,
    report: <ReportPage />,
    transform: <TransformationPage/>,
    smartDoc : <SmartDoc/>,
    test: <TestChevron />,
    deploy: <DeployChevron />,
    default: <AboutUsPage />,
  };

  const renderComponent = (intent: EAppIntent | null) => {
    switch (intent) {
      case EAppIntent.EXTRACT_ARTIFACT:
        return <ExtractArtifactPage />;
      case EAppIntent.JCL_ARTIFACT_INSTRUMENTATION:
        return <JCLArtifactExtractPage />;
      case EAppIntent.JCL_CC_ARTIFACT_INSTRUMENTATION:
        return <JCLCCExtractPage />;
      case EAppIntent.JCL_PROC_ARTIFACT_INSTRUMENTATION:
        return <JCLProcExtractPage />;
      case EAppIntent.LISTING_ARTIFACT_INSTRUMENTATION:
        return <ListingArtifactExtractPage />;
      case EAppIntent.ARTIFACT_UPLOAD:
        return <UploadArtifact />;
      case EAppIntent.UPLOADING_ARTIFACT:
        return <DefaultUploadingArtifacts />;
      case EAppIntent.REPORT:
        return <ReportPage />;
      case EAppIntent.SAMPLE_REPORT:
        return <SampleReportPage />;
      case EAppIntent.TRANSFORMATION_PAGE:
        return <TransformationPage />;
      case EAppIntent.TRANSFORM_SETTINGS:
        return <TransformSettings />;
      case EAppIntent.TRANSFORMATION_REPORT:
        return <TransformationReport />;
      case EAppIntent.ABOUT_US:
        return <AboutUsPage />;
      case EAppIntent.CALLCHAIN:
        return <CallChainPage />;
      case EAppIntent.SELF_SERVICE:
        return <ArtifactAssessmentSetps />;
      case EAppIntent.COBOL_TO_JAVA:
        return <CodeConversionProcess />;
      case EAppIntent.CF_SERVICES:
        return <CloudframeServicesPage />;
      case EAppIntent.ARTIFACT_INFO:
        return <ArtifactInfo />;
      case EAppIntent.CODE_ACCURACY:
        return <CodeAccuracyPage />;
      case EAppIntent.MISSING_SYMBOLS:
        return <MissingSymbols />;
      case EAppIntent.RESOLVE_DYNAMIC_CALLS:
        return <ResolveDynamic />;
      case EAppIntent.MISSING_CONTROLCARD:
        return <MissingControlCard />;
      case EAppIntent.MISSING_METADATA:
        return <MissingMetaData />;
      case EAppIntent.MISSING_PROC:
        return <MissingProcs />;
      case EAppIntent.MISSING_PROGRAM:
        return <MissingPrograms />;
      case EAppIntent.SMART_DOC:
        return <SmartDoc />;
      case EAppIntent.TEST_CHEVRON:
          return <TestChevron />;    
      case EAppIntent.MARKETPLACE_PAGE:
          return <Marketplace />;
      case EAppIntent.DEPLOY_PAGE:
          return <DeployChevron />;
      default:
        return null; 
    }
  }; 

  const intentComponent = renderComponent(intent);
  if (intentComponent) return intentComponent;

  return defaultStagePages[normalizedStage] || defaultStagePages.default;
};
