import React, { useEffect } from 'react';
import Loader from './loader';
import { fetchBotResponse } from './chatbot.service';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCustomChatMessages,
  setActiveModal,
  setCurrentContent,
  setCurrentIntent,
  setResetChatbot,
} from '../../redux/app-global';
import { EAppIntent, EAppModal } from '../../redux/app-global/app-global.types';
import { RootState } from '../../redux/store';
import { createClientMessage } from 'react-chatbot-kit';
import { SharedService } from '../../services/shared-service';
import { getMockResponse } from './chatbot-mock-response';
import ReactMarkdown from 'react-markdown';
import { externalConfig } from '../../utils/misc.utils';

let runOnce = false;

const ActionProvider = ({
  createChatBotMessage,
  setState,
  state,
  children,
}: any) => {
  const dispatch = useDispatch();
  const customMessage = useSelector(
    (state: RootState) => state.appGlobal.customChatMessage,
  );
  const scanCompleted = useSelector(
    (state: RootState) => state.appGlobal.scanCompleted,
  );
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const resetChatbot = useSelector(
    (state: RootState) => state.appGlobal.resetChatbot,
  );

  useEffect(() => {
    if (resetChatbot) {
      setState((prev: any) => {
        return { ...prev, messages: [prev.messages[0]] };
      });
      dispatch(setResetChatbot(false));
    }
  }, [resetChatbot, dispatch]);

  // useEffect(() => {
  //   if (customMessage?.length > 0) {
  //     handlePrompt(customMessage[0].message);
  //     dispatch(clearCustomChatMessages());
  //   }
  // }, [customMessage, dispatch]);

  useEffect(() => {
    if (scanCompleted && !runOnce) {
      runOnce = true;
      setState((prev: any) => ({
        ...prev,
        messages: [
          ...prev.messages,
          createChatBotMessage(
            'Welcome to CodeNavigator AI Assistant. How can I help you today?',
            {},
          ),
        ],
      }));
    }
  }, [scanCompleted, state?.messages]);

  function handleOption(option: {
    id: string;
    message: string;
    intent: string;
    type: string;
    content: any;
  }) {
    if (Object.values(EAppIntent).includes(option.intent as any)) {
      dispatch(setCurrentIntent(option.intent as EAppIntent));
      dispatch(setCurrentContent(option.content));
    } else {
      console.log('new intent:', option.intent);
    }
    handlePrompt1(option.message);
  }

  SharedService.handleOption = handleOption as any;

  async function handlePrompt(message: string) {
    console.log(message);
    const loading = createChatBotMessage(<Loader />);
    // const clientMessage = createClientMessage(message, {});
    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, loading],
    }));
    const randomDelay = Math.floor(Math.random() * 2000) + 1000;
    await new Promise(resolve => setTimeout(resolve, randomDelay));
    setState((prev: any) => {
      const newPrevMsg = prev.messages.slice(0, -1);
      return {
        ...prev,
        messages: [
          ...newPrevMsg,
          createChatBotMessage(getMockResponse(message)),
        ],
      };
    });
  }

  async function handlePrompt1(message: string) {
    const loading = createChatBotMessage(<Loader />);
    const clientMessage = createClientMessage(message, {});
    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, loading],
    }));

    let options: any = [];
    try {
      options = await fetchBotResponse(message);
      SharedService.isOpenAiActive = true;
      const formattedOptions = (
        <ReactMarkdown>
          {options.replace(/\n\n(\d+\.)/g, '\n$1').replace(/\n/g, '\n')}
        </ReactMarkdown>
      );
      setState((prev: any) => {
        const newPrevMsg = prev.messages.slice(0, -1);
        return {
          ...prev,
          messages: [...newPrevMsg, createChatBotMessage(formattedOptions)],
        };
      });
    } catch (err) {
      SharedService.isOpenAiActive = false;
      setState((prev: any) => {
        const newPrevMsg = prev.messages.slice(0, -1);
        return {
          ...prev,
          messages: [
            ...newPrevMsg,
            createChatBotMessage(
              'AI Chat plugin is not activated! Please contact CloudFrame Support team for further assistance.',
            ),
          ],
        };
      });
      return;
    }
    // const promptResp = options.find(
    //   (option: any) => option?.type === 'prompt-response',
    // );
    // let restOption = options.filter(
    //   (option: any) => option?.type !== 'prompt-response',
    // );

    // if (license?.Environment === 'ONPREM') {
    //   restOption = restOption.filter((option: any) => !option?.hideForOnPrem);
    // }

    // if (promptResp) {
    //   const paragraphs = promptResp.message.split('\n');
    //   const messages: any = [];
    //   paragraphs.forEach((paragraph: string, index: number) => {
    //     const isLastParagraph = index === paragraphs.length - 1;
    //     const botMessage = createChatBotMessage(
    //       paragraph,
    //       isLastParagraph
    //         ? {
    //             widget: 'options',
    //             payload: {
    //               title: '',
    //               options: restOption,
    //               handler: handleOption,
    //             },
    //           }
    //         : {},
    //     );
    //     messages.push(botMessage);
    //   });
    //   setState((prev: any) => {
    //     const newPrevMsg = prev.messages.slice(0, -1);
    //     return { ...prev, messages: [...newPrevMsg, ...messages] };
    //   });
    // } else {
    //   const botMessage = createChatBotMessage(
    //     `Please select one of the following options`,
    //     {
    //       widget: 'options',
    //       payload: { title: '', options: restOption, handler: handleOption },
    //     },
    //   );
    //   setState((prev: any) => {
    //     const newPrevMsg = prev.messages.slice(0, -1);
    //     return { ...prev, messages: [...newPrevMsg, botMessage] };
    //   });
    // }

    // if (promptResp?.intent) {
    //   dispatch(setCurrentIntent(promptResp.intent as EAppIntent));
    // }
    // if (promptResp?.content) {
    //   dispatch(setCurrentContent(promptResp.content));
    // }
  }

  function getUserInfo() {
    const botMessage = createChatBotMessage(
      'In order to continue, please provide your contact information',
    );
    setTimeout(() => {
      dispatch(setActiveModal(EAppModal.ACCESS_FULL_REPORT));
    }, 2000);
    setState((prev: any) => {
      return { ...prev, messages: [...prev.messages, botMessage] };
    });
  }

  function handleUploadArtifact() {
    const botMessage = createChatBotMessage(
      'Please upload your Artifact in Information center',
    );
    setTimeout(() => {
      dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
    }, 500);
    setState((prev: any) => {
      return { ...prev, messages: [...prev.messages, botMessage] };
    });
  }

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions: { handlePrompt1, getUserInfo, handleUploadArtifact },
        });
      })}
    </div>
  );
};

export default ActionProvider;
