import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import {
  CreateProjectProps,
  IDependentProject,
  IProjectFormData,
} from './create-project.types';
import { ProjectDetails } from './create-project-stages/project-details';
import { ProjectDependency } from './create-project-stages/project-dependency-details';
import { IoCreate } from 'react-icons/io5';
import { IUser } from '../admin/users/users.service';
import {
  useCreateNewProjectMutation,
  useLazyGetProjectDependenciesQuery,
  useLazyGetProjectsQuery,
} from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { SharedService } from 'src/services/shared-service';

const CreateProject = ({ isOpen, onClose }: CreateProjectProps) => {
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectFormData, setProjectFormData] = useState<IProjectFormData>({
    projectName: '',
    projectType: 'SmartDoc',
    startDate: '',
    endDate: '',
    organizationName: license?.licenseFor || '',
    organizationId: '',
    teamMembers: [],
    projectLead: null,
    dependentProjects: [],
  });

  const [createNewProject, { isLoading }] = useCreateNewProjectMutation();
  const projectsQuery = useLazyGetProjectsQuery();
  const projectDependenciesQuery = useLazyGetProjectDependenciesQuery();
  const handleProjectFormChange = (
    key: string,
    value: string | IUser | IUser[] | IDependentProject[] | null,
  ) => {
    setProjectFormData({ ...projectFormData, [key]: value });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleProjectDetailsValidation = () => {
    if (!projectFormData.projectName) {
      alert('Please enter project name');
      return false;
    }

    if (!projectFormData.projectType) {
      alert('Please select project type');
      return false;
    }

    if (!projectFormData.startDate) {
      alert('Please select start date');
      return false;
    }

    if (!projectFormData.endDate) {
      alert('Please select end date');
      return false;
    }

    if (projectFormData.endDate < projectFormData.startDate) {
      alert('End date should be greater than start date');
      return false;
    }

    if (projectFormData.teamMembers.length && !projectFormData.projectLead) {
      alert('Please select project lead');
      return false;
    }

    return true;
  };

  const resetFormData = () => {
    setCurrentPage(1);
    setProjectFormData({
      projectName: '',
      projectType: 'SmartDoc',
      startDate: '',
      endDate: '',
      organizationName: license?.licenseFor || '',
      organizationId: '',
      teamMembers: [],
      projectLead: null,
      dependentProjects: [],
    });
  };

  const handleClose = () => {
    setCurrentPage(1);
    resetFormData();
    onClose();
  };

  const handleCreate = async () => {
    const isFormValid = handleProjectDetailsValidation();

    if (!isFormValid) {
      return;
    }

    const response = await createNewProject(projectFormData);
    console.log('Project create:', response);

    if (response.error) {
      Swal.fire({
        icon: 'error',
        title: 'Project creation failed',
        text: 'Please try again later.',
      });
      return;
    }

    onClose();

    Swal.fire({
      icon: 'success',
      title: 'Project created successfully',
    });
    resetFormData();
    projectsQuery[0]();
    projectDependenciesQuery[0]();
    // if (SharedService.refetchProjectsInDashBoard) {
    //   SharedService.refetchProjectsInDashBoard();
    // }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-5 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-2xl max-w-2xl w-full h-[95%] overflow-hidden flex flex-col relative">
        <div className="flex justify-between items-center pb-2 sticky top-0 bg-white z-10 py-2 border-b">
          <h2 className="text-xl font-bold">Create New Project</h2>

          <button
            onClick={handleClose}
            className="flex items-center justify-center w-9 h-9 bg-gray-100 text-gray-600 hover:bg-red-500 hover:text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none shadow-lg"
            aria-label="Close modal"
          >
            <FaTimes size={15} />
          </button>
        </div>

        <div className="flex flex-col h-full relative">
          <div className=" relative mt-4 max-h-[82%] overflow-auto overflow-x-hidden flex-grow">
            {currentPage === 1 && (
              <ProjectDetails
                projectFormData={projectFormData}
                handleProjectFormDataChange={handleProjectFormChange}
              />
            )}
            {currentPage === 2 && (
              <ProjectDependency
                projectFormData={projectFormData}
                handleProjectFormDataChange={handleProjectFormChange}
              />
            )}
          </div>
          <div className="h-[10%] flex flex-col items-center  bg-white z-10 py-2 border-t pt-4 justify-end">
            {projectFormData.projectType !== 'SmartDoc' ? (
              <div className="flex space-x-2 mb-4">
                <div
                  className={`w-3 h-3 rounded-full ${currentPage === 1 ? 'bg-blue-500' : 'bg-gray-300'}`}
                />
                <div
                  className={`w-3 h-3 rounded-full ${currentPage === 2 ? 'bg-blue-500' : 'bg-gray-300'}`}
                />
              </div>
            ) : (
              <div className="h-3 mb-4" />
            )}

            <div className="flex justify-between w-full">
              {currentPage > 1 &&
                projectFormData.projectType != 'SmartDoc' && (
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={isLoading}
                    className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
                  >
                    <span>&larr;</span>&nbsp;&nbsp;
                    <span>Previous</span>
                  </button>
                )}

              <div className="flex justify-end w-full mt-auto">
                {currentPage < 2 &&
                  projectFormData.projectType != 'SmartDoc' && (
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={isLoading}
                      className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
                    >
                      Next &nbsp;&rarr;
                    </button>
                  )}

                {projectFormData.projectType === 'SmartDoc' && (
                  <button
                    onClick={handleCreate}
                    disabled={isLoading}
                    className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition flex items-center space-x-2"
                  >
                    <span>Create</span>
                    <span>
                      <IoCreate />
                    </span>
                  </button>
                )}

                {currentPage === 2 &&
                  projectFormData.projectType != 'SmartDoc' && (
                    <button
                      onClick={handleCreate}
                      disabled={isLoading}
                      className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition flex items-center space-x-2"
                    >
                      <span>Create</span>
                      <span>
                        <IoCreate />
                      </span>
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
