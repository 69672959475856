import { MdAccessAlarm } from 'react-icons/md';
import CompareLogo from '../../../assets/images/png/Compare-logo.png';
import EditorLogo from '../../../assets/images/png/Editor-logo.png';

import { useState } from 'react';
import { Button, Input, Modal, Select, Upload } from 'antd';
import MarketplaceModal from './marketPlace-modal';
import { FaArrowLeft } from 'react-icons/fa';
import { setCurrentIntent } from 'src/redux/app-global';
import { useDispatch } from 'react-redux';
import { EAppIntent } from 'src/redux/app-global/app-global.types';

const tools = [
  {
    id: 1,
    name: 'File Comparison',
    description: 'Advanced Tool for comparing the output files',
    image: CompareLogo,
    expandedDescription: "CloudFrame's File Comparator simplifies comparision of the mainframe output file and java output file to verify the differences between the two files. This tool helps to debug the output mismatches of Cloudframe generated java code. The user can view the changes of both files in a split view or one below another",
  },
  {
    id: 2,
    name: 'CF Editor',
    description: 'Comprehensive text editor for viewing/editing binary files.',
    image: EditorLogo,
  },
];

const Marketplace = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);
  const dispatch = useDispatch();

  const showModal = (tool) => {
    setSelectedTool(tool);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedTool(null);
  };

  const redirectPage = () => {
    dispatch(setCurrentIntent(EAppIntent.TEST_CHEVRON));
  }
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Gradient Background and Title */}
      <div className="relative bg-gradient-to-r from-blue-500 to-indigo-600 py-16 text-center">
        <h1 className="text-5xl font-extrabold text-white mb-4">CloudFrame Marketplace</h1>
        <p className="text-lg text-gray-200 max-w-2xl mx-auto">
          Discover powerful tools tailored for your needs.
          Empower your workflow with cutting-edge solutions.
        </p>
      </div>

      {/* Tool Cards Section */}
      <div className="container mx-auto py-10 px-4 flex justify-center">
        <div className="flex flex-wrap justify-center gap-10">
          {tools.map((tool) => (
            <div
              key={tool.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 ease-in-out relative w-[300px]"
            >
              <div className="relative h-48">
                <div className='h-3/4 p-2 flex justify-center'>
                  <img
                    src={tool.image}
                    alt={tool.name}
                  />
                </div>
                {/* <div className="object-cover w-full h-full flex justify-center">
                  {tool.image}
                </div> */}
                <div className="absolute inset-0 bg-gradient-to-t from-gray-500 via-transparent to-transparent opacity-75"></div>
                <h2 className="absolute bottom-0 left-0 p-4 text-white text-lg font-semibold z-10">
                  {tool.name}
                </h2>
              </div>

              <div className="p-4">
                <p className="text-gray-700">{tool.description}</p>
                <div className="mt-4 flex justify-center items-center">
                  <button
                    className="px-4 py-2 text-sm bg-cta text-white rounded-md shadow hover:bg-cta-dark transition-colors duration-200 ease-in-out"
                    onClick={() => tool.id === 1 && showModal(tool)}
                  >
                    {tool.id > 1 ? (<div className='flex items-center gap-2 text-white'>
                      <MdAccessAlarm /> Coming soon!
                    </div>)
                      : 'Try it'}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='flex items-center justify-center'>
        <button className='bg-cta text-white flex items-center gap-2 justify-center p-3 rounded-md shadow-lg' onClick = {()=>redirectPage()}><FaArrowLeft /> Validation Dashboard</button>
      </div>
      {selectedTool && (
        <MarketplaceModal selectedTool={selectedTool} isModalVisible={isModalVisible} handleCancel={handleCancel}/>
      )}
    </div>
  );
};

export default Marketplace;
