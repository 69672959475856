import ProjectSideBar from 'src/components/project-sidebar/project-sidebar';
import {
  useGetProjectDetailQuery,
  useGetProjectReportsQuery,
} from 'src/redux/api-slice';
import { getSearchParameter } from 'src/utils/url.utils';
import { downloadReport } from './project-report-page.service';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useUser } from 'src/hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { useDispatch } from 'react-redux';
import { EAppIntent } from 'src/redux/app-global/app-global.types';
import { setCurrentIntent, setScanCompleted } from 'src/redux/app-global';
import AddNotesWidget from 'src/components/add-notes-widget/add-notes-widget';
import { useIsProjectLead } from '../project-overview/card/stages-component/useIsProjectLead';
import { useSetProjectContext } from '../project-overview/card/stages-component/useSetProjectContext';
import dayjs from 'dayjs';

const ProjectReportPage = () => {
  const projectId = getSearchParameter('projectId');
  const { data: reports, isLoading } = useGetProjectReportsQuery(
    { projectId },
    { skip: !projectId },
  );
  const user = useUser();
  const projectsQuery = useGetProjectDetailQuery({ projectId });

  const isProjectLead = useIsProjectLead();
  useSetProjectContext();
  const [loading, setLoading] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let reportData: any[] = [];
  if (reports?.['readinessReport']) {
    reportData.push({
      stage: 'Stage 1: Readiness',
      reports: [
        {
          id: 'readiness',
          title: 'Readiness Report',
          date: reports?.['readinessReport']?.lastModifiedDate
            ? dayjs(reports?.['readinessReport']?.lastModifiedDate).format(
                'MMM DD, YYYY',
              )
            : '',
        },
      ],
    });
  }
  if (reports?.['smartDocReport']) {
    reportData.push({
      stage: 'Stage 2: SmartDoc',
      reports: [
        {
          id: 'smartDoc',
          title: 'SmartDoc Report',
          date: reports?.['smartDocReport']?.lastModifiedDate
            ? dayjs(reports?.['smartDocReport']?.lastModifiedDate).format(
                'MMM DD, YYYY',
              )
            : '',
        },
      ],
    });
  }
  if (reports?.['transformReport']) {
    reportData.push({
      stage: 'Stage 3: Transform',
      reports: [
        {
          id: 'transform',
          title: 'Transform Report',
          date: reports?.['transformReport']?.lastModifiedDate
            ? dayjs(reports?.['transformReport']?.lastModifiedDate).format(
                'MMM DD, YYYY',
              )
            : '',
        },
      ],
    });
  }

  const getCurrentStage = data => {
    if (data?.currentStage?.toLowerCase() === 'default') {
      return 'Extract';
    }
    return data?.currentStage || 'NA';
  };

  const onPressDownload = async (reportName: string) => {
    try {
      const reportId =
        reportName === 'readiness'
          ? 'READINESS'
          : reportName === 'smartDoc'
            ? 'SMART_DOC'
            : 'TRANSFORM';

      setLoading(reportName);
      await downloadReport(projectId, reportId);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please try again later.',
      });
    } finally {
      setLoading('');
    }
  };

  const currentStage = getCurrentStage(projectsQuery.data);
  const onPressReportCard = (reportName: string) => {
    if (user?.role !== 'Admin') {
      if (
        !isProjectLead &&
        !['enhance', 'test', 'deploy', 'transform'].includes(
          currentStage.toLowerCase(),
        )
      ) {
        return;
      }

      navigate(`${ROUTES.HOME}?projectId=${projectId}`);
      dispatch(setScanCompleted(true));

      if (reportName === 'readiness') {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
      } else if (reportName === 'smartDoc') {
        dispatch(setCurrentIntent(EAppIntent.SMART_DOC));
      } else {
        dispatch(setCurrentIntent(EAppIntent.TRANSFORMATION_REPORT));
      }
      return;
    }
  };

  return (
    <div className="flex flex-row bg-whiteSmoothLight w-full relative gap-4">
      <div className="fixed bottom-8 right-10">
        <div className="h-14 w-14 bg-grey20 rounded-full flex items-center justify-center">
          <AddNotesWidget />
        </div>
      </div>
      <div className="mx-4 my-4 mb-0 pb-0 mr-0 w-8">
        <ProjectSideBar activeScreen={'Reports'} />
      </div>
      <div className="w-full h-[90vh] overflow-scroll mx-4 my-4 px-4 pb-8">
        <div>
          <h3 className="text-2xl font-bold my-4">Reports</h3>
          <p>
            Access detailed reports of your project transformation process.
            Review progress, identify any issues, and ensure a smooth transition
            from COBOL to Java.
          </p>
        </div>
        {isLoading || projectsQuery.isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="pb-8">
            {reportData.map((stageData, stageIndex) => (
              <div key={stageIndex} className="flex flex-col gap-4 my-4">
                <h4 className="text-xl font-semibold my-4">
                  {stageData.stage}
                </h4>
                <div className="flex gap-4">
                  {stageData.reports.map((report, reportIndex) => (
                    <div
                      key={reportIndex}
                      className="bg-white rounded-lg shadow-lg p-6 cursor-pointer transition-transform transform hover:scale-105 hover:shadow-xl"
                      style={{ height: '270px', width: '350px' }}
                      onClick={e => {
                        e.stopPropagation();
                        onPressReportCard(report.id);
                      }}
                    >
                      <h4 className="text-xl font-semibold text-gray-800 mb-2">
                        {report.title}
                      </h4>
                      <p className="text-sm font-light text-gray-600 mb-4">
                        {report.date}
                      </p>
                      <img
                        src={require('../../assets/images/png/zip.png')}
                        alt="PDF/HTML file icon"
                        className="h-24 w-24 rounded-md mt-4 mx-auto"
                      />
                      <hr className="mt-2" />
                      <button
                        onClick={e => {
                          e.stopPropagation();
                          onPressDownload(report.id);
                        }}
                        className="mt-4 flex items-center justify-center bg-blue-500 text-white rounded-md py-2 px-4 hover:bg-blue-600 transition-shadow shadow-md hover:shadow-lg"
                      >
                        <i className="fas fa-download mr-2"></i>
                        {loading === report.id ? 'Downloading...' : 'Download'}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mt-8">
              {reportData.length === 0 && (
                <p className="text-gray-500">
                  No reports have been generated for this project yet.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProjectReportPage;
