import { useUser } from 'src/hooks/useUser';
import { useGetProjectDetailQuery } from 'src/redux/api-slice';
import { getSearchParameter } from 'src/utils/url.utils';
import { getProjectLead } from './stages-component.service';

export const useIsProjectLead = () => {
  const projectId = getSearchParameter('projectId');
  const { data } = useGetProjectDetailQuery({ projectId });
  const user = useUser();

  const projectLead = getProjectLead(data);
  const isProjectLead =
    projectLead?.name.toLowerCase() === user?.userId.toLowerCase();

  return isProjectLead;
};
