import axios from 'axios';
import { appTheme } from 'src/styles/theme';
import { externalConfig } from 'src/utils/misc.utils';

export interface IUser {
  id: number;
  name: string;
  email: string;
  status: string;
  role: string;
  active: string;
}

export const fetchUsers = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_LOGIN_URL}/users`)
    if (Array.isArray(response.data?.data)) {
      return response.data.data;
    }
  }
  catch (error) {
    console.error(error);
    return [];
  }
};

export const userStatusToColorMap = {
  INACTIVE: {
    background: appTheme.colors.grey20,
    color: '#000000',
  },
  ACTIVE: {
    background: '#008000',
    color: '#FFFFFF',
  },
};
