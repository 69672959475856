import axios from 'axios';
import { SESSION_HEADER } from '../constants/app.constant';

const setAuthHeaders = (token?: string) => {
  axios.defaults.maxBodyLength = 1073741824; // 1 GB in bytes;
  axios.defaults.maxContentLength = 1073741824; // 1 GB in bytes;

  axios.interceptors.request.use(config => {
    const accessToken = sessionStorage.getItem('accessToken') || token;
    const headers = config.headers;
    headers[SESSION_HEADER] = sessionStorage.getItem(SESSION_HEADER);
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });
};

export default setAuthHeaders;
