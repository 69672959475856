import { useMemo, useRef } from 'react';
import DataTable from '../../../components/app-modals/support-ticket/DataTable';
import { SupportTicketTable } from './support-ticket-table';
import TransformStatusTable from './tranform-status-table';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIntent } from '../../../redux/app-global';
import {
  EAppIntent,
  EArtifactStatus,
} from '../../../redux/app-global/app-global.types';
import { RootState } from '../../../redux/store';
import { useCallChainUtils } from '../../../hooks/useCallChainUtils';

const TransformationReport = () => {
  const artifactStatus = useSelector(
    (state: RootState) => state.appGlobal.artifactStatus || {},
  );
  const supportTicketsGenerated = useSelector(
    (state: RootState) => state.appGlobal.supportTicketsGenerated || [],
  );
  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );
  const { jclArtifactIds } = useCallChainUtils();

  const counts = useMemo(() => {
    let successCount = 0;
    let failedCount = 0;
    let successPrograms: { name: string; type: 'jcl' | 'program' }[] = [];
    let failedPrograms: { name: string; type: 'jcl' | 'program' }[] = [];
    Object.keys(artifactStatus).forEach(name => {
      if (artifactStatus[name] === EArtifactStatus.SUCCESS) {
        successCount++;
        successPrograms.push({
          name,
          type: jclArtifactIds.includes(name) ? 'jcl' : 'program',
        });
      } else if (artifactStatus[name] === EArtifactStatus.ERROR) {
        failedCount++;
        failedPrograms.push({
          name,
          type: jclArtifactIds.includes(name) ? 'jcl' : 'program',
        });
      }
    });

    return { successCount, failedCount, successPrograms, failedPrograms };
  }, [artifactStatus, jclArtifactIds]);

  const transformRef = useRef(null);
  const dispatch = useDispatch();
  const handleReportDownload = () => {
    const transformElement = transformRef.current;
    const htmlContent = (transformElement as any)?.innerHTML;
    const fullHtmlContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Report</title>
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
              <link rel="stylesheet" href="https://static2.sharepointonline.com/files/fabric/office-ui-fabric-js/1.4.0/css/fabric.min.css" />
              <link rel="stylesheet" href="https://static2.sharepointonline.com/files/fabric/office-ui-fabric-js/1.4.0/css/fabric.components.min.css" />
              <script src="https://static2.sharepointonline.com/files/fabric/office-ui-fabric-js/1.4.0/js/fabric.min.js"></script><body>${htmlContent}</body>
            </html>
          `;
    const blob = new Blob([fullHtmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'CloudFrame Application Transformation Report.html';
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };
  return (
    <div>
      <div className="bg-gray-100 flex flex-row items-center justify-end gap-4 py-4 px-4">
        <button
          className="bg-cta shadow-sm border-0 text-white p-2 text-sm rounded-md hover:bg-indigo-600 focus:outline-none"
          onClick={handleReportDownload}
        >
          Download Report
        </button>
        <button
          onClick={() => {
            dispatch(setCurrentIntent(EAppIntent.TRANSFORMATION_PAGE));
          }}
          className="bg-cta shadow-sm border-0 text-white p-2 text-sm rounded-md hover:bg-indigo-600 focus:outline-none"
        >
          Back to transform
        </button>
      </div>
      <div ref={transformRef}>
        <div className="w-full h-full bg-blue-600 px-8 py-8">
          <h1 className="font-bold text-xl mt-4 px-4 text-white">
            Transformation Report
          </h1>
          <p className="mt-2 px-4 text-justify text-white">
          The Transformation Report provides a comprehensive overview of the transformation journey of the programs included in your project. It highlights the overall status of the transformation process, distinguishing between programs that were successfully transformed and those that encountered issues. This report is designed to support your ongoing evaluation and facilitate strategic planning for future modernization efforts.
          </p>
          <div className="flex flex-col space-y-2 px-4 py-4">
            <div className="flex">
              <span className="text-gray-300 text-xs">
                Generated on {new Date().toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        <div className="flex px-4 justify-center">
          <div className="block mt-8">
            <h2 className="font-semibold text-center mr-4">Summary</h2>
            <div className="flex ml-4">
              <div className="mt-6 mr-8 rounded-full w-24 h-24 border-4 border-green-500 flex flex-col justify-center items-center mr-4">
                <h2>{counts.failedCount + counts.successCount}</h2>
                <p className="text-sm">Programs</p>
              </div>
              <div className="mt-6 mr-8 rounded-full w-24 h-24 border-4 border-green-500 flex flex-col justify-center items-center mr-4">
                <h2>{counts.successCount}</h2>
                <p className="text-sm">Success</p>
              </div>
              <div className="mt-6 mr-8 rounded-full w-24 h-24 border-4 border-red-500 flex flex-col justify-center items-center mr-4">
                <h2>{counts.failedCount}</h2>
                <p className="text-sm">Failed</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='mt-4 p-8'>
          <h3 className="font-semibold">Transformation Summary for EXPRPT:</h3>
          <p className='mt-2'>The JOB EXPRPT uses no input files produces 3 output files:</p>
          <ul style={{listStyle:"circle",marginLeft:"32px",lineHeight:"32px"}}>
            <li>CLOUDFRM.TRADING.BATCHDB2.RUNLOG.REPORT</li>
            <li>CLOUDFRM.TRADING.BATCHDB2.SUMMARY.REPORT</li>
            <li>CLOUDFRM.TRADING.BATCHDB2.EXCPTION.REPORT</li>
          </ul>
          <p className='mt-2'>The files are available in binary format and can be compared directly with mainframe files in EBCDIC format.</p>
        </div> */}
        {supportTicketsGenerated.length > 0 && (
          <div className="bg-gray-100 mt-8 p-4">
            <h3 className="mt-4 px-4 font-semibold">Support Tickets Raised</h3>
            <SupportTicketTable />
          </div>
        )}
        {counts.successPrograms?.length > 0 && (
          <div className="mt-4 p-2">
            <h3 className="px-4 font-semibold">Transformed Artifacts</h3>
            <TransformStatusTable data={counts.successPrograms} type="green" />
          </div>
        )}
        {counts.failedPrograms?.length > 0 && (
          <div className="bg-gray-100 mt-8 p-2">
            <h3 className="mt-4 px-4 font-semibold">Transformation Failed</h3>
            <TransformStatusTable data={counts.failedPrograms} type="red" />
          </div>
        )}
      </div>
    </div>
  );
};
export default TransformationReport;
