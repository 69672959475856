import React, { useCallback, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import {
  getLicenseMetadata,
  submitActivationKey,
  uploadLicenseFile,
} from './license.service';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { useUser } from 'src/hooks/useUser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { loadLicenseDetails } from 'src/services/global-service';
import { useLazyGetLicenseMetadataQuery } from 'src/redux/api-slice';
import { FaCopy } from 'react-icons/fa';
import dayjs from 'dayjs';

const LicenseUploadPage = () => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [encryptedKey, setEncryptedKey] = useState<string | null>(null);
  const [secureCode, setSecureCode] = useState<string>('');
  const [copySuccess, setCopySuccess] = useState('');
  const navigate = useNavigate();
  const user = useUser();

  const codeRef: any = useRef<any>(null);

  const [getMetadataQuery] = useLazyGetLicenseMetadataQuery();
  const [loading, setLoading] = useState(false);

  const license = useSelector((state: RootState) => state.appGlobal.license);
  const dispatch = useDispatch();

  const noLicenseFound = !license?.licenseFor;
  const isValidLicenseExist =
    license?.isLicenseActivate === 'true' && license?.isLicenseValid === 'true';

  const isLicenseExpired = license?.licensedTill
    ? dayjs(license?.licensedTill, 'MM/DD/YYYY').isBefore(dayjs(), 'day')
    : false;

  const getMetadata = useCallback(async () => {
    try {
      const metadata = await getMetadataQuery();
      setShowModal(true);

      if (!metadata.data) {
        throw new Error(
          'An unexpected error occurred. Please try again later.',
        );
      }

      setEncryptedKey(metadata.data);
    } catch (error: any) {
      setShowModal(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          error?.message ||
          'An unexpected error occurred. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (
        license?.isLicenseValid === 'true' &&
        user?.role === 'Admin' &&
        !isValidLicenseExist &&
        !isLicenseExpired
      ) {
        await getMetadata();
      }
    })();
  }, [license, getMetadata, user, isValidLicenseExist, isLicenseExpired]);

  const handleFileUpload = e => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(null);
    } else {
      setError('Please upload a valid file.');
    }
    e.target.value = '';
  };

  const handleFileDrop = e => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setError(null);
    } else {
      setError('Please upload a valid file.');
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      setError('Please select a file before submitting.');
      return;
    }

    setIsUploading(true);
    try {
      const response = await uploadLicenseFile(file);
      console.log(response);

      if (response.status === 'ERROR') {
        throw new Error(
          response.message || 'Something went wrong! Please try again later',
        );
      }

      setFile(null);
      const licenseData = await loadLicenseDetails(dispatch);

      const isNewLicenseExpired = licenseData?.licensedTill
        ? dayjs(license?.licensedTill, 'MM/DD/YYYY').isBefore(dayjs(), 'day')
        : false;

      if (isNewLicenseExpired) {
        setShowModal(false);
        Swal.fire({
          icon: 'error',
          title: 'License Renew Failed',
          text: 'Your license has expired. Please contact our support team for assistance.',
        });
        return;
      }

      if (
        licenseData?.isLicenseValid === 'true' &&
        licenseData?.isLicenseActivate === 'true'
      ) {
        setShowModal(false);
        setTimeout(() => {
          navigate(ROUTES.DASHBOARD);
          window.history.pushState({}, '', './dashboard');
          window.dispatchEvent(new PopStateEvent('popstate'));
        }, 500);
        return;
      }

      await getMetadata();
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'License Renew Failed',
        text:
          error.message ||
          'Please try again with valid files or contact our support team for assistance.',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const copyToClipboard = async () => {
    const text = codeRef.current?.textContent || '';
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const handleModalSubmit = async () => {
    try {
      setLoading(true);
      const resp = await submitActivationKey(secureCode);

      if (resp.status === 'ERROR') {
        throw new Error(
          resp.message || 'Something went wrong! Please try again later',
        );
      }

      await loadLicenseDetails(dispatch);
      setShowModal(false);
      navigate(ROUTES.DASHBOARD);
      window.history.pushState({}, '', './dashboard');
      window.dispatchEvent(new PopStateEvent('popstate'));
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          error?.message ||
          'An unexpected error occurred. Please try again later.',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false);
    }
  };

  if (user?.role !== 'Admin') {
    return (
      <div className="h-[90vh] w-full flex items-center justify-center bg-gray-100">
        <div
          className="bg-white p-10 rounded-lg shadow-lg w-full max-w-3xl -mt-20 flex items-center justify-center"
          style={{ height: '400px' }}
        >
          <div>
            <h2 className="text-2xl font-bold text-gray-800 text-center">
              Your license has expired
            </h2>
            <p className="text-gray-600 text-center mt-2">
              Please contact your administrator to renew your license.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[90vh] w-full flex items-center justify-center bg-gray-100">
      <div
        className="bg-white p-10 rounded-lg shadow-lg w-full max-w-3xl -mt-20"
        style={{ height: '400px' }}
      >
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {noLicenseFound
            ? 'No license found'
            : !isLicenseExpired
              ? 'Upload new license'
              : 'Your license has expired'}
        </h2>
        <p className="text-gray-600 text-center mt-2">
          Please upload a valid license file to continue using the platform.
        </p>
        <div
          className="mt-6 flex justify-center"
          onDrop={handleFileDrop}
          onDragOver={e => e.preventDefault()}
          style={{
            border: '2px dashed #999',
            padding: '40px',
            borderRadius: '10px',
            textAlign: 'center',
          }}
        >
          <label htmlFor="fileUpload">
            {file ? (
              <p className="text-sm text-green-500">
                File selected: {file.name}
              </p>
            ) : (
              <div>
                <p className="text-blue-500 cursor-pointer">
                  Select a file to upload
                </p>
                <p className="text-gray-500">or drag and drop it here</p>
              </div>
            )}
          </label>

          <input
            id="fileUpload"
            type="file"
            accept=".txt,.pdf"
            onChange={handleFileUpload}
            className="hidden"
          />
        </div>

        {error && (
          <div className="mt-4 text-center">
            <p className="text-sm text-red-500">{error}</p>
          </div>
        )}

        <div className="mt-8 flex justify-center">
          <button
            className="bg-blue-600 text-white text-sm px-4 py-2 rounded-md"
            onClick={handleSubmit}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Submit'}
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl">
            <h3 className="text-lg font-bold text-gray-800">
              License Approval
            </h3>
            <p className="mt-2 text-gray-600 ">
              Copy the License Key below and send it to CloudFrame Support to
              receive your secure Activation Key.
            </p>

            <div className="relative">
              {copySuccess && (
                <div
                  style={{
                    color: copySuccess === 'Failed to copy!' ? 'red' : 'green',
                  }}
                  className="text-sm  ml-2 text-right absolute right-0 -bottom-4"
                >
                  {copySuccess}
                </div>
              )}
            </div>
            <div className="mt-4 p-2 bg-gray-100 rounded relative">
              <div className="float-right ml-2">
                <div>
                  <FaCopy
                    className="cursor-pointer text-xl hover:text-blue-500"
                    onClick={copyToClipboard}
                  />
                </div>
              </div>
              <code ref={codeRef} className="text-sm text-gray-800 break-words">
                {encryptedKey}
              </code>
            </div>
            <p className="mt-4 text-gray-600">
              Enter the Activation Key provided by CloudFrame Support:
            </p>
            <input
              type="text"
              value={secureCode}
              onChange={e => setSecureCode(e.target.value)}
              className="mt-2 p-2 border rounded w-full"
            />
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="bg-gray-500 text-white text-sm px-4 py-2 rounded-md"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="bg-blue-600 text-white text-sm px-2 py-2 rounded-md"
                onClick={handleModalSubmit}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenseUploadPage;
