interface ValidationProgressBarProps {
    progress: number; // Progress value in percentage (0 to 100)
  }
  
  const ValidationProgressBar: React.FC<ValidationProgressBarProps> = ({ progress }) => {
    return (
      <div className="w-full bg-gray-300 h-2 rounded-lg overflow-hidden">
        <div
          className="h-full rounded-lg transition-all duration-500 ease-in-out animate-pulse"
          style={{
            width: `${progress}%`,
            background: `linear-gradient(90deg, #6EE7B7 0%, #3B82F6 100%)`,
          }}
        ></div>
      </div>
    );
  };
  
  export default ValidationProgressBar;
  