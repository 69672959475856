import { useUser } from 'src/hooks/useUser';
import { IMemberOnlyProps } from './member-only-types';

const MemberOnly = ({ children }: IMemberOnlyProps) => {
  const user = useUser();

  if (user?.role !== 'Admin') {
    return <div>{children}</div>;
  }

  return null;
};

export default MemberOnly;
